import { createUrl, getPublicMultipartRoutesHeaders, getPublicRoutesHeaders } from "./api.util";
import { Observable, pipe } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APISuccess, BlankAPISuccess } from '../model/generics';
import { UtilService } from '../services/util.service';
import { tap } from "rxjs/operators";
import { IJWTPayload, IRegisterInputPayload } from "../model/auth.model";


export type registerInput = {
    email: string,
    accountName: string
};
export type registerContent = undefined;
type registerOutput = Observable<BlankAPISuccess>;

export type resetInput = {
    resetToken: string;
    email: string;
    password: string;
}
export type resetContent = IJWTPayload;
type resetOutput = Observable<APISuccess<resetContent>>;

export type authenticateInput = {
    email: string;
    password: string;
}
export type authenticateContent = IJWTPayload;
type authenticateOutput = Observable<APISuccess<authenticateContent>>;

export type forgotInput = {
    email: string;
    password: string;
}
export type forgotContent = IJWTPayload;
type forgotOutput = Observable<APISuccess<forgotContent>>;

@Injectable()
export class AuthApi extends UtilService {

    constructor(
        private readonly httpClient: HttpClient
    ) {
        super();
    }

    /**
     * @param  {registerInput} opts
     * @returns registerOutput
     */
    readonly register = (opts: IRegisterInputPayload): registerOutput => {

        return this.httpClient.post<BlankAPISuccess>(
            createUrl('auth', 'register'),
            opts,
            { headers: getPublicRoutesHeaders() }
        );
    };

    /**
     * @param  {resetInput} opts
     * @returns resetOutput
     */
    readonly reset = (opts: resetInput): resetOutput => {
        return this.httpClient.post<APISuccess<resetContent>>(
            createUrl('auth', 'reset'),
            opts,
            { headers: getPublicRoutesHeaders() }
        )
    };

    /**
     * @param  {authenticateInput} opts
     * @returns authenticateOutput
     */
    readonly authenticate = (opts: authenticateInput): authenticateOutput => {
        return this.httpClient.post<APISuccess<authenticateContent>>(
            createUrl('auth'),
            opts,
            { headers: getPublicRoutesHeaders() }
        )
    }

    /**
     * @param  {forgotInput} opts
     * @returns forgotOutput
     */
    readonly forgot = (opts: forgotInput): forgotOutput => {
        return this.httpClient.post<APISuccess<forgotContent>>(
            createUrl('auth', 'forgot'),
            opts,
            { headers: getPublicRoutesHeaders() }
        )
    }

    /**
     * @description Fetches a new auth token from the server using the supplied refresh token
     * @param {Omit<IJWTPayload, 'Bearer'>} opts 
     * @returns {Observable<APISuccess<IJWTPayload>>}
     */
    readonly refresh = (opts: Omit<IJWTPayload, 'tokenType'>): Observable<APISuccess<IJWTPayload>> => {
        return this.httpClient.post<APISuccess<IJWTPayload>>(
            createUrl('auth', 'refresh'),
            opts,
            { headers: getPublicRoutesHeaders() }
        )
    }
}