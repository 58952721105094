import {createUrl, getPublicRoutesHeaders} from "./api.util";
import { Observable, pipe } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BlankAPISuccess } from "../model/generics";
import { UtilService } from "../services/util.service";

export type contactInput = {
	name?: string;
	email: string;
	phone?: string;
	company?: string;
	message: string;
}
export type contactOutput = Observable<BlankAPISuccess>;

@Injectable()
export class MailApi extends UtilService {
  
  constructor(
    private readonly httpClient: HttpClient
  ) {
	  super();
  }
  
	/**
	 * @param  {contactInput} opts
	 * @returns {contactOutput}
	 */
	readonly contact = (opts: contactInput): contactOutput => {
		return this.httpClient.post<BlankAPISuccess>(
			createUrl('mail', 'contact'),
			opts,
			{headers: getPublicRoutesHeaders()}
		);
	};
}