<ng-container *ngIf="control as control">
  <div class="form-group">
    <label class="form-label">{{ control.label }}</label>
    <input type="input" class="form-control" *ngIf="!isPreview" placeholder="{{ control.placeHolder }}" />
    <form *ngIf="isPreview" [formGroup]="form">
      <input type="input" class="form-control" placeholder="{{ control.placeHolder }}"
        formControlName="{{ control.name }}" />

      <ng-container *ngIf="
            isPreview === true &&
            form?.get(control.name || '')?.hasError('required') &&
            (form?.get(control.name || '')?.dirty ||
              form?.get(control.name || '')?.touched)
          ">
        <span class="text-danger">{{ control.label }} is required</span>

      </ng-container>
    </form>
  </div>
  <control-action [isPreview]="isPreview" [isColumn]="isColumn" (duplicate)="duplicateTextFieldControl(control)"
    (update)="editInputFieldDialog(control, elementIndex, columnIndex, rowIndex)"
    (delete)="deleteTextField(control, elementIndex, columnIndex, rowIndex)"></control-action>

</ng-container>