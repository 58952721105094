import { GenericApi } from './generic.api';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createUrl, getPublicRoutesHeaders } from './api.util';
import { Observable } from 'rxjs';

// Convenience Type
type T = any;

@Injectable()
export class FileUploadApi extends GenericApi<T> {
  public path = "file";

  constructor(
    public httpClient: HttpClient
  ) {
    super(httpClient);
  }

  public readonly getSignedUploadUrl = (bucket: string, key: string): Observable<any> => {
    return this.httpClient.post<any>(
      createUrl(this.path, 'get-signed-url'),
      {
        bucket,
        key
      },
      { headers: getPublicRoutesHeaders() }
    );
  };

  public readonly decodeUri = (file: any, url: string): Observable<any> => {
    const headers = new HttpHeaders({ 'Content-Type': file.type + ";charset=UTF-8" });
    console.log("decodeUri", file,
      {
        headers: headers,
        reportProgress: true
      })
    return this.httpClient.put(
      decodeURI(decodeURI(url)),
      file,
      {
        headers: headers,
        reportProgress: true
      }
    )
  };
}
