import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorMessage, FieldRejection } from "../../../main/model/api.model";
import { CommonController } from "../../../main/util/common.controller";

import { ChangeTaskStatusService, UserTaskService } from "src/app/main/services/account.service";
import { IFormsInstance } from "src/app/forms-instance/types/forms-instance";
import { FormsInstanceService } from "src/app/forms-instance/service/forms-instance.service";
import { PublicService } from "../../services/public.service";
import { JwtService } from "../../services/jwt.service";
import { DialogRef } from "src/app/ui/service/dialog-ref";
import { DialogService } from "src/app/ui/service/dialog.service";
import { PublicFormsParticipentDialogComponent } from "./public-form-participent.component";

@Component({
  selector: "app-form-instance-edit",
  templateUrl: "./public-forms.component.html",
  styles: [``],
})
export class PublicFormsComponent
  extends CommonController
  implements OnInit {
  id: string | null = null;
  form: UntypedFormGroup;
  fieldRejections: FieldRejection[] = [];
  formData: IFormsInstance;
  currentPath: string = '';
  taskId: string = ''
  isUserLoggedIn: boolean = false
  publicFormUserData: { firstName: string; lastName: string; email: string; concent: boolean; accountId: any; formInstanceId: string; formTemplateId: string } = {
    firstName: '',
    lastName: '',
    email: '',
    concent: false,
    accountId: null,
    formInstanceId: '',
    formTemplateId: ''
  };
  constructor(
    private formsInstanceService: FormsInstanceService,
    private publicService: PublicService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private jwtService: JwtService,
    public dialog: DialogService,
  ) {
    super();

    // this.form = this.formBuilder.group({
    //   domain: [
    //     null,
    //     [Validators.required, Validators.pattern(DomainRegex)],
    //     serverSide(this.fieldRejections, "domain"),
    //   ],
    // });
  }
  formInstanceForm!: FormGroup;
  ngOnInit() {
    this.getCurrentPath()
    this.validateFormBuilder();
    this.monitorJwtData()

    this.route.params.subscribe((params) => {
      if ("id" in params && String(params.id).length) {
        this.publicService.formInstanceGet(params.id).subscribe({
          next: (data) => {
            this.publicFormUserData.formInstanceId = data.id;
            this.publicFormUserData.formTemplateId = data.formTemplateId;
            this.id = data.id;

            this.formData =
              this.formsInstanceService.convertFormBuilderObj(data);

            this.validateFormBuilder();
            // this.form.controls["domain"].setValue(data.domain);
          },
        });
      }
    });
  }


  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.publicFormUserData.firstName = data.account.name.includes(" ") ? data.account.name.split(" ")[0] : data.account.name
          this.publicFormUserData.lastName = data.account.name.includes(" ") && data.account.name.split(" ")[1]
          this.publicFormUserData.email = data.user.email;
          this.publicFormUserData.accountId = data.account.id;

          this.isUserLoggedIn = true;
        } else {
          this.openPublicFormParticipentForm()
        }
      },
    });
  }
  getCurrentPath() {
    this.route.queryParams.subscribe(params => {
      if (params && params?.taskId) {
        this.taskId = params.taskId
      }
    });
    this.currentPath = this.route.pathFromRoot
      .map(v => v.routeConfig ? v.routeConfig.path : '')
      .filter(v => v !== '')
      .join('/');
  }

  save() {
    const saveMethod = this.id
      ? this.publicService.formInstanceUpdate(this.form.value, this.id)
      : this.publicService.formInstanceCreate(this.form.value);

    saveMethod.subscribe({
      next: () => {
        this.router.navigate(["/dashboard/config/formsinstance"]);
      },
      error: (err: ErrorMessage) =>
        this.handleValidationErrors(err, this.form, this.fieldRejections),
      complete: () => this.form.markAsUntouched(),
    });
  }

  onSubmit() {
    if (this.formInstanceForm.invalid) {
      return;
    }

    if (this.publicFormUserData.email == '' || this.publicFormUserData.email == '' || this.publicFormUserData.email == '') {
      this.openPublicFormParticipentForm()
    }
    let formdata = this.formInstanceForm.value;
    this.formData?.rows.forEach((row) => {
      row.columns.forEach((column) => {
        column.elements.forEach((element) => {
          if (element.type === "checkbox") {
            element.result = JSON.stringify(formdata[element.name]);
          } else {
            element.result = formdata[element.name];
          }
        });
      });
    });
    this.publicService.submitPublicFormParticipent(this.publicFormUserData)
      .subscribe({
        next: () => {
          this.publicService.formInstanceUpdate(this.formData, this.id || "").subscribe({
            next: () => {
              if (this.taskId != '' && this.currentPath.includes('task')) {
                // this.updateTask()
              } else {
                this.router.navigate(["/"]);
              }

            },
            error: (err: ErrorMessage) =>
              this.handleValidationErrors(err, this.form, this.fieldRejections),
            complete: () => console.log("completed"),
          });

        },
        error: (err: ErrorMessage) =>
          this.handleValidationErrors(err, this.form, this.fieldRejections),
        complete: () => console.log("completed"),
      });

  }

  // destroy() {
  //   if (!this.id) return;

  //   this.formsInstanceService.delete(this.id, "").subscribe({
  //     next: () => {
  //       this.router.navigate(["/dashboard/config/forms"]);
  //     },
  //     error: (err: ErrorMessage) =>
  //       this.handleValidationErrors(err, this.form, this.fieldRejections),
  //     complete: () => this.form.markAsUntouched(),
  //   });
  // }

  validateFormBuilder() {
    let formKeys = {};
    this.formData?.rows.forEach((row) => {
      row.columns.forEach((column) => {
        column.elements.forEach((element) => {
          if (element.type === "checkbox") {
            formKeys[element.name] =
              element.isRequired === true
                ? new FormArray([], Validators.required)
                : new FormArray([]);
          } else {
            formKeys[element.name] =
              element.isRequired === true ? ["", Validators.required] : [""];
          }
        });
      });
    });
    this.formInstanceForm = this.formBuilder.group(formKeys);
  }

  openPublicFormParticipentForm() {
    const dialogRef = this.dialog.open(
      PublicFormsParticipentDialogComponent,
      { data: null },
      "modal-width-xl"
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.publicFormUserData.firstName = result.firstName;
        this.publicFormUserData.lastName = result.lastName;
        this.publicFormUserData.email = result.email;
        this.publicFormUserData.concent = result.concent == 'on' ? true : false;
      }
    });
  }

}
