import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UIStateService {

  public useWideLayout$ = new BehaviorSubject<boolean>(false);

  constructor(
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.useWideLayout$.next(false);
      }
    });
  }
}