import { GenericApi } from "../../main/api/generic.api";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class FormsTemplateApi extends GenericApi {
    public path = "form-template";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}
@Injectable()
export class FormsRowApi extends GenericApi {
    public path = "form-row";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}

@Injectable()
export class FormsColumnApi extends GenericApi {
    public path = "form-column";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}
@Injectable()
export class FormsElementApi extends GenericApi {
    public path = "form-element";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}

@Injectable()
export class FormsElementOptionApi extends GenericApi {
    public path = "form-element-option";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}

@Injectable()
export class FormTemplateMoveToInstanseApi extends GenericApi {
    public path = "form-instance/copyformtemplate";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}
