import { slideInAnimation } from "../util/animations";
import { CommonController } from "../util/common.controller";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { UIStateService } from "src/app/ui/service/uiState.service";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { of } from "rxjs";
import { Router } from "@angular/router";


const className = "ConsoleComponent";

@Component({
  template: `<div  [ngClass]="{'dashboard-content':isConsoleRoute() ===true}" >
                <router-outlet></router-outlet> 
                </div>
  `,
  animations: [slideInAnimation],
  styles: [``],
})
export class ConsoleComponent extends CommonController {
  useWideLayout$ = of(false);

  constructor(private uiStateService: UIStateService, private router: Router) {
    super();

    this.monitorLayoutWidth();
  }

  /**
 * @description Checks if the current route is the console route
 * @returns {boolean} - True if the current route is "/dashboard/config", false otherwise
 */
  isConsoleRoute() {

    return this.router.url === "/dashboard/config";
  }

  /**
 * @description Checks if the current route is the form creation route
 * @returns {boolean} - True if the current route is "/config/forms/create-form", false otherwise
 */
  isFormRoute() {
    return this.router.url === "/config/forms/create-form";
  }

  /**
 * @description Monitors the layout width and updates the layout state accordingly
 * @returns {void}
 */
  monitorLayoutWidth() {
    const signature = className + ".monitorLayoutWidth: ";
    this.useWideLayout$ = this.uiStateService.useWideLayout$.pipe(
      distinctUntilChanged(),
      debounceTime(0),
      tap((val) => console.log(signature + `UseWideLayout[${val}]`))
    );
  }
}
