import { Component } from '@angular/core';
import { CommonController } from '../../util/common.controller';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['_footer.component.scss']
})
export class FooterComponent extends CommonController {
  year: string;

  currentPath: string = ''
  isShowNavigation: boolean = true;

  constructor(public router: Router) {
    super();
    this.year = new Date().getFullYear().toString();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = event.url;
      }
    });
  }

  shouldShowFooter(): boolean {
    return ['/', '/about', '/features', '/plans'].includes(this.currentPath);
  }
}