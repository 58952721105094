<section class="page-banner">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-7">
        <div class="banner-content">
          <span class="badge">Features</span>
          <h1 class="title">Let’s understand FusionFlow features</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- =========================== -->
<!-- Featured Banner end here... -->
<!-- =========================== -->

<!-- =========================================== -->
<!-- Featured ipass config section start here... -->
<!-- =========================================== -->
<section class="featured-config">
  <div class="container-fluid">
    <div>
      <h2 class="title">Components</h2>
      <p class="description">
        FusionFlow Components are purpose-built to cater to specific,
        well-defined needs. Each component serves a distinct, specialized
        function, contributing to the overall efficiency and flexibility of your
        workflows.
      </p>
    </div>

    <!-- featured-config-1 section start here... -->
    <div class="row align-items-center featured-card">
      <div class="col-12 col-lg-6">
        <div class="config-content">
          <div class="config-anchor">
            <div class="card-img">
              <img
                src="/assets/icons/Form-instances-config.svg"
                alt="Form-instances-config"
              />
            </div>
            <h4 class="mb-0">
              <a
                href="form-instance.html"
                role="button"
                class="btn stretched-link"
              >
                Form Templates: Simplify Data Collection
              </a>
            </h4>
          </div>
          <p class="description">
            FusionFlow's Form Templates component empowers you to effortlessly
            create form instances for user input. Streamline data collection,
            ensuring precision and efficiency at every step.
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="config-img">
          <img
            class="w-100"
            src="/assets/img/Form-Instances.webp"
            alt="form-instance"
          />
        </div>
      </div>
    </div>
    <!-- featured-config-1 section end here... -->

    <!-- featured-config-2 section start here... -->
    <div class="row align-items-center featured-card">
      <div class="col-12 col-lg-6">
        <div class="config-img">
          <img
            class="w-100"
            src="/assets/img/Form-Instances.webp"
            alt="form-instance"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="config-content">
          <div class="config-anchor">
            <div class="card-img">
              <img
                src="/assets/icons/File-storage-config.svg"
                alt="File-storage-config"
              />
            </div>
            <h4 class="mb-0">
              <a
                href="file-storage.html"
                role="button"
                class="btn stretched-link"
              >
                File Storage: Secure and Encrypted
              </a>
            </h4>
          </div>
          <p class="description">
            Our File Storage component provides a fortified solution for
            securely storing and sharing files. With automatic encryption, your
            data remains safeguarded while offering convenient accessibility to
            authorized users.
          </p>
        </div>
      </div>
    </div>
    <!-- featured-config-2 section end here... -->

    <!-- featured-config-3 section start here... -->
    <div class="row align-items-center featured-card" id="data-table">
      <div class="col-12 col-lg-6">
        <div class="config-content">
          <div class="config-anchor">
            <div class="card-img">
              <img
                src="/assets/icons/Data-table-config.svg"
                alt="Data-Tables-config"
              />
            </div>
            <h4 class="mb-0">
              <a
                href="/#/#data-table"
                role="button"
                class="btn stretched-link"
              >
                Data Tables: Versatile Data Management
              </a>
            </h4>
          </div>
          <p class="description">
            Our Data Tables component empowers you to efficiently store and
            manage various data sets. Enjoy the flexibility to organize,
            analyze, and maintain your data in a structured and user-friendly
            manner.
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="config-img">
          <img
            class="w-100"
            src="/assets/img/Data-Tables.webp"
            alt="Data-Tables"
          />
        </div>
      </div>
    </div>
    <!-- featured-config-3 section end here... -->

    <!-- featured-config-4 section start here... -->
    <div class="row align-items-center featured-card" id="users-groups">
      <div class="col-12 col-lg-6">
        <div class="config-img">
          <img
            class="w-100"
            src="/assets/img/Users-Groups.webp"
            alt="Users-Groups"
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="config-content">
          <div class="config-anchor">
            <div class="card-img">
              <img
                src="/assets/icons/Users-Groups-config.svg"
                alt="Users-Groups-config"
              />
            </div>
            <h4 class="mb-0">
              <a
                href="/#/#users-groups"
                role="button"
                class="btn stretched-link"
              >
                Users & Groups: Streamlined Access Control
              </a>
            </h4>
          </div>
          <p class="description">
            Our Users & Groups component simplifies access control. Tailor user
            permissions based on their roles within your organization, ensuring
            secure and organized access throughout your application.
          </p>
        </div>
      </div>
    </div>
    <!-- featured-config-4 section end here... -->

    <!-- featured-config-5 section start here... -->
    <div class="row align-items-center featured-card">
      <div class="col-12 col-lg-6">
        <div class="config-content">
          <div class="config-anchor">
            <div class="card-img">
              <img
                src="/assets/icons/Email-templates-config.svg"
                alt="Email-templates-config"
              />
            </div>
            <h4 class="mb-0">
              <a
                href="email-template.html"
                role="button"
                class="btn stretched-link"
              >
                Email Templates: Personalize Your Messages
              </a>
            </h4>
          </div>
          <p class="description">
            Our Email Templates component empowers organizations to customize
            their email content. Tailor your messages and enhance your
            communication with users to create a more personal and engaging
            experience.
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="config-img">
          <img
            class="w-100"
            src="/assets/img/Email-Template.webp"
            alt="email-template"
          />
        </div>
      </div>
    </div>
    <!-- featured-config-5 section end here... -->

    <!-- featured-config-6 section start here... -->
    <div class="row align-items-center featured-card">
      <div class="col-12 col-lg-6">
        <div class="config-img">
          <img class="w-100" src="/assets/img/Workflow.webp" alt="Workflow" />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="config-content">
          <div class="config-anchor">
            <div class="card-img">
              <img
                src="/assets/icons/Workflow-config.svg"
                alt="Workflow-config"
              />
            </div>
            <h4 class="mb-0">
              <a href="workflow.html" role="button" class="btn stretched-link">
                Workflows: The Integration Orchestrator
              </a>
            </h4>
          </div>
          <p class="description">
            Our Workflows component defines data logic, efficiently managing
            information flow between components. Streamline operations, reduce
            errors, and improve efficiency.
          </p>
        </div>
      </div>
    </div>
    <!-- featured-config-6 section end here... -->
  </div>
</section>
