<ng-container [ngSwitch]="fieldItem.type">
  <div class="contact-form-inputs position-relative">
    <ng-container *ngSwitchCase="'text'">
      <app-fbc-input
        [control]="fieldItem"
        [elementIndex]="elementId"
        [columnIndex]="columnId"
        [rowIndex]="rowId"
        [isColumn]="isColumn"
        [isPreview]="isPreview"
        [form]="form"
        (textFieldDeleteEvent)="handleDeleteControl($event)"
        (duplicateControl)="handleDuplicateControl($event)"
        (textAreaUpdateEvent)="handleUpdateControl($event)"
      >
      </app-fbc-input>
    </ng-container>
    <ng-container *ngSwitchCase="'number'">
      <app-fbc-number
        [control]="fieldItem"
        [elementIndex]="elementId"
        [columnIndex]="columnId"
        [rowIndex]="rowId"
        [isColumn]="isColumn"
        [isPreview]="isPreview"
        [form]="form"
        (inputFieldDeleteEvent)="handleDeleteControl($event)"
        (duplicateControl)="handleDuplicateControl($event)"
        (textAreaUpdateEvent)="handleUpdateControl($event)"
      >
      </app-fbc-number>
    </ng-container>
    <ng-container *ngSwitchCase="'textarea'">
      <app-fbc-textarea
        [control]="fieldItem"
        [elementIndex]="elementId"
        [columnIndex]="columnId"
        [rowIndex]="rowId"
        [isColumn]="isColumn"
        [isPreview]="isPreview"
        [form]="form"
        (textAreaFieldDeleteEvent)="handleDeleteControl($event)"
        (duplicateControl)="handleDuplicateControl($event)"
        (textAreaUpdateEvent)="handleUpdateControl($event)"
      >
      </app-fbc-textarea>
    </ng-container>
    <ng-container *ngSwitchCase="'radio'">
      <app-fbc-radio
        [control]="fieldItem"
        [elementIndex]="elementId"
        [columnIndex]="columnId"
        [rowIndex]="rowId"
        [isColumn]="isColumn"
        [isPreview]="isPreview"
        [form]="form"
        (radioFieldDeleteEvent)="handleDeleteControl($event)"
        (duplicateControl)="handleDuplicateControl($event)"
        (textAreaUpdateEvent)="handleUpdateControl($event)"
      >
      </app-fbc-radio>
    </ng-container>
    <ng-container *ngSwitchCase="'checkbox'">
      <app-fbc-checkbox
        [control]="fieldItem"
        [elementIndex]="elementId"
        [columnIndex]="columnId"
        [rowIndex]="rowId"
        [isColumn]="isColumn"
        [isPreview]="isPreview"
        [form]="form"
        (CheckboxFieldDeleteEvent)="handleDeleteControl($event)"
        (duplicateControl)="handleDuplicateControl($event)"
        (textAreaUpdateEvent)="handleUpdateControl($event)"
      >
      </app-fbc-checkbox>
    </ng-container>
    <ng-container *ngSwitchCase="'select'">
      <app-fbc-select
        [control]="fieldItem"
        [elementIndex]="elementId"
        [columnIndex]="columnId"
        [rowIndex]="rowId"
        [isColumn]="isColumn"
        [isPreview]="isPreview"
        [form]="form"
        (SelectFieldDeleteEvent)="handleDeleteControl($event)"
        (duplicateControl)="handleDuplicateControl($event)"
        (textAreaUpdateEvent)="handleUpdateControl($event)"
      >
      </app-fbc-select>
    </ng-container>
    <ng-container *ngSwitchCase="'file'">
      <app-fbc-file-upload
        [control]="fieldItem"
        [elementIndex]="elementId"
        [columnIndex]="columnId"
        [rowIndex]="rowId"
        [isColumn]="isColumn"
        [form]="form"
        [isPreview]="isPreview"
        (FileUploadDeleteEvent)="handleDeleteControl($event)"
        (duplicateControl)="handleDuplicateControl($event)"
      >
      </app-fbc-file-upload>
    </ng-container>
    <ng-container *ngSwitchCase="'heading'">
      <app-fbc-heading
        [control]="fieldItem"
        [elementIndex]="elementId"
        [columnIndex]="columnId"
        [rowIndex]="rowId"
        [isColumn]="isColumn"
        [isPreview]="isPreview"
        (HeadingDeleteEvent)="handleDeleteControl($event)"
        (duplicateControl)="handleDuplicateControl($event)"
        (headingUpdateEvent)="handleUpdateControl($event)"
      >
      </app-fbc-heading>
    </ng-container>
    <ng-container *ngSwitchCase="'column'">
      <app-fbc-column
        [control]="fieldItem"
        [elementIndex]="elementId"
        [columnIndex]="columnId"
        [rowIndex]="rowId"
        [isColumn]="isColumn"
        [isPreview]="isPreview"
        (HeadingDeleteEvent)="handleDeleteControl($event)"
        (duplicateControl)="handleDuplicateControl($event)"
      >
      </app-fbc-column>
    </ng-container>
  </div>
</ng-container>
