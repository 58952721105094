<div [formGroup]="form">
  <input
    #textInput
    [disabled]="disabled"
    *ngIf="!multiline && type != 'checkbox'"
    [value]="getControl().value"
    class="form-control"
    [id]="fieldId"
    [type]="type"
    placeholder="{{ placeholder }}"
    (change)="onEditableChange(textInput.value)"
    [ngClass]="{ hasContent: hasContent }"
    (keydown)="onKeyDown()"
    (keyup)="onKeyUp($event)"
    (click)="onClick()"
  />

  <textarea
    #textInput
    [disabled]="disabled"
    *ngIf="multiline"
    [value]="getControl().value"
    class="form-control"
    [id]="fieldId"
    rows="3"
    placeholder="{{ placeholder }}"
    (change)="onEditableChange(textInput.value)"
    [ngClass]="{ hasContent: hasContent }"
    (keydown)="onKeyDown()"
    (keyup)="onKeyUp($event)"
    (click)="onClick()"
  ></textarea>

  <input
    #textInput
    [disabled]="disabled"
    *ngIf="type === 'checkbox'"
    type="checkbox"
    [checked]="getControl().value"
    class="form-check-input"
    [id]="fieldId"
    [type]="type"
    (change)="onEditableChange(textInput.value)"
    [ngClass]="{ hasContent: hasContent }"
    (keydown)="onKeyDown()"
    (keyup)="onKeyUp($event)"
    (click)="onClick()"
  />
  <!-- <div contenteditableModel *ngIf="multiline" [id]="fieldId" [ngClass]="{
      hasContent: hasContent, 
      textEdit: true,
      'outline-none': true,
      'ng-touched': isTouched
    }" contentEditable="true" [contenteditableModel]="getControl().value"
    (contenteditableModelChange)="onEditableChange($event)" (click)="onEditableClick()"></div> -->
  <span class="highlight"></span> <span class="bar"></span>

  <span class="span error" *ngFor="let errorText of errorsToDisplay">{{
    errorText
  }}</span>
</div>
