import { Component, Inject, OnInit } from "@angular/core";
import { DialogRef } from "src/app/ui/service/dialog-ref";
import { DIALOG_DATA } from "src/app/ui/service/dialog-tokens";
import { IPermission, IPermissionType } from "src/app/user/types/user";
import { GroupService } from "../../../service/user.service";
type permissionValue = { permissionId: string; types: string[] };
const AllTypes = ["All", "View", "Create", "Edit", "Delete", "Approve"];
@Component({
  selector: "app-create-group",
  templateUrl: "./create-group.component.html",
  styleUrls: ["./_create-group.component.scss"],
})
export class CreateGroupComponent implements OnInit {
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any
  ) { }
  permissionData: IPermission[];
  permissionTypeData: IPermissionType;
  permissions: permissionValue[] = [];
  groupName: string = '';
  groups;
  isGroup: boolean = true;
  ngOnInit(): void {
    this.permissionData = this.data.permission;
    this.permissionTypeData = this.data.permissionType;
    if (this.data?.editData) {
      this.groupName = this.data.editData.groupName
      this.permissions = this.data.editData.groupPermission
    }
  }

  handleChangeName(event) {
    this.groupName = event.target.value;
  }

  handleCheckValueExistes(name, value) {
    if (this.permissions.length > 0) {
      let index = this.permissions.findIndex(
        (item) => item.permissionId === name
      );
      if (index > -1) {
        if (this.permissions[index].types.includes(value)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
  handleChangePermission(event) {
    const { name, value, checked } = event.target;
    const index = this.permissions.findIndex(
      (item) => item.permissionId === name
    );

    if (checked) {
      let typesToAdd = [value];

      if (value === "All") {
        typesToAdd = [];
        typesToAdd = AllTypes.filter((type) => type);
      }

      if (index === -1) {
        this.permissions.push({
          permissionId: name,
          types: typesToAdd,
        });
      } else {
        const types = this.permissions[index].types;

        if (value === "All") {
          types.splice(0, types.length, ...typesToAdd);
        } else {
          types.push(value);
        }
      }
    } else if (index !== -1) {
      const types = this.permissions[index].types;

      if (value === "All") {
        types.length = 0;
      } else {
        const AllIndex = types.indexOf("All");
        if (AllIndex !== -1) {
          types.splice(AllIndex, 1);
        }
        const valueIndex = types.indexOf(value);
        if (valueIndex !== -1) {
          types.splice(valueIndex, 1);
        }
      }
    }

    console.log("permissions", this.permissions);
  }

  onSubmit() {
    if (this.groupName == "" && this.permissions.length === 0) {
      return;
    }
    this.dialogRef.close({
      name: this.groupName,
      permissions: this.permissions,
    });
  }

  close() {
    this.dialogRef.close();
  }
}
