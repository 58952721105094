import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountPlanBuyService } from 'src/app/main/services/account.service';

@Component({
  selector: 'app-success-page',
  template: `
    <section class="plan_success">
      <div class="container">
        <div class="row justify-content-center ">
          <div class="col-12 col-lg-7">
            <h1 class="title">Payment Successful</h1>
            <p class="description">Thank you for your purchase! Your payment has been processed successfully.</p>
            <a class="btn btn-outline-dark w-auto" *ngIf="isLoading">Please Wait!!!!!!!!!</a>
            <a routerLink="/dashboard" class="btn btn-outline-dark w-auto" *ngIf="!isLoading">Go to Dashboard</a>
          </div>
        </div>
      </div>
    </section>
  `,
  styles: [`
      .plan_success {
        padding: 17.4rem 0rem 7.16rem 0rem;
      }

      .plan_success .title {
        color: #333333; 
        text-align: center;
        font-family: 'Arial', sans-serif; 
        font-size: 28px; 
        font-style: normal; 
        font-weight: 400; 
        line-height: 1.5; 
        letter-spacing: 0.084rem;
        -webkit-text-stroke-width: thin;
        -webkit-text-stroke-color: #333333; 
        margin-bottom: 1.2rem; 
      }

      .plan_success .description {
        color: #999999; 
        text-align: center;
        font-family: 'Arial', sans-serif;
        font-size: 14px; 
        font-style: normal; 
        font-weight: 400; 
        line-height: 1.57143;
        margin-bottom: 0rem;
        font-feature-settings: "liga" off, "clig" off;
        max-width: 55rem;
        margin: 0 auto;
      }
    `]
})
export class SuccessPageComponent implements OnInit {

  isLoading: boolean = true;

  constructor(
    private planBuyService: AccountPlanBuyService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const planId = params['planId'];
      if (planId) {
        this.purchasePlan(planId);
      }
    });
  }

  async purchasePlan(planId: string) {
    const postData = { planId: planId };

    this.planBuyService.create(postData).subscribe({
      next: async (session: any) => {
        this.isLoading = false;
        console.log('Plan Purchased successfully');
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Error during checkout session creation:', err);
      },
      complete: () => {
        this.isLoading = false;
        console.log('Plan Purchased successfully');
      },
    });
  }
}
