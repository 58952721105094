<ng-container *ngIf="control as control">
  <div class="form-group {{ control.customClass }}">
    <label class="form-label">{{ control.label }}</label>
    <textarea rows="{{ control.rows }}" class="form-control" id="{{ control.id }}" *ngIf="!isPreview"
      placeholder="{{ control.placeHolder }}"></textarea>
    <form *ngIf="isPreview" [formGroup]="form">
      <textarea rows="{{ control.rows }}" class="form-control" id="{{ control.id }}"
        placeholder="{{ control.placeHolder }}" formControlName="{{ control.name }}"></textarea>

      <ng-container *ngIf="
            isPreview === true &&
            form?.get(control.name || '')?.hasError('required') &&
            (form?.get(control.name || '')?.dirty ||
              form?.get(control.name || '')?.touched)
          ">
        <span class="text-danger"> {{ control.label }} is required</span>
      </ng-container>
    </form>

  </div>
  <!-- <ng-template > -->
  <control-action [isPreview]="isPreview" [isColumn]="isColumn" (duplicate)="duplicateTextAreaFieldControl(control)"
    (update)="editInputFieldDialog(control, elementIndex, columnIndex, rowIndex)"
    (delete)="deleteTextField(control, elementIndex, columnIndex, rowIndex)"></control-action>

  <!-- </ng-template> -->
</ng-container>