import { GenericApi } from "../../main/api/generic.api";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
// data - table / record;
@Injectable()
export class DataTableApi extends GenericApi {
    public path = "data-table";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}

@Injectable()
export class DataTableRecordApi extends GenericApi {
    public path = "data-table_record";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}
