import { CdkDropList } from "@angular/cdk/drag-drop";
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-fbc-field-view",
  templateUrl: "./fbc-field-view.component.html",
  styleUrls: ["./fbc-field-view.component.sass"],
})

export class FbcFieldViewComponent {
  @Input() fieldItem: any | null = null;
  @Input() form: FormGroup
  @Input() rowId: number;
  @Input() columnId: number;
  @Input() elementId: number;
  @Input() isColumn: boolean;
  @Input() isPreview: boolean;
  @Output() deleteControl = new EventEmitter<any>();
  @Output() duplicateControl = new EventEmitter<any>();
  @Output() updatedControl = new EventEmitter<any>();

  handleDeleteControl = (e) => {
    this.deleteControl.emit(e);
  };

  handleDuplicateControl = (e) => {
    this.duplicateControl.emit(e);
  };

  handleUpdateControl = (e) => {
    this.updatedControl.emit(e);
  };

}
