import { Component } from '@angular/core';
import { CommonController } from '../util/common.controller';

@Component({
  selector: 'client-review',
  styleUrls: ['./_client-review.component.scss'],
  template: `
   <section class="reach">
    <div class="container-fluid">
      <h2 class="title text-center mb-0">
        See how FusionFlow reaches everywhere!
      </h2>
      <p class="description text-center">
        Get a no-obligation personalised price quote today
      </p>
      <div class="client-review owl-carousel" style="display: block;" >
        <owl-carousel-o [options]="carouselOptions">
          <ng-template carouselSlide *ngFor="let client of clients">
            <div class="card">
              <div class="card-body">
                <div class="head">
                  <span>
                    <img [src]="client.image" alt="client" />
                  </span>
                  <div>
                    <h4>{{ client.name }}</h4>
                    <p class="mb-0">{{ client.location }}</p>
                  </div>
                </div>
                <div class="comment">
                  <p class="comnt-desc">
                    {{ client.comment }}
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </section>

	`,
  styles: [``]
})
export class ClientReviewComponent extends CommonController {
  clients = [
    {
      name: 'Steele Parker',
      location: 'Australia',
      comment: 'My other favourite template is the furnishing growth animation template which wows the viewers every time. Thank you Jubal',
      image: '/assets/img/client-1.svg'
    },
    {
      name: 'Amanda Schinner',
      location: 'Berkshire',
      comment: 'My other favourite template is the furnishing growth animation template which wows the viewers every time. Thank you Jubal',
      image: '/assets/img/client-2.svg'
    },
    {
      name: 'Kate Halvorson',
      location: 'Mexico',
      comment: 'My other favourite template is the furnishing growth animation template which wows the viewers every time. Thank you Jubal',
      image: '/assets/img/client-3.svg'
    },
    {
      name: 'Jasmine Roberts',
      location: 'Colombia',
      comment: 'My other favourite template is the furnishing growth animation template which wows the viewers every time. Thank you Jubal',
      image: '/assets/img/client-4.svg'
    },
    {
      name: 'Louis Bergnaum',
      location: 'Morocco',
      comment: 'My other favourite template is the furnishing growth animation template which wows the viewers every time. Thank you Jubal',
      image: '/assets/img/client-5.svg'
    }
  ];

  carouselOptions = {
    loop: true,
    margin: 10,
    dots: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  }
}