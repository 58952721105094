
import { Component } from '@angular/core';
import { LoaderService } from '../service/loader.service';

@Component({
  selector: 'app-loader',
  template: `
  <div class="text-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
`,
  styles: [``],
})
export class LoaderComponent {
  constructor(public loaderService: LoaderService) { }
}
