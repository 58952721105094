<section class="company-list">
  <div class="list-head">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 px-lg-0 d-flex justify-content-between">
          <div>
            <h2 class="title">Select your company account</h2>
            <p class="description">
              Select your company account, assign tasks, and monitor
              availability and performance.
            </p>
          </div>
          <div>
            <button
              class="btn btn-primary"
              (click)="handleOpenDialog()"
              type="button"
            >
              <span>
                <img src="/assets/icons/plus-dark.svg" alt="plus-light" />
              </span>
              Add New Account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="company-list-cards">
    <div class="container-fluid">
      <div class="row row-gap-4">
        <div
          class="col-12 col-md-6 col-lg-5"
          *ngFor="let item of accounts; let i = index"
        >
          <div class="card company-card">
            <div class="card-head"></div>
            <label
              class="card-body cursor-pointer"
              for="{{ 'checkCard1' + i }}"
            >
              <div class="d-flex align-items-center justify-content-start">
                <div class="card-img">
                  <img
                    src="{{
                      item.companyLogo != null
                        ? endpoint + item.companyLogo
                        : '/assets/img/spark-logo.webp'
                    }}"
                    alt="spark-logo"
                  />
                </div>
                <div>
                  <h5>
                    <a href="dashboard.html" class="btn">
                      {{ item.companyName }}
                    </a>
                  </h5>
                  <p>
                    <a href="https://technology@Sparkbusiness.com" class="btn">
                      {{ useremail }}
                    </a>
                  </p>
                </div>
                <div>
                  <span *ngIf="item.planId" class="badge">{{
                    item.plan?.planName
                  }}</span
                  ><span *ngIf="!item.planId" class="badge"
                    >No Active Plans</span
                  >
                </div>
              </div>
              <div class="d-flex justify-content-end mb-0">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    (change)="handleSwitchAccount(item.id)"
                    name="checkCard"
                    id="{{ 'checkCard1' + i }}"
                    [checked]="activatedAccount === item.id ? true : false"
                  />
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
