import { Injectable } from '@angular/core';

export enum StorageServiceKey {
  jwt = 'jwt',
  jwt_refresh = 'jwt_refresh',
  jwt_token_type = 'jwt_token_type',
}

@Injectable()
export class StorageService {
  constructor() {
  }

  getItem(key: StorageServiceKey, defaultValue?: any): any {
    const storageItem = localStorage.getItem(key as string);

    return !!storageItem ? JSON.parse(storageItem) : defaultValue;
  }

  setItem(key: StorageServiceKey, value: any): void {
    localStorage.setItem(key as string, JSON.stringify(value));
  }

  removeItem(key: StorageServiceKey): any {
    localStorage.removeItem(key as string);
  }
}
