import { Component, OnInit } from '@angular/core';
import { AccountPlanBuyService, AccountPlanService, CreateCheckoutService } from '../../services/account.service';
import { AccountPlan } from '../../model/account.model';
import { JwtService } from '../../services/jwt.service';
import { Router } from '@angular/router';
import { ErrorMessage } from "src/app/main/model/api.model";
import { PublicService } from '../../services/public.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./_pricing.component.scss']
})
export class PricingComponent implements OnInit {
  constructor(
    private jwtService: JwtService,
    private planService: AccountPlanService,
    private router: Router,
    private planBuyService: CreateCheckoutService,
    private publicService: PublicService
  ) { }

  planData: AccountPlan[] = [];
  accountId: string = '';
  purchasedPlan: string = '';
  isLogin: boolean = true;


  async ngOnInit() {
    this.getPlans();
    this.monitorJwtData();
  }

  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.isLogin = true;
          this.purchasedPlan = data?.planDetail?.id || '';
          this.accountId = data.account.id;
        } else {
          this.isLogin = false;
        }
      },
    });
  }

  // Updated purchase plan method
  async purchasePlan(planId: string) {
    if (!this.isLogin) {
      return this.router.navigate(['/login/']);
    }

    const postData = {
      planId: planId,
      accountId: this.accountId,
    };

    this.planBuyService.create(postData).subscribe({
      next: async (session: any) => {
        window.location.href = session.url
      },
      error: (err: ErrorMessage) => {
        console.error('Error during checkout session creation:', err);
      },
      complete: () => console.log('Checkout session initiated successfully'),
    });

  }

  getPlans() {
    this.publicService.getPlans('').subscribe({
      next: (data) => {
        const newData: any = [];
        let i = 0;
        while (i < data.rows.length) {
          newData.push({ ...data.rows[i], planPerks: JSON.parse(data.rows[i].planPerks.replace(/'/g, '"')) });
          i++;
        }
        this.planData = newData.sort((a, b) => a.index - b.index);
      },
    });
  }
}
