import { CommonController } from "../util/common.controller";
import { Component } from "@angular/core";
import { Router, NavigationEnd } from '@angular/router';

@Component({
  template: `
    <banner></banner>
    <tech-stack></tech-stack>
    <workflow-info></workflow-info>
		<config-feature></config-feature>
    <client-review></client-review>
    <contact-form></contact-form>
		<faq></faq>
  `
})
export class HomeComponent extends CommonController {
  constructor(
    private router: Router
  ) {
    super();

    this.enableAnchorScrolling();
    this.autoScrollTop();
  }

  private autoScrollTop() {
    let lastKnownUrl: string | null = null;
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        const currentUrl = this.router.url.split('#')[0];

        if (!lastKnownUrl || currentUrl !== lastKnownUrl) {
          this.scrollTop();
        }

        lastKnownUrl = currentUrl;
      }
    });
  }
}
