import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancel-page',
  template: `
    <section class="plan_cancel">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-7">
            <h1 class="title" >Payment Canceled</h1>
            <p class="description" >Your payment was not completed. Please try again or contact support if you need assistance.</p>
            <a routerLink="/pricing" class="btn btn-outline-dark w-auto">Go Back to Pricing</a>
          </div>
        </div>
      </div>
    <section>
`,
  styles: [`
      .plan_cancel {
        padding: 17.4rem 0rem 7.16rem 0rem;
      }

      .plan_cancel .title {
        color: #333333; 
        text-align: center;
        font-family: 'Arial', sans-serif; 
        font-size: 28px; 
        font-style: normal; 
        font-weight: 400; 
        line-height: 1.5; 
        letter-spacing: 0.084rem;
        -webkit-text-stroke-width: thin;
        -webkit-text-stroke-color: #333333; 
        margin-bottom: 1.2rem; 
      }

      .plan_cancel .description {
        color: #999999; 
        text-align: center;
        font-family: 'Arial', sans-serif;
        font-size: 14px; 
        font-style: normal; 
        font-weight: 400; 
        line-height: 1.57143;
        margin-bottom: 0rem;
        font-feature-settings: "liga" off, "clig" off;
        max-width: 55rem;
        margin: 0 auto;
      }

    `]
})
export class CancelPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
