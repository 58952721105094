export const environment = {
    production: true,
    region: 'ap-southeast-2',
    endpoint: 'https://dev.api.fusionflow.com.au',
    logLevel: 'debug',
    buildId: process.env.NG_APP_BUILD_ID || 'local',
    defaultCollectionLimit: 30,
    uploadMaxSize: 512000000,
    uploadMaxDuration: 30,
    uploadChunkSize: 20000000
};
