import {
  CdkDragDrop,
  CdkDropList,
  DragDropModule,
} from "@angular/cdk/drag-drop";
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilderService } from "src/app/forms-template/service/form-builder.service";
import { ColumnControl } from "./column-control";

@Component({
  selector: "app-fbc-column",
  templateUrl: "./fbc-column.component.html",
  styleUrls: ["./fbc-column.component.sass"],
})
export class FbcColumnComponent {
  constructor(private formBuilderService: FormBuilderService) {}
  @Input() column: any;
  null = null;
  @Input() control: ColumnControl | null = null;
  @Input() elementIndex!: number;
  @Input() columnIndex!: number;
  @Input() rowIndex!: number;
  @Input() myFormArry!: any;
  @Input() todoList!: CdkDropList;
  @Input() isColumn: boolean;
  @Input() isPreview: boolean;
  @Output() HeadingDeleteEvent = new EventEmitter<{
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();

  @Output() duplicateControl = new EventEmitter<ColumnControl>();
  deleteTextField(
    control: ColumnControl,
    index: number,
    columnIndex: number,
    rowIndex: number
  ) {
    this.HeadingDeleteEvent.emit({
      elementIndex: index,
      columnIndex: columnIndex,
      rowIndex: rowIndex,
    });
  }

  duplicateFileUploadControl(control: ColumnControl): void {
    this.duplicateControl.emit(control);
  }

  onFileSelected(e: any) {}

  // drop(event: CdkDragDrop<string[]>): void {
  //   this.formBuilderService.drop(
  //     event,
  //     event.previousContainer.data,
  //     event.container.data,
  //     true
  //   );
  // }
}
