import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { GenericService } from '../../main/services/generic.service';
import { IWorkflow } from '../types/workflow';
import { GenericApi } from 'src/app/main/api/generic.api';
import { WorkflowInstanceApi } from '../api/workflow-instance.api';
import { v4 as uuidv4 } from 'uuid';
@Injectable()
export class WorkflowInstanceService extends GenericService<IWorkflow> {
	constructor(
		public workflowInstanceApi: WorkflowInstanceApi,
		public notifications: NotificationsService
	) {
		super(workflowInstanceApi as GenericApi<any>, notifications);
	}
	convertToWorkFlowStepData(data) {
		const arr: any = [];
		const sortedData = this.sortByIndexAscending(data.workflowInstanceStep);

		sortedData.forEach((step) => {
			const triggers = step.workflowInstanceStepTrigger.length > 0
				? step.workflowInstanceStepTrigger.map((trigger) => {
					const triggerFilter = JSON.parse(trigger.filter);
					const key = Object.keys(triggerFilter)[0];
					const [_, middleString, lastString] = key.split(".");

					const actions = trigger.workflowInstanceStepActions.length > 0
						? trigger.workflowInstanceStepActions.map((action) => {
							const config = action.config ? JSON.parse(action.config) : {};
							const actionId = action.id;
							const defaultTemplateId = uuidv4();

							if (action.action.includes('task')) {
								return {
									id: actionId,
									title: config.taskName,
									event: 'Task',
									templateId: action.config === null ? defaultTemplateId : config.taskId,
									type: 'action',
									filter: config,
									condition: JSON.parse(action.condition),
									response: action.response ? JSON.parse(action.response) : null
								};
							} else if (action.action.includes('email')) {
								return {
									id: actionId,
									title: config.subject,
									event: "send",
									templateId: config.emailTemplateId,
									templateTitle: config.subject,
									type: 'email',
									filter: config.context,
									to: config.destination,
									subject: config.subject,
									condition: JSON.parse(action.condition),
									response: action.response ? JSON.parse(action.response) : null
								};
							}
							else if (action.action.includes('setValues')) {
								return {
									id: actionId,
									title: "setValue",
									event: "setValue",
									templateId: "",
									templateTitle: "",
									type: "all",
									config: JSON.parse(action.variables),
									response: action.response ? JSON.parse(action.response) : null
								}
							}
							else if (action.action.includes('dataTable') && trigger.trigger.includes("Webhook")) {
								return {
									id: actionId,
									title: 'dataTableRecord.' + action.action.split(".")[1],
									event: "dataTableRecord",
									templateId: config.dataTableId || defaultTemplateId,
									type: 'webhook',
									templateTitle: config?.templateTitle || '',
									templateType: "table",
									condition: JSON.parse(action.condition),
									response: action.response ? JSON.parse(action.response) : null
								};
							} else if (action.action.includes('formInstance') && trigger.trigger.includes("Webhook")) {
								return {
									id: actionId,
									title: 'FormInstance.' + action.action.split(".")[1],
									event: "FormInstance",
									templateId: config.formTemplateId || defaultTemplateId,
									type: 'webhook',
									templateTitle: config?.templateTitle || '',
									templateType: "form",
									condition: JSON.parse(action.condition),
									response: action.response ? JSON.parse(action.response) : null
								};
							} else if (action.action.includes('send') && trigger.trigger.includes("Webhook")) {
								let actionConfig = {
									webhookUrl: config.requestUrl,
									method: config.method,
									headers: JSON.parse(config?.headers || '{}'),
									postData: JSON.parse(config?.postData || '{}'),
									condition: JSON.parse(action.condition),
									response: action.response ? JSON.parse(action.response) : null
								}
								return {
									id: actionId,
									title: 'webhook.' + action.action.split(".")[1],
									event: "webhook",
									templateId: config.formTemplateId || defaultTemplateId,
									type: 'webhook',
									templateTitle: config?.requestUrl || '',
									templateType: "url",
									config: actionConfig,
									condition: JSON.parse(action.condition),
									response: action.response ? JSON.parse(action.response) : null
								};
							} else {
								const formatActionName = (action) =>
									action.replace('$', '').replace(/^./, str => str.toUpperCase());

								const determineTitleOrEvent = (action) =>
									action.includes('complete') ? 'complete' : formatActionName(action);

								const getTemplateId = (action, config, defaultTemplateId) => {

									const actionPart = action.action.split(".")[0];
									if (actionPart.includes('$form')) {
										return config.formTemplateId;
									}
									if (actionPart.includes('$DataTable')) {
										return config.datatableId;
									}
									return "";
								};

								const getType = (action) => {
									if (action.config === null) {
										return "action";
									}
									if (action.action.includes('form')) {
										return 'form';
									}
									if (action.action.includes('DataTable')) {
										return 'table';
									}
									return "email";
								};

								return {
									id: actionId,
									title: determineTitleOrEvent(action.action),
									event: action.action.includes('complete') ? 'complete' : determineTitleOrEvent(action.action.split(".")[0]),
									templateId: getTemplateId(action, config, defaultTemplateId),
									templateTitle: action.config === null ? '' : config.name,
									type: getType(action),
									condition: JSON.parse(action.condition),
									response: action.response ? JSON.parse(action.response) : null
								};

							}
						})
						: [{
							id: 'sbt_' + uuidv4(),
							title: "Drop Action Here",
							event: "",
							templateId: "",
							type: "",
						}];
					const getEventType = (trigger) => {
						if (trigger.trigger.includes('Webhook')) {
							return 'webhook';
						}
						return trigger.trigger.split(".")[0];
					};

					const getTemplateId = (trigger, triggerFilter, middleString, lastString) => {
						if (middleString !== "execute" && !trigger.trigger.includes('Webhook')) {
							return triggerFilter[`$event.${middleString}.${lastString}`]['$eq'];
						}
						return "";
					};

					const getTriggerType = (trigger, lastString, middleString) => {
						if (trigger.trigger.includes('Webhook')) {
							return "webhook";
						}
						if (lastString === "formTemplateId") {
							return "form";
						}
						if (middleString === "DataTable") {
							return 'table';
						}
						return "email";
					};

					return {
						id: trigger.id,
						title: trigger.trigger,
						event: getEventType(trigger),
						templateTitle: trigger?.filter ? triggerFilter.templateTitle : '',
						templateId: getTemplateId(trigger, triggerFilter, middleString, lastString),
						type: getTriggerType(trigger, lastString, middleString),
						...this.convertToFilter(triggerFilter, trigger?.nextStep, trigger),
						actions
					};

				})
				: [{
					id: 'sbt_' + uuidv4(),
					title: "Drop Trigger Here",
					event: "",
					templateId: "",
					type: "",
					actions: [{
						id: 'sbt_' + uuidv4(),
						title: "Drop Action Here",
						event: "",
						templateId: "",
						type: "",
					}],
				}];

			arr.push({
				id: step.id,
				title: step.name,
				triggers
			});
		});

		console.log("final arr", arr);
		return arr;
	}
	convertToFilter(data, nextStep, trigger) {
		let filter: any = {};

		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				if (key.includes('$event.formData.')) {
					const filterType = Object.keys(data[key])[0];
					const configKey = key.replace('$event.formData.', '');
					filter.filterType = filterType.replace('$', '');
					filter.config = filter.config || {};
					filter.config[configKey] = data[key][filterType];
				} else if (key.includes('$event.webhook.')) {
					let webhookAction = trigger.workflowInstanceStepActions[0];
					let actionConfig = JSON.parse(webhookAction.config);
					if (webhookAction.action.includes('dataTable') || webhookAction.action.includes('formInstance')) {
						filter = {
							filterType: "webhook",
							webhookType: webhookAction.action == '$dataTable.createRecord' ? "dataTableRecord.create" :
								webhookAction.action == '$formInstance.create' ? 'formInstance' : '',
							config: {
								event: webhookAction.action == '$dataTable.createRecord' ? "dataTableRecord.create" :
									webhookAction.action == '$formInstance.create' ? 'formInstance' : '',
								templateId: webhookAction.action.includes('dataTable') ? actionConfig?.dataTableId :
									webhookAction.action.includes('formInstance') ? actionConfig?.formTemplateId : '',
								templateTitle: actionConfig?.templateTitle
							}
						}
					} else if (webhookAction.action.includes("webhook.send")) {
						let config = JSON.parse(webhookAction.config)
						filter = {
							filterType: "webhook",
							webhookType: "url",
							config: {
								webhookUrl: config.requestUrl,
								method: config.method,
								headers: JSON.parse(config?.headers || '{}'),
								postData: JSON.parse(config?.postData || '{}')
							}
						}
					}
				}
			}
		}
		if (nextStep) {

			filter.nextStepConfig = { nextStepIndex: nextStep };
		}

		return { filter };
	}
	sortByIndexAscending(data) {
		if (Array.isArray(data)) {
			return data
				.map((item) => ({
					...item,
					workflowInstanceStepTrigger: this.sortByIndexAscending(
						item.workflowInstanceStepTrigger
					),
				}))
				.map((item) => ({
					...item,
					workflowInstanceStepActions: this.sortByIndexAscending(
						item.workflowInstanceStepActions
					),
				}))
				.sort((a, b) => a.index - b.index);
		} else if (typeof data === "object") {
			const sortedObject = {};
			for (const key in data) {
				sortedObject[key] = this.sortByIndexAscending(data[key]);
			}
			return sortedObject;
		}
		return data;
	}
}