import { createUrl, queryToParams } from "./api.util";
import { Observable } from "rxjs";
import { IQueryFilter, QueryResult } from "../model/query.filter.class";
import { HttpClient } from "@angular/common/http";
import { HasId } from "../model/generics";
import { Injectable } from "@angular/core";

@Injectable()
export abstract class GenericApi<T = unknown> {
    abstract path: string;

    constructor(public httpClient: HttpClient) { }

    get = (id: number | string): Observable<T & HasId> => {
        return this.httpClient.get<T & HasId>(createUrl(this.path, id));
    };

    list = (query: IQueryFilter): Observable<QueryResult<T & HasId>> => {
        return this.httpClient.get<QueryResult<T & HasId>>(createUrl(this.path), {
            params: queryToParams(query),
        });
    };

    create = (model: Partial<T>): Observable<T & HasId> => {
        return this.httpClient.post<T & HasId>(createUrl(this.path), model);
    };

    update = (id: number | string, model: Partial<T>): Observable<T & HasId> => {
        return this.httpClient.put<T & HasId>(createUrl(this.path, id), model);
    };

    delete = (
        id: number | string,
        path: string
    ): Observable<{ success: boolean; deleted: number }> => {
        let newPath = path ? this.path + path : this.path;
        return this.httpClient.delete<{ success: boolean; deleted: number }>(
            createUrl(newPath, id)
        );
    };
}
