import { GenericApi } from '../../main/api/generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class WorkflowTemplateApi extends GenericApi {
    public path = "workflow-template";

    constructor(
        public httpClient: HttpClient
    ) {
        super(httpClient);
    }
}

@Injectable()
export class WorkflowPublicTemplateApi extends GenericApi {
    public path = "workflow-template/template";

    constructor(
        public httpClient: HttpClient
    ) {
        super(httpClient);
    }
}

@Injectable()
export class WorkflowGenerateURLApi extends GenericApi {
    public path = "workflow-template/generate-url";

    constructor(
        public httpClient: HttpClient
    ) {
        super(httpClient);
    }
}

@Injectable()
export class WorkflowMessageApi extends GenericApi {
    public path = "workflow-message";

    constructor(
        public httpClient: HttpClient
    ) {
        super(httpClient);
    }
}

@Injectable()
export class WorkflowTemplateStepApi extends GenericApi {
    public path = "workflow-template-step";

    constructor(
        public httpClient: HttpClient
    ) {
        super(httpClient);
    }
}

@Injectable()
export class WorkflowTemplateStepTriggerApi extends GenericApi {
    public path = "workflow-template-step-trigger";

    constructor(
        public httpClient: HttpClient
    ) {
        super(httpClient);
    }
}

@Injectable()
export class WorkflowTemplateStepActionApi extends GenericApi {
    public path = "workflow-template-step-action";

    constructor(
        public httpClient: HttpClient
    ) {
        super(httpClient);
    }
}