<ng-container *ngIf="control as control">

  <div class="form-group {{ control.customClass }}">
    <label class="form-label">{{ control.label }}</label><input type="number" class="form-control" id="{{ control.id }}"
      placeholder="{{ control.placeHolder }}" [defaultValue]="control.defaultValue" *ngIf="!isPreview" />
    <form *ngIf="isPreview" [formGroup]="form">
      <input type="number" class="form-control" placeholder="{{ control.placeHolder }}"
        formControlName="{{ control.name }}" />

      <ng-container *ngIf="
            isPreview === true &&
            form?.get(control.name || '')?.hasError('required') &&
            (form?.get(control.name || '')?.dirty ||
              form?.get(control.name || '')?.touched)
          ">
        <span class="text-danger">{{ control.label }} is required</span>
      </ng-container>
    </form>
  </div>
  <control-action [isPreview]="isPreview" [isColumn]="isColumn" (duplicate)="duplicateNumberFieldControl(control)"
    (update)="editInputFieldDialog(control, elementIndex, columnIndex, rowIndex)"
    (delete)="deleteTextField(control, elementIndex, columnIndex, rowIndex)"></control-action>
  <!-- <ng-template > -->

  <!-- </ng-template> -->
</ng-container>