import { GenericApi } from '../../main/api/generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class FormsInstanceApi extends GenericApi {
    public path = "form-instance";

    constructor(
        public httpClient: HttpClient
    ) {
        super(httpClient);
    }
}

@Injectable()
export class PublicFormsInstanceApi extends GenericApi {
    public path = "form-instance/public";

    constructor(
        public httpClient: HttpClient
    ) {
        super(httpClient);
    }
}