import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDashboardService } from '../../services/account.service';
import { WorkflowInstanceService } from 'src/app/workflow/service/workflow-instance.service';
import { JwtService } from '../../services/jwt.service';
import { Projectservice } from '../../services/project.service';

@Component({
  selector: 'app-user-dashbaord',
  templateUrl: './user-dashbaord.component.html',
  styleUrls: ['./_user-dashbaord.component.scss']
})
export class UserDashbaordComponent implements OnInit {
  constructor(public router: Router,
    public userDashboardService: UserDashboardService,
    public workflowInstanceService: WorkflowInstanceService,
    public jwtService: JwtService,
    public projectService: Projectservice
  ) { }
  dashboardData: any;
  taskData: any = [];
  taskType = 'task';
  filterOption: string = '';
  filterValue: string = '';
  projectId: string = '';
  activatedAccount: any
  filterOptions = {
    status: ['Completed', 'On Track', 'At Risk', 'Due', 'Off Track'],
    sortBy: ['Creation date', 'Due date', 'Assign date']
  };

  /**
 * @description Changes the type of tasks displayed and updates the task data accordingly
 * @param e - The selected task type ('task' or 'dueTask')
 * @returns {void}
 */
  changeTaskType(e) {
    this.taskType = e;
    if (e === 'task') {
      this.taskData = this.dashboardData.tasks
    } else {
      this.taskData = this.dashboardData.dueTask
    }
  }

  /**
   * @description Lifecycle hook that is called after data-bound properties are initialized
   * @returns {void}
   */
  ngOnInit(): void {

    this.monitorJwtData()
  }

  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.activatedAccount = data.account;
          this.projectId = this.projectService.getSelectedProject(this.activatedAccount.id) || ''
          this.getUserDashboard()
        }
      },
    });
  }


  /**
   * @description Fetches the user dashboard data from the service and initializes task data
   * @returns {void}
   */
  getUserDashboard() {
    let query = {
      filter: {
        id: this.projectId
      }
    }
    this.userDashboardService.list(query).subscribe({
      next: (data) => {
        this.dashboardData = Object.entries(data);
      },
      error: (err) => console.log(err),
      complete: () => console.log("data fatched successfully")
    })
  }

  convertToSentenceCase(key: string): string {
    if (!key) return '';
    return key.charAt(0).toUpperCase() + key.slice(1);
  }

  /**
   * @description Navigates to the task detail page based on the provided parameters
   * @param id - The ID of the task
   * @param taskId - The ID of the task to be passed as a query parameter
   * @param instanceId - The ID of the instance (optional)
   * @param instanceType - The type of the instance (optional)
   * @returns {void}
   */
  goToTask(id, taskId, workflowInstanceId, instanceType) {
    // if (instanceType === 'workflowInstance') {
    //   this.workflowInstanceService.get(id).subscribe({
    //     next: (data: any) => {
    //       this.router.navigate(["/dashboard/config/workflows/templates/workflow-execute/" + data.workflowTemplateId], {
    //         queryParams: { taskId, workflowInstanceId: instanceId }
    //       })
    //     }
    //   })

    // } else {
    this.router.navigate(['/dashboard/config/forms/task/' + id], { queryParams: { taskId, workflowInstanceId } });
    // }
  }

  /**
 * @description Applies filter to the task data based on the provided option and value
 * @param option - The filter option ('status' or 'sortBy')
 * @param value - The value to filter or sort by
 * @returns {void}
 */
  applyFilter(option: string, value: string) {
    this.filterOption = option;
    this.filterValue = value;
    let tasks = this.taskType === 'task' ? this.dashboardData.tasks : this.dashboardData.dueTask
    if (option === 'status') {
      this.taskData = tasks.filter(task => task.status === value);
    } else if (option === 'sortBy') {
      switch (value) {
        case 'Creation date':
          this.taskData = tasks.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
          break;
        case 'Due date':
          this.taskData = tasks.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());
          break;
        case 'Assign date':
          this.taskData = tasks.sort((a, b) => new Date(a.assignedAt).getTime() - new Date(b.assignedAt).getTime());
          break;
        default:
          break;
      }
    }
    // Implement other filter options
  }

  /**
   * @description Returns the CSS class for the status badge based on the task status
   * @param status - The status of the task ('On Track', 'Completed', 'At Risk', 'Due', 'Off Track')
   * @returns {string} - The CSS class for the badge
   */
  getStatusBadgeClass(status) {
    if (status === 'On Track' || 'Completed') {
      return 'bg-green'
    } else if (status === 'At Risk' || 'Due') {
      return 'bg-orange'
    } else if (status === 'Off Track') {
      return 'bg-red'
    }
  }


  /**
   * @description Returns the CSS class for the priority badge based on the task priority
   * @param status - The priority status of the task ('Low', 'Medium', 'High')
   * @returns {string} - The CSS class for the badge
   */
  getPriorityBedgeClass(status) {
    let classObj = { Low: "bg-red-light", Medium: "bg-green-light", High: "bg-blue-light" }
    return classObj[status]
  }
}
