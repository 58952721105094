import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/main/app.module';
import { environment } from './environments/environment';
import { logger } from './app/main/util/Logger';
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/lib/codemirror";
Sentry.init({
  dsn: "https://6951478ae98b41bcbde91011e900673d@o135869.ingest.sentry.io/6513696",
  environment: environment.production ? 'Production' : 'Development',
  release: environment.buildId,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", "https://api.sparkbusinesstechnology.com.au", "https://api.dev.sparkbusinesstechnology.com.au"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100% 
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 1.0,
});

logger.info(`Starting Application. BuildId[${environment.buildId}]`);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    if (window['ngRef']) {
      window['ngRef'].destroy();
    }
    window['ngRef'] = ref;

    // Otherwise, log the boot error
  })
  .catch(err => console.error(err));
