import { Injectable } from "@angular/core";
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { contactInput, contactOutput, MailApi } from '../api/mail.api';

@Injectable()
export class MailService {
	constructor(
		private mailApi: MailApi,
		public notifications: NotificationsService,
	) { }

	public contact = (opts: contactInput): Observable<contactOutput> => {
		return apiCallWrapper(
			this.mailApi.contact(opts),
			{
				notificationsService: this.notifications,
				action: "Sending",
				message: "Sending enquiry"
			}
		);
	}
}