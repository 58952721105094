import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'control-action',
  template: `  <div class="component-buttons" *ngIf="!isPreview" >
      <a href="javascript:void(0)" role="button" *ngIf="!isColumn"  (click)="editControl()"
        class="btn btn-assign" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
        [title]="'Duplicate'" data-bs-toggle="tooltip"
  data-bs-html="true"
  data-bs-placement="top"
>
        <span><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
  <path d="M12.0909 2.04314C12.2763 1.87094 12.4964 1.73435 12.7387 1.64116C12.981 1.54796 13.2407 1.5 13.503 1.5C13.7652 1.5 14.0249 1.54796 14.2672 1.64116C14.5095 1.73435 14.7296 1.87094 14.9151 2.04314C15.1005 2.21533 15.2476 2.41976 15.348 2.64474C15.4483 2.86973 15.5 3.11086 15.5 3.35439C15.5 3.59791 15.4483 3.83904 15.348 4.06403C15.2476 4.28901 15.1005 4.49344 14.9151 4.66563L5.38331 13.5166L1.5 14.5L2.55909 10.8941L12.0909 2.04314Z" stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
      </a>
      <a href="javascript:void(0)" (click)="duplicateControl()"
        role="button" class="btn btn-edit" data-bs-toggle="tooltip" data-bs-placement="top"
        data-bs-custom-class="custom-tooltip" [title]="'Edit'">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
  <path d="M14.0999 5.90039H7.7999C7.0267 5.90039 6.3999 6.52719 6.3999 7.30039V13.6004C6.3999 14.3736 7.0267 15.0004 7.7999 15.0004H14.0999C14.8731 15.0004 15.4999 14.3736 15.4999 13.6004V7.30039C15.4999 6.52719 14.8731 5.90039 14.0999 5.90039Z" stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.6 10.1H2.9C2.5287 10.1 2.1726 9.9525 1.91005 9.68995C1.6475 9.4274 1.5 9.0713 1.5 8.7V2.4C1.5 2.0287 1.6475 1.6726 1.91005 1.41005C2.1726 1.1475 2.5287 1 2.9 1H9.2C9.5713 1 9.9274 1.1475 10.1899 1.41005C10.4525 1.6726 10.6 2.0287 10.6 2.4V3.1" stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
      </a>
      <a href="javascript:void(0)" (click)="deleteControl()" role="button"
        class="btn btn-dlt" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
        [title]="'Delete'">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
  <path d="M1.5 4.16602H2.77778H13" stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.69401 4.16688V2.88911C4.69401 2.55022 4.82863 2.22521 5.06826 1.98558C5.30789 1.74595 5.6329 1.61133 5.97179 1.61133H8.52734C8.86623 1.61133 9.19124 1.74595 9.43087 1.98558C9.6705 2.22521 9.80512 2.55022 9.80512 2.88911V4.16688M11.7218 4.16688V13.1113C11.7218 13.4502 11.5872 13.7752 11.3475 14.0149C11.1079 14.2545 10.7829 14.3891 10.444 14.3891H4.05512C3.71623 14.3891 3.39123 14.2545 3.1516 14.0149C2.91197 13.7752 2.77734 13.4502 2.77734 13.1113V4.16688H11.7218Z" stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>
      </a>
  </div>`,
  styles: [``]
})

export class ControlActionComponent {
  constructor() { }
  @Input() isPreview!: boolean;
  @Input() isColumn!: boolean;
  @Output() duplicate = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();

  duplicateControl() {
    this.duplicate.emit()
  }


  editControl() {
    this.update.emit()
  }


  deleteControl() {
    this.delete.emit()
  }
}