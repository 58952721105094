import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonController } from '../util/common.controller';
import { FieldRejection, ErrorMessage } from '../model/api.model';
import { MailService } from '../services/mail.service';

@Component({
  selector: 'contact-form',
  template: `
   <section class="contact d-none">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-7">
              <div class="card contact-card">
                <div class="card-body">
                  <form action="">
                    <div class="contact-form-inputs position-relative">
                      <div>
                    <text-input field="name" class="from-control" placeholder="Name" [form]="form"></text-input>
                      </div>
                      <div>
                      <text-input field="email" placeholder="Email" [form]="form" [errors]='{
                          required: "Email Address Required",
                          email: "Invalid Email Address"
                        }'></text-input>
                      </div>
                      <div>
                        <text-input field="phone" placeholder="Contact Number" [form]="form"></text-input>
                      </div>
                      <div>
                       <text-input field="company" placeholder="Company Name" [form]="form"></text-input>
                      </div>
                      <div>
                      	<text-input field="message" placeholder="Message" [form]="form" [multiline]="true" [errors]='{
								required: "Message Required"
							}'></text-input>
                      </div>
                      	<div class="col-12 alert alert-danger" *ngIf="displayValidationErrors(fieldRejections)" [innerHTML]="displayValidationErrors(fieldRejections)"></div>
                      <div class="">
                        <button class="btn btn-dark w-100" [disabled]="!canSubmit(form)" (click)="sendContactUs()" type="button">
                          Get quote now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <div class="contact-content">
                <h4 class="title">
                  Connect, Engage, and Retain with FusionFlow
                </h4>
                <p class="description">
                  Get started today and see how it can become the digital heart
                  of your company.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
	`
})
export class ContactFormComponent extends CommonController {
  form: UntypedFormGroup;
  fieldRejections: FieldRejection[] = [];

  constructor(
    public formBuilder: UntypedFormBuilder,
    public mailService: MailService
  ) {
    super();

    this.form = this.formBuilder.group({
      name: [null],
      email: [null, [Validators.required, Validators.email]],
      phone: [null],
      company: [null],
      message: [null, Validators.required]
    });
  }

  sendContactUs() {
    if (!this.canSubmit(this.form)) return;
    this.form.markAsPending();

    this.mailService.contact(this.form.value)
      .subscribe(
        () => this.form.reset(),
        (err: ErrorMessage) => this.handleValidationErrors(err, this.form, this.fieldRejections),
        () => this.form.markAsUntouched()
      );
  }
}