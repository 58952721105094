
import { NgModule } from '@angular/core';

import { TooltipDirective } from './directive/tooltip.directive';
@NgModule({
  declarations: [
    TooltipDirective
  ],
  exports: [
    TooltipDirective
  ]
})
export class SharedModule { }

