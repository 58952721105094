import { HttpClient } from "@angular/common/http";
import { UtilService } from "../services/util.service";
import { Injectable } from "@angular/core";
import { APISuccess } from "../model/generics";
import { createUrl, getPublicRoutesHeaders } from "./api.util";

@Injectable()
export class publicApi extends UtilService {

  constructor(
    private readonly httpClient: HttpClient
  ) {
    super();
  }

  readonly formInstanceCreate = (opts: any): any => {
    return this.httpClient.post<APISuccess<any>>(
      createUrl('form-instance/public'),
      opts,
      { headers: getPublicRoutesHeaders() }
    )
  }

  readonly formInstanceUpdate = (opts: any, id: string): any => {
    return this.httpClient.put<APISuccess<any>>(
      createUrl('form-instance/public', id),
      opts,
      { headers: getPublicRoutesHeaders() }
    )
  }

  readonly formInstanceGet = (id: string): any => {
    return this.httpClient.get<APISuccess<any>>(
      createUrl('form-instance/public', id),
      { headers: getPublicRoutesHeaders() }
    )
  }

  readonly submitPublicFormParticipent = (opts: any): any => {
    return this.httpClient.post<APISuccess<any>>(
      createUrl('public-form'),
      opts,
      { headers: getPublicRoutesHeaders() }
    )
  }


  readonly getPlans = (id: string): any => {
    return this.httpClient.get<APISuccess<any>>(
      createUrl('plan'),
      { headers: getPublicRoutesHeaders() }
    )
  }

}