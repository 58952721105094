import { Injectable } from "@angular/core";
import { NotificationsService } from 'angular2-notifications';
import { GenericService } from './generic.service'
import { AccountPlanApi, AccountPlanBuyApi, UserAccountsApi, UserSwitchAccountsApi, UserTaskApi, MyTaskApi, ChangeTaskStatusApi, PublicFormApi, PublicFormInstanceApi, GetWorkflowInstanceApi, UserDashboardApi, PublicFormInstanceApiV2, CreateCheckoutApi, TaskActivityAPI, TaskBulkUpdateApi, TaskBulkDeleteApi } from "../api/account.api";
import { GenericApi } from "../api/generic.api";
import { Observable } from "rxjs";
import { apiCallWrapper } from "../api/api.util";

@Injectable()
export class AccountPlanService extends GenericService<any> {
  constructor(
    public accountPlanApi: AccountPlanApi,
    public notifications: NotificationsService
  ) {
    super(accountPlanApi as GenericApi<any>, notifications);
  }
}

@Injectable()
export class AccountPlanBuyService extends GenericService<any> {
  constructor(
    public accountPlanBuyApi: AccountPlanBuyApi,
    public notifications: NotificationsService
  ) {
    super(accountPlanBuyApi as GenericApi<any>, notifications);
  }
}


@Injectable()
export class CreateCheckoutService extends GenericService<any> {
  constructor(
    public createCheckoutApi: CreateCheckoutApi,
    public notifications: NotificationsService
  ) {
    super(createCheckoutApi as GenericApi<any>, notifications);
  }
}

@Injectable()
export class UserAccountService extends GenericService<any> {
  constructor(
    public userAccountsApi: UserAccountsApi,
    public notifications: NotificationsService
  ) {
    super(userAccountsApi as GenericApi<any>, notifications);
  }
}

@Injectable()
export class UserSwitchAccountService extends GenericService<any> {
  constructor(
    public userSwitchAccountsApi: UserSwitchAccountsApi,
    public notifications: NotificationsService
  ) {
    super(userSwitchAccountsApi as GenericApi<any>, notifications);
  }
}

@Injectable()
export class UserTaskService extends GenericService<any> {
  constructor(
    public userTaskApi: UserTaskApi,
    public notifications: NotificationsService,
    public taskActivityService: TaskActivityService
  ) {
    super(userTaskApi as GenericApi<any>, notifications);
  }
  /**
   * 
   * @param formData new updated object for task
   * @param taskPreviousObject previous values object
   * @param activatedAccount user currenct acticated account
   * @param userslist users list to get full name 
   * @param taskID taskId
   * @returns 
   */
  getActivityLog(formData, taskPreviousObject, activatedAccount, userslist, taskId) {
    // Create an empty array for the activity log
    let activityLog: any[] = [];

    // Iterate over keys in taskPreviousObject and compare with formData
    Object.keys(taskPreviousObject).forEach((key) => {
      // Stringify both the old value and the new value to handle complex data structures
      let oldValue = JSON.stringify(taskPreviousObject[key]);
      let newValue = JSON.stringify(formData[key]);

      // Compare the values, if they differ, push to the activity log
      if (oldValue !== newValue) {
        activityLog.push(this.getActivityString(key, JSON.parse(newValue), activatedAccount, userslist));
      }
    });
    let postdata = {
      taskId: taskId,
      log: JSON.stringify(activityLog)
    }
    this.taskActivityService.create(postdata).subscribe({
      next: () => console.log("Task Activity Created"),
      error: (err) => console.log("Task Activity faild", err),
      complete: () => console.log("Task Activity Completed"),
    })
  }
  getActivityString(field, newValue, activatedAccount, userslist) {
    const username = `**${activatedAccount.name}**`;
    const formattedNewValue = `**${newValue}**`;

    const activityMessages = {
      taskName: `${username} changed task title to ${formattedNewValue}`,
      taskForm: `${username} changed task description to ${formattedNewValue}`,
      status: `${username} changed task status to ${formattedNewValue}`,
      priority: `${username} changed task priority to ${formattedNewValue}`,
      dueDate: `${username} changed task due date to ${formattedNewValue}`,
      startDate: `${username} changed task start date to ${formattedNewValue}`,
    };

    if (field === 'reporterId') {
      const reporter = userslist.find(user => user.id === newValue);
      return `${username} changed reporter to **${reporter?.name || 'Unknown Reporter'}**`;
    } else if (field === 'assignedTo') {
      const collaboratorIds = newValue.split(",");
      const collaboratorNames = collaboratorIds.map(id => {
        const user = userslist.find(user => user.id === id);
        return `**${user?.name || 'Unknown Collaborator'}**`;
      }).join(", ");
      return `${username} updated collaborators to ${collaboratorNames}`;
    }
    return activityMessages[field] || `${username} made an unknown change`;
  }
  timeAgo(createdAt: string): string {
    const date = new Date(createdAt);
    const now = new Date();

    // Check if date is valid
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    const secondsAgo = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (secondsAgo < 60) {
      return `${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
    } else if (secondsAgo < 3600) {
      const minutesAgo = Math.floor(secondsAgo / 60);
      return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
    } else if (secondsAgo < 86400) {
      const hoursAgo = Math.floor(secondsAgo / 3600);
      return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
    } else if (secondsAgo < 2592000) { // Less than 30 days (1 month)
      const daysAgo = Math.floor(secondsAgo / 86400);
      return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
    } else if (secondsAgo < 31536000) { // Less than 365 days (1 year)
      const monthsAgo = Math.floor(secondsAgo / 2592000);
      return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`;
    } else {
      const yearsAgo = Math.floor(secondsAgo / 31536000);
      return `${yearsAgo} year${yearsAgo !== 1 ? 's' : ''} ago`;
    }
  }

  /**
* @description add comment
*/
  public readonly createTask = (payload: { taskId: string, comment: string }): Observable<{ success: true }> => {
    return apiCallWrapper(
      this.userTaskApi.addComment(payload),
      {
        notificationsService: this.notifications,
        action: "Add Comment"
      }
    )
  }

  /**
* @description list all comment
*/
  public readonly getTaskComment = (taskId: string) => {
    return apiCallWrapper(
      this.userTaskApi.taskComment(taskId),
      {
        notificationsService: this.notifications,
        action: "Add Comment"
      }
    )
  }

  /**
* @description Edi comment
*/
  public readonly editComment = (payload: { taskId: string, comment: string }, commentId: string) => {
    return apiCallWrapper(
      this.userTaskApi.editComment(payload, commentId),
      {
        notificationsService: this.notifications,
        action: "Edit Comment"
      }
    )
  }

  /**
* @description Delete comment
*/
  public readonly deleteComment = (commentId: string) => {
    return apiCallWrapper(
      this.userTaskApi.deleteComment(commentId),
      {
        notificationsService: this.notifications,
        action: "Delete Comment"
      }
    )
  }
}

@Injectable()
export class MyTaskService extends GenericService<any> {
  constructor(
    public myTaskApi: MyTaskApi,
    public notifications: NotificationsService
  ) {
    super(myTaskApi as GenericApi<any>, notifications);
  }
}

@Injectable()
export class ChangeTaskStatusService extends GenericService<any> {
  constructor(
    public changeTaskStatusApi: ChangeTaskStatusApi,
    public notifications: NotificationsService
  ) {
    super(changeTaskStatusApi as GenericApi<any>, notifications);
  }
}


@Injectable()
export class TaskBulkUpdateService extends GenericService<any> {
  constructor(
    public taskBulkUpdateApi: TaskBulkUpdateApi,
    public notifications: NotificationsService
  ) {
    super(taskBulkUpdateApi as GenericApi<any>, notifications);
  }
}

@Injectable()
export class TaskBulkDeleteService extends GenericService<any> {
  constructor(
    public taskBulkDeleteApi: TaskBulkDeleteApi,
    public notifications: NotificationsService
  ) {
    super(taskBulkDeleteApi as GenericApi<any>, notifications);
  }
}


@Injectable()
export class PublicFormService extends GenericService<any> {
  constructor(
    public publicFormApi: PublicFormApi,
    public notifications: NotificationsService
  ) {
    super(publicFormApi as GenericApi<any>, notifications);
  }
}


@Injectable()
export class PublicFormInstanceService extends GenericService<any> {
  constructor(
    public publicFormApi: PublicFormInstanceApi,
    public notifications: NotificationsService
  ) {
    super(publicFormApi as GenericApi<any>, notifications);
  }
}

@Injectable()
export class PublicFormInstanceServiceV2 extends GenericService<any> {
  constructor(
    public publicFormApiV2: PublicFormInstanceApiV2,
    public notifications: NotificationsService
  ) {
    super(publicFormApiV2 as GenericApi<any>, notifications);
  }
}

@Injectable()
export class GetWorkflowInstanceService extends GenericService<any> {
  constructor(
    public getWorkflowInstanceApi: GetWorkflowInstanceApi,
    public notifications: NotificationsService
  ) {
    super(getWorkflowInstanceApi as GenericApi<any>, notifications);
  }
}


@Injectable()
export class UserDashboardService extends GenericService<any> {
  constructor(
    public userDashbaordApi: UserDashboardApi,
    public notifications: NotificationsService
  ) {
    super(userDashbaordApi as GenericApi<any>, notifications);
  }
}

@Injectable()
export class TaskActivityService extends GenericService<any> {
  constructor(
    public taskActivityAPI: TaskActivityAPI,
    public notifications: NotificationsService
  ) {
    super(taskActivityAPI as GenericApi<any>, notifications);
  }
}


