import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Constants } from "../../constents";
import { ActivatedRoute } from "@angular/router";
import { FormsElementOptionApi } from "src/app/forms-template/api/forms-template.api";
import { FormsElementOptionService } from "src/app/forms-template/service/forms-template.service";
import { DialogRef } from "src/app/ui/service/dialog-ref";
import { DIALOG_DATA } from "src/app/ui/service/dialog-tokens";

@Component({
  selector: "app-input-dialog",
  templateUrl: "./input-dialog.component.html",
  styles: [
    `
      
    `,
  ],
})
export class InputDialogComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private formsElementOptionService: FormsElementOptionService,
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any
  ) { }

  constants: any = Constants;
  inputFieldEditForm!: FormGroup;

  ngOnInit(): void {
    this.validateFormBuilder();
    this.editProperty(this.data);
  }

  editProperty(control: any) {
    if (this.handleCheckOptionsField() === true) {
      this.inputFieldEditForm.patchValue({
        label: control.label,
        isRequired: control.isRequired,
        name: control.name,
      });
      const optionFormGroup = control.options.map((option: any) =>
        this.formBuilder.group(option)
      );
      const optionFormArray = this.formBuilder.array(optionFormGroup);

      this.inputFieldEditForm.setControl("options", optionFormArray);
    } else {
      this.inputFieldEditForm.setValue({
        label: control.label,
        placeHolder: control.placeHolder,
        isRequired: control.isRequired,
        name: control.name,
      });
    }
  }
  createOption(): FormGroup {
    return this.formBuilder.group({
      value: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ],
      ],
      label: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(999),
        ],
      ],
      defaultSelected: [false],
    });
  }

  get options(): FormArray {
    return this.inputFieldEditForm.get("options") as FormArray;
  }

  validateFormBuilder() {
    this.inputFieldEditForm = this.formBuilder.group({
      label: ["", Validators.required],
      ...(this.handleCheckOptionsField() === false && {
        placeHolder: ["", Validators.required],
      }),
      name: ["", Validators.required],
      isRequired: [false],
      ...(this.handleCheckOptionsField() === true && {
        options: this.formBuilder.array([this.createOption()]),
      }),
    });
  }

  onCheckboxChange(event: any) {
    const isChecked = event.target.checked;
    this.inputFieldEditForm.patchValue({ isRequired: isChecked });
  }

  onSubmit(): void {

    if (this.inputFieldEditForm.invalid) {
      return;
    }
    this.inputFieldEditForm.value.type = this.data.type;
    this.dialogRef.close(this.inputFieldEditForm.value);
  }

  close() {
    this.dialogRef.close();
  }

  handleCheckOptionsField() {
    if (
      this.data.type == "select" ||
      this.data.type == "radio" ||
      this.data.type == "checkbox"
    ) {
      return true;
    } else {
      return false;
    }
  }

  onKey(event: any, index: number): void {
    let isError = false;
    this.options.controls.filter((element, elementIndex) => {
      if (
        element.value.value === event.target.value &&
        index !== elementIndex
      ) {
        isError = true;
      }
    });
    if (isError) {
      this.options.controls[index].get("value")?.setErrors({ unique: true });
    }
  }

  radioChange(event: any, index: number): void {
    const optionControls = this.options.controls;
    optionControls.forEach((control, i) => {
      const defaultSelectedControl = control.get("defaultSelected");
      if (defaultSelectedControl) {
        defaultSelectedControl.setValue(i === index && event.target.checked);
        defaultSelectedControl.updateValueAndValidity();
      }
    });
    const optionFormGroup = optionControls.map((option: any) =>
      this.formBuilder.group(option.value)
    );
    const optionFormArray = this.formBuilder.array(optionFormGroup);
    this.inputFieldEditForm.setControl("options", optionFormArray);
  }

  addNewOption(): void {
    this.options.push(this.createOption());
  }

  removeOption(index: number): void {
    if (this.options.controls[index].value.id) {
      this.removeElementOption(this.options.controls[index].value.id);
    }
    this.options.removeAt(index);
  }
  removeElementOption(id) {
    this.formsElementOptionService.delete(id, '').subscribe({
      next: (data) => console.log("Column Deleted"),
      error: (err) => console.log("err", err),
      complete: () => console.log("Form Column Deleted Successfully"),
    });
  }
}
