<ng-container *ngIf="control as control">
  <div class="form-group {{ control.customClass }}">
    <label class="form-label">{{ control.label }}</label>
    <select *ngIf="!isPreview" class="form-control" aria-label="Default select example">
      <option selected>Select an option</option>
      <option *ngFor="let item of control.options" value="{{ item.value }}">
        {{ item.label }}
      </option>
    </select>
    <form *ngIf="isPreview" [formGroup]="form">
      <select class="form-select" aria-label="Default select example" formControlName="{{ control.name }}">
        <option selected>Select an option</option>
        <option *ngFor="let item of control.options" value="{{ item.value }}">
          {{ item.label }}
        </option>
      </select>
      <ng-container *ngIf="
            isPreview === true &&
            form?.get(control.name || '')?.hasError('required') &&
            (form?.get(control.name || '')?.dirty ||
              form?.get(control.name || '')?.touched)
          ">
        <span class="text-danger"> {{ control.label }} is required</span>
      </ng-container>
    </form>
  </div>
  <!-- <ng-template > -->
  <control-action [isPreview]="isPreview" [isColumn]="isColumn" (duplicate)="duplicateSelectFieldControl(control)"
    (update)="editInputFieldDialog(control, elementIndex, columnIndex, rowIndex)"
    (delete)="deleteTextField(control, elementIndex, columnIndex, rowIndex)"></control-action>

  <!-- </ng-template> -->
</ng-container>