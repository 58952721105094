export interface IParanoid {
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
}

export interface IPropertiesConfig<T = any> {
    from?: string;
    to: keyof T;
    by: (val:any, prop?:keyof T) => any;
}

export interface IBaseModel<T = any, I = T> {
    deserialize(input: Partial<I>): this;
    deserialize(input: Partial<I>, properties: (keyof I)[]): this;
}

/** 
 * @note I really like this, awesome idea - Steele
 */
export class BaseModel<T = any, I = T> implements IBaseModel<T, I> {
    deserialize(input: Partial<I>, properties?: (keyof I | IPropertiesConfig<I>)[]): this {
        /* Note: This should only be uncommented in local environments for debugging this function */
        /*
        const signature = "BaseModel.deserialize: ";
        logger.silly(signature+`Decoding Properties[${properties}] from`);
        logger.silly(input);
        */
        if (Array.isArray(properties)) {
            properties.forEach(prop => {
                if( typeof prop === 'string' ) {
                    this.deserializeProperty(prop as keyof I, input[prop])
                } else {
                    const opts = prop as IPropertiesConfig<I>;
                    const rawVal = input[(opts.from||opts.to) as keyof I];
                    this.deserializeProperty(opts.to, opts.by ? opts.by(rawVal) : rawVal);
                }
            });
        } else {
            Object.assign(this, input);
        }
        /*
        logger.silly(signature+`Decoding Result`);
        logger.silly(this);
        */
        return this;
    }

    serialize(): I {
        return JSON.parse(JSON.stringify(this));
    }

    private deserializeProperty(prop: keyof I, value: any): void {
        (this as unknown as I)[prop] = value;
    }
}