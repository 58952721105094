

function getConstatnts(): any {


  const accountValidationMessages = {
      name: [
          { type: 'required', message: 'Name is required.' },
          { type: 'minlength', message: 'Name must be at least 5 characters long.' },
          { type: 'maxlength', message: 'Name cannot be more than 25 characters long.' },
          { type: 'pattern', message: 'Your username must contain only numbers and letters.' }
      ],
      emailAddress: [
          { type: 'required', message: 'Email is required.' },
          { type: 'email', message: 'Please enter a valid email address.' },
          { type: 'pattern', message: 'Email format should be xyz@example.com.' }
      ],
      phoneNumber: [
          { type: 'required', message: 'Phone number is required.' },
          { type: 'minlength', message: 'Phone number must be at least 10 characters long.' },
          { type: 'maxlength', message: 'Phone number cannot be more than 10 characters long.' },
      ],
      message: [
          { type: 'required', message: 'Message is required.' },
          { type: 'minlength', message: 'Message must be at least 6 characters long.' },
          { type: 'maxlength', message: 'Message cannot be more than 255 characters long.' },
      ],
      label: [
          { type: 'required', message: 'Label is required.' },
          { type: 'minlength', message: 'Label must be at least 5 characters long.' },
          { type: 'maxlength', message: 'Label cannot be more than 25 characters long.' }
      ],
      placeHolder: [
        { type: 'required', message: 'Placeholder is required.' },
        { type: 'minlength', message: 'Placeholder must be at least 3 characters long.' },
        { type: 'maxlength', message: 'Placeholder cannot be more than 25 characters long.' }
    ],
      maxlength: [
          { type: 'required', message: 'Maxlength is required.' },
          { type: 'pattern', message: 'Maxlength must contain only numbers.' },
      ],
      minlength: [
          { type: 'required', message: 'Minlength is required.' },
          { type: 'pattern', message: 'Minlength must contain only numbers.' },
      ],
      value: [
          { type: 'required', message: 'Option value is required.' },
          { type: 'minlength', message: 'Sorry, your option value must be between 1 and 50 characters long.' },
          { type: 'pattern', message: 'Sorry, only letters (a-z), numbers (0-9), and periods (- and _) are allowed.' },
          { type: 'unique', message: 'Option value is already exist. Try another.' }
      ],
      value1: [
          { type: 'required', message: 'Option value is required.' },
          { type: 'minlength', message: 'Sorry, your option value must be between 1 and 999 characters long.' }
      ],
      searchAddress: [
          { type: 'unique', message: 'Searched address not found.' }
      ],
      id: [
          { type: 'required', message: 'Unique id is required.' }
      ],
      operationValue: [
          { type: 'required', message: 'Operation is required.' }
      ],
      control: [
          { type: 'required', message: 'Operator is required.' },
      ],
      width: [
          { type: 'required', messages: 'Width is required.'},
          { type: 'max', message: 'Please enter a value between 0 and 600' }
      ],
      height: [
          { type: 'required', messages: 'Height is required.'},
          { type: 'max', message: 'Please enter a value between 0 and 600' }
      ]
  };

  const columns = [
      {
          value: 'col-md-1',
          label: 'Column 1'
      },
      {
          value: 'col-md-2',
          label: 'Column 2'
      },
      {
          value: 'col-md-3',
          label: 'Column 3'
      },
      {
          value: 'col-md-4',
          label: 'Column 4'
      },
      {
          value: 'col-md-5',
          label: 'Column 5'
      },
      {
          value: 'col-md-6',
          label: 'Column 6'
      },
      {
          value: 'col-md-7',
          label: 'Column 7'
      },
      {
          value: 'col-md-8',
          label: 'Column 8'
      },
      {
          value: 'col-md-9',
          label: 'Column 9'
      },
      {
          value: 'col-md-10',
          label: 'Column 10'
      },
      {
          value: 'col-md-11',
          label: 'Column 11'
      },
      {
          value: 'col-md-12',
          label: 'Column 12'
      }
  ];

  const operations = [
      {
          value: '+',
          label: 'Addition'
      },
      {
          value: '-',
          label: 'Subtraction'
      },
      {
          value: '*',
          label: 'Multiplication'
      },
      {
          value: '/',
          label: 'Division'
      },
      {
          value: 'x̄',
          label: 'Average'
      },
  ];

  const dataTypes = {
      dataTypeString: 'string',
      dataTypeNumber: 'number',
      dataTypeBoolean: 'boolean'
  };



  const matchPattern = {
      emailPattern: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
      passwordPattern: '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,20})',
      phonePattern: '[6-9]\\d{9}',
      numberPattern: '^[0-9]*$'
  };


  const messages = {
      waringMessage: 'Are you sure you want to remove this field?',
      optionKeyValidationMessage: 'That option key is already exist. Try another.'
  };

  const applicationConstants = {
      applicationTitle: 'SBT Form Build',
      
      accountValidationMessages,
   
      dataTypes,
      matchPattern,
      messages,
      columns,
      operations,
  };

  return applicationConstants;
}

export const Constants = getConstatnts();
