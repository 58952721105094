<ng-container *ngIf="control as control">
  <ng-container [ngSwitch]="control.name">
    <ng-container *ngSwitchCase="'h1'">
      <h1>{{ control.label }}</h1>
    </ng-container>
    <ng-container *ngSwitchCase="'h2'">
      <h2>{{ control.label }}</h2>
    </ng-container>
    <ng-container *ngSwitchCase="'h3'">
      <h3>{{ control.label }}</h3>
    </ng-container>
    <ng-container *ngSwitchCase="'h4'">
      <h4>{{ control.label }}</h4>
    </ng-container>
    <ng-container *ngSwitchCase="'h5'">
      <h5>{{ control.label }}</h5>
    </ng-container>
    <ng-container *ngSwitchCase="'h6'">
      <h6>{{ control.label }}</h6>
    </ng-container>
  </ng-container>
  <!-- <ng-template > -->
  <control-action [isPreview]="isPreview" [isColumn]="isColumn" (duplicate)="duplicateHeadingControl(control)"
    (update)="editHeading(control, elementIndex, columnIndex, rowIndex)"
    (delete)="deleteHeading(control, elementIndex, columnIndex, rowIndex)"></control-action>

  <!-- </ng-template> -->
</ng-container>