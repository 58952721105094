<div
  class="modal fade config-add-modal show"
  id="addformModal"
  tabindex="-1"
  aria-labelledby="addformModalLabel"
  aria-hidden="true"
  style="display: block; padding-left: 0px"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-body">
        <h3>Add New Account</h3>
        <form class="mt-2" (submit)="handleSubmitColValue()">
          <div class="form-group mb-25">
            <label for="LoginName" class="form-label">Full name</label>
            <text-input
              field="accountName"
              placeholder="Enter your name"
              [form]="form"
              [errors]="{
                required: 'Account Name Required',
                serverValidation: 'Rejected by server'
              }"
            ></text-input>
          </div>
          <div class="form-group mb-25">
            <label for="LoginCompany" class="form-label">Company name</label>
            <text-input
              field="companyName"
              placeholder="Enter your Company name"
              [form]="form"
              [errors]="{
                required: 'Company name Required',
                serverValidation: 'Rejected by server'
              }"
            ></text-input>
          </div>
          <div class="form-group mb-25">
            <label for="LoginCompanyLogo" class="form-label"
              >Company logo</label
            >
            <input
              class="form-control"
              (change)="onFileChange($event)"
              type="file"
              placeholder="Enter your Company logo"
            />
          </div>
          <div class="modal-btns">
            <button type="button" class="btn btn-secondary" (click)="close()">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
