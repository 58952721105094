<section class="public_form_section">
    <div class="container-fluid">
        <section class="form-component">
            <div class="component-container mt-5">
                <div class="container custom-form-styles ">
                    <div class="component-flex-content">
                        <form [formGroup]="formInstanceForm" (ngSubmit)="onSubmit()">
                            <div *ngFor="let item of formData?.rows; let idx = index" class="row">
                                <ng-container *ngFor="let column of item.columns; let id = index">
                                    <div class="{{ column.size }}" *ngIf="column.isColumn === false">
                                        <ng-container *ngFor="let element of column.elements; let idVal = index">
                                            <app-fbc-field-view [rowId]="idx" [columnId]="id" [elementId]="idVal"
                                                [fieldItem]="element" [isPreview]="true" [isColumn]="column.isColumn"
                                                [form]="formInstanceForm">
                                            </app-fbc-field-view>
                                        </ng-container>
                                    </div>
                                    <div class="{{ column.size }}" *ngIf="column.isColumn === true">
                                        <div [ngClass]="{ 'column-box': column.elements.length === 0 }">
                                            <ng-container *ngFor="let element of column.elements; let idVal = index">
                                                <app-fbc-field-view [rowId]="idx" [columnId]="id" [elementId]="idVal"
                                                    [fieldItem]="element" [isColumn]="column.isColumn"
                                                    [isPreview]="true" [form]="formInstanceForm"></app-fbc-field-view>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="custom-form-styles-button mt-5">
                                <button type="button" class="btn btn-secondary"> Cancel </button>
                                <button type="submit" class="btn btn-dark">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>