// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { DataGridComponent } from './data-grid.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PaginationComponent } from 'src/app/main/template/pagination/pagination.component';
import { SharedModule } from '../../shared.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
  ],
  declarations: [
    DataGridComponent,
    PaginationComponent,
    // TooltipDirective
  ],
  exports: [
    DataGridComponent,
  ]
})
export class DataGridModule { }