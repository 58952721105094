<ng-container *ngIf="control as control">

  <div class="form-group {{ control.customClass }}">
    <label class="form-label">{{ control.label }}</label>
    <ng-container *ngIf="!isPreview">
      <div class="form-check" *ngFor="let item of control.options; let idVal = index">
        <input class="form-check-input" name="{{ control.name }}" type="checkbox" [defaultChecked]="item.isChecked"
          value="{{ item.value }}" id="{{ item.value + idVal }}" />
        <label class="form-check-label" for="{{ item.value + idVal }}">
          {{ item.label }}
        </label>
      </div>
    </ng-container>
    <form *ngIf="isPreview" [formGroup]="form">
      <div class="form-check" *ngFor="let item of control.options; let idVal = index">
        <input class="form-check-input" type="checkbox" [defaultChecked]="item.isChecked" value="{{ item.value }}"
          id="{{ item.value + idVal }}" (change)="updateHandleChange($event)" />
        <label class="form-check-label" for="{{ item.value + idVal }}">
          {{ item.label }}
        </label>
      </div>

      <ng-container *ngIf="
            isPreview === true &&
            form?.get(control.name || '')?.hasError('required') &&
            (form?.get(control.name || '')?.dirty ||
              form?.get(control.name || '')?.touched)
          ">
        <span class="text-danger"> {{ control.label }} is required</span>
      </ng-container>
    </form>

  </div>
  <!-- <ng-template > -->
  <control-action [isPreview]="isPreview" [isColumn]="isColumn" (duplicate)="duplicateCheckboxFieldControl(control)"
    (update)="editInputFieldDialog(control, elementIndex, columnIndex, rowIndex)"
    (delete)="deleteTextField(control, elementIndex, columnIndex, rowIndex)"></control-action>

  <!-- </ng-template> -->
</ng-container>