import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { filter, skip, tap } from 'rxjs/operators';
import { createUrl } from "./api.util";
import { logger } from '../util/Logger';
import { JwtService } from '../services/jwt.service';

interface IUserDetailResponse {
  id: string;
  name: string;
  companyName: string | null;
  companyLogo: string | null;
  createdById: string;
  createdByAccountId: string;
  planId: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  userAccounts: {
    id: string;
    isDefault: boolean;
    userId: string;
    accountId: string;
    user: {
      id: string;
      email: string;
      isAdmin: boolean | null;
    };
  };
  userGroups: Array<{
    id: string;
    groupId: string;
    accountId: string;
    group: {
      id: string;
      name: string;
      groupPermission: Array<{
        id: string;
        types: string;
        permission: {
          id: string;
          name: string;
        };
      }>;
    };
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class SessionApi {
  private readonly className = 'SessionService';

  public $userData = new BehaviorSubject<IUserDetailResponse | null>(null);
  public $sessionChanged = new BehaviorSubject<boolean>(false);

  constructor(private readonly httpClient: HttpClient, public jwtService: JwtService) {
    this.prepareListeners();
  }

  private _authDataRequest: BehaviorSubject<IUserDetailResponse> | null = null;
  public readAuthData(): Observable<IUserDetailResponse> {
    const signature = `${this.className}.readAuthData: `;

    if (!this._authDataRequest) {
      logger.debug(signature + `Preparing user data request`);

      this._authDataRequest = new BehaviorSubject<IUserDetailResponse>({
        id: '',
        name: '',
        companyName: null,
        companyLogo: null,
        createdById: '',
        createdByAccountId: '',
        planId: null,
        createdAt: '',
        updatedAt: '',
        deletedAt: null,
        userAccounts: {
          id: '',
          isDefault: true,
          userId: '',
          accountId: '',
          user: {
            id: '',
            email: '',
            isAdmin: null,
          },
        },
        userGroups: [],
      });

      this.httpClient.get<IUserDetailResponse>(createUrl('user', 'detail')).subscribe({
        next: (resp) => {
          logger.debug(signature + `Loaded user data`);
          this._authDataRequest?.next(resp);
        },
        error: (err) => {
          logger.debug(signature + `Error loading user data`);
          this._authDataRequest?.error(err);
        },
        complete: () => {
          if (this._authDataRequest) {
            this._authDataRequest.complete();
            this._authDataRequest = null;
          }
        },
      });
    }

    return this._authDataRequest.pipe(
      filter((resp) => !!resp),
      tap(() => logger.warn(signature + `Fetched User Data`))
    );
  }

  private prepareListeners(): void {
    const signature = `${this.className}.prepareListeners: `;
    this.jwtService.currentJwtPayload$.subscribe((payload) => {

      if (payload) {
        this.readAuthData().subscribe((data) => {
          this.processAuthDataResponse(data);
        });
      } else {
        this.$userData.next(null);
        this.$sessionChanged.next(true);
      }
    });
  }

  private processAuthDataResponse(data: IUserDetailResponse) {
    const signature = `${this.className}.processAuthResponse: `;
    this.$userData.next(data);
    logger.warn(signature + `User data processed`);
    this.$sessionChanged.next(true);
  }

  public getUserProperty<T extends keyof IUserDetailResponse>(property: T): IUserDetailResponse[T] | null {
    const userData = this.$userData.value;
    return userData ? userData[property] : null;
  }
}
