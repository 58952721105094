import { Component, Inject } from '@angular/core';
import { DialogRef } from 'src/app/ui/service/dialog-ref';
import { DIALOG_DATA } from 'src/app/ui/service/dialog-tokens';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-manage-group-mambers',
  templateUrl: './manage-group-mambers.component.html',
  styleUrls: ['./_manage-group-mambers.component.scss']
})
export class ManageGroupMambersComponent {
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private chatService: ChatService
  ) { }

  changeChatHost(hostId) {
    let payload = {
      newHostId: hostId
    }
    this.chatService.changeHost(this.data.chatData.id, payload).subscribe({
      next: (res) => {
        this.data.chatData.users = this.data.chatData.users.map(item => ({
          ...item,
          type: item.accountId === hostId ? 'host' : 'user'
        }));
      },
      complete: () => { },
      error: (err) => console.log(err)
    })
  }

  inviteUser(user) {
    const payload = { userId: user.id };
    this.chatService.inviteChatuser(this.data.chatData.id, payload).subscribe({
      next: (res) => {
        let obj = { ...res.result, account: user };
        this.data.chatData.users.push(obj)
      },
      error: (err) => console.error('Error inviting chat user:', err),
      complete: () => console.log('User Invite process completed')
    });
  }

  removeChatUser(userId: string): void {
    const payload = { userId };

    this.chatService.removeChatuser(this.data.chatData.id, payload).subscribe({
      next: () => {
        this.data.chatData.users = this.data.chatData.users.filter(user => user.accountId !== userId);
      },
      error: (err) => console.error('Error removing chat user:', err),
      complete: () => console.log('User removal process completed')
    });
  }

  getNotInvitedUsers() {
    return this.data.users.filter(item1 => !this.data.chatData.users.some(item2 => item2.accountId === item1.id)) || [];
  }

  close() {
    this.dialogRef.close()
  }
}
