import { Component, Inject, OnInit } from "@angular/core";
import { ErrorMessage, FieldRejection } from "../../../main/model/api.model";
import { CommonController } from "../../../main/util/common.controller";
import { DialogRef } from "src/app/ui/service/dialog-ref";
import { DIALOG_DATA } from "src/app/ui/service/dialog-tokens";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-form-instance-participent",
  template: `<div class="modal fade config-add-modal show" id="addformModal" tabindex="-1" aria-labelledby="addformModalLabel" aria-hidden="true" style="display: block; padding-left: 0px">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-body">
        <form class="login_contant">
          <div class="form-group mb-25">
            <label for="TaskName" class="form-label">First Name</label>
            <text-input [type]="'text'" field="firstName" placeholder="First Name" [form]="form" [errors]='{ required: "First Name is Required",
                                            }'></text-input>
          </div>
          <div class="form-group mb-25">
            <label for="TaskName" class="form-label">Last Name</label>
            <text-input [type]="'text'" field="lastName" placeholder="Last Name" [form]="form" [errors]='{ required: "Last Name is Required",
                                            }'></text-input>
          </div>
          <div class="form-group mb-25">
            <label for="TaskName" class="form-label">Email</label>
            <text-input field="email" placeholder="Email" [form]="form" [errors]='{
                      required: "Email Address Required",
                      email: "Invalid Email Address"
                    }'></text-input>
          </div>
           <div class="form-check modal-check">
              <text-input [type]="'checkbox'" [id]="'Consent'" field="concent" placeholder="Email" [form]="form" ></text-input>
              <label for="Consent"  class="form-check-label m3-2">Consent for marketing materials</label>
          </div>
          <div class="modal-btns">
            <button type="submit" (click)="handleSubmitForm()" class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>`,
  styles: [``],
})
export class PublicFormsParticipentDialogComponent extends CommonController implements OnInit {
  form: UntypedFormGroup;
  fieldRejections: FieldRejection[] = [];
  formInstanceId: string = ''
  constructor(private dialogRef: DialogRef, public formBuilder: UntypedFormBuilder,
    @Inject(DIALOG_DATA) public data: any) {
    super();
    this.form = this.formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required]],
      concent: [false],
    });
  }

  ngOnInit(): void {

  }
  handleSubmitForm() {
    this.dialogRef.close(this.form.value);
  }

}