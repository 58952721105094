import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private socket: Socket) { }

  // Join a chat group
  joinGroup(chatId: string) {
    this.socket.emit('join-group', { chatId });
  }

  // Leave a chat group
  leaveGroup(chatId: string) {
    this.socket.emit('leave-group', { chatId });
  }

  // Send a message to the group
  sendMessage(message: any) {
    this.socket.emit('send-message', message);
  }

  // Listen for incoming messages
  onMessageReceived(): Observable<any> {
    return this.socket.fromEvent('message-get');
  }

  // Listen for disconnection
  onDisconnect(): Observable<any> {
    return this.socket.fromEvent('disconnect');
  }

  // Listen for errors
  onError(): Observable<any> {
    return this.socket.fromEvent('error');
  }

  // Disconnect the socket manually
  disconnect() {
    this.socket.disconnect();
  }

  // Reconnect the socket manually (optional)
  reconnect() {
    this.socket.connect();
  }
}
