import { NotificationsService } from "angular2-notifications";
import { slideInAnimation } from "../../util/animations";
import { AuthService } from "../../services/auth.service";
import { CommonController } from '../../util/common.controller';
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { JwtService } from '../../services/jwt.service';
import { Router } from '@angular/router';
import { ErrorMessage, FieldRejection } from '../../model/api.model';
import { serverSide } from '../../util/utils';

declare const google: any;

@Component({
	moduleId: module.id,
	selector: 'login',
	templateUrl: 'login.component.html',
	styleUrls: ['_login.component.scss'],
	animations: [slideInAnimation]
})
export class LoginComponent extends CommonController implements OnInit {
	form: UntypedFormGroup;
	fieldRejections: FieldRejection[] = [];
  passwordFieldType: string = 'password';

	constructor(
		private formBuilder: UntypedFormBuilder,
		private authService: AuthService,
		private jwtService: JwtService,
		private router: Router
	) {
		super();

		this.form = this.formBuilder.group({
			email: [null, [Validators.required, Validators.email], serverSide(this.fieldRejections, 'email')],
			password: [null, Validators.required, serverSide(this.fieldRejections, 'password')]
		});
	}

	togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

	ngOnInit() {
		if (this.authService.isAuthenticated()) {
			this.router.navigate(['/']);
			return;
		}
	}

	googleSignIn() {
		this.loadGoogleSignIn();
	}

	loadGoogleSignIn() {
		const script = document.createElement('script');
		script.src = 'https://accounts.google.com/gsi/client';
		script.onload = () => this.initializeGoogleSignIn();
		document.head.appendChild(script);
	}

	initializeGoogleSignIn() {
		google.accounts.id.initialize({
			client_id: '510037060331-6brqq2aa5t96nlk65q8u7ui1n16ooqvs.apps.googleusercontent.com',
			callback: this.handleCredentialResponse.bind(this)
		});
		google.accounts.id.prompt();
	}

	handleCredentialResponse(response: any) {
		const idToken = response.credential;
		console.log('ID Token: ' + idToken);
	}

	authenticate() {
		if (!this.canSubmit(this.form)) return;

		this.form.markAsPending();

		this.authService.authenticate(this.form.value)
			.subscribe(jwtPayload => {
				this.jwtService.saveJWTData(jwtPayload);
				setTimeout(() => {
					this.jwtService.currentJwtPayload$.subscribe({
						next: (data: any) => {
							if (data?.planDetail && data?.user?.isAdmin) {
								this.router.navigate(['/dashboard']);
							} else if (!data?.user?.isAdmin) {
								this.router.navigate(['/dashboard']);
							} else {
								this.router.navigate(['/plans']);
							}
						},
					});
				}, 1000);

				//
			},
				(err: ErrorMessage) => this.handleValidationErrors(err, this.form, this.fieldRejections),
				() => this.form.markAsUntouched());
	}
}