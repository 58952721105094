import { slideInAnimation } from "../../util/animations";
import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { logger } from "../../util/Logger";
import { JwtService } from "../../services/jwt.service";
import * as Sentry from "@sentry/angular-ivy";
import { Angulartics2GoogleTagManager } from "angulartics2";
import { AuthService } from "../../services/auth.service";

const className = "MainTemplateComponent";
declare let ga: Function;

@Component({
  moduleId: module.id,
  animations: [slideInAnimation],
  selector: 'main-template',
  templateUrl: 'main-template.component.html',
  styleUrls: ['main-template.component.scss']
})
export class MainTemplateComponent implements OnInit {

  year: string = new Date().getFullYear().toString();

  constructor(
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private jwtService: JwtService,
    private router: Router,
    private authService: AuthService
  ) {
    this.configureGoogleAnalytics();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = event.url;
      }
    });
  }

  ngOnInit() {
    this.blurNumberInputOnWheel();
    this.monitorJwtData();
  }

  currentPath: string = ''
  isShowNavigation: boolean = true;

  shouldShowFooter(): boolean {
    return ['/', '/login', '/about', '/features', '/plans', '/forgot'].includes(this.currentPath);
  }

  public isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  private configureGoogleAnalytics() {
    const signature = className + '.configureGoogleAnalytics: ';
    if (!location.protocol.match(/^https/)) {
      logger.silly(signature + 'Analytics not installed due to insecure protocol');
      return;
    }

    this.angulartics2GoogleTagManager.startTracking();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (typeof ga === 'undefined') {
          logger.warn(signature + 'Unable to find analytics function');
          return;
        }
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  prepareRoute(outlet: RouterOutlet, data: string) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData[data];
  }

  /**
    * Monitors JWT data.
    * @description Subscribes to `knownJWTData$` observable and sets user information in Sentry scope when new data is available.
    */
  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          Sentry.configureScope(scope => {
            scope.setUser({
              username: data.user.email,
              email: data.user.email,
              id: data.user.id,
              account_id: data.account.id,
              account_name: data.account.name,
            });
          });
        } else {
          Sentry.configureScope(scope => {
            scope.setUser(null);
          });
        }
      },
    });
  }

  blurNumberInputOnWheel() {
    const signature = className + ".blurNumberInput: ";
    document.addEventListener("wheel", function () {
      if (
        document.activeElement
        && document.activeElement["type"]
        && document.activeElement["type"] === "number"
        && document.activeElement["blur"]
      ) {
        logger.silly(signature + "Prevented mWheel in Number Input");
        document.activeElement["blur"]();
      }
    });
  }

  isShow() {
    return this.currentPath === "/" || this.currentPath === '/about' || this.currentPath === '/features' || this.currentPath === '/plans';
  }
}
