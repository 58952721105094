<app-menu></app-menu>
<section class="dashboard" [class.config]="showConfigClass" [class.workflow-config]="showConfigClassWorkFlow" [ngStyle]="{
    padding:
      currentPath.includes('chat') ||
      currentPath.includes('create-form') ||
      currentPath.includes('add-datatable') ||
      currentPath.includes('edit-form') ||
      currentPath.includes('edit-datatable')
        ? '0px'
        : ''
  }">
  <div *ngIf="
      !currentPath.includes('chat') ||
      !currentPath.includes('create-project') ||
      !currentPath.includes('add-datatable') ||
      !currentPath.includes('edit-form') ||
      !currentPath.includes('edit-datatable')
    ">
    <!-- User-welcome content start here...-->
    <div *ngIf="currentPath === '/dashboard'" class="welcome-user d-flex align-items-center justify-content-between">
      <div>
        <h4 class="mb-0">Welcome to Dashboard {{activatedAccount.name}}</h4>
        <p class="mb-0">Project: {{selectedProject?.projectName}}</p>
      </div>
      <div class="d-flex align-items-center flex-gap">
        <button class="btn" type="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12" fill="none">
            <path
              d="M7.72843 11.8496H11.6783V9.87467H7.72843V11.8496ZM0.816162 0V1.97493H18.5906V0H0.816162ZM3.77856 6.91227H15.6282V4.93734H3.77856V6.91227Z"
              fill="black" />
          </svg>
        </button>
        <button class="btn" type="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path
              d="M4.00958 11.4149V0H3.081V11.4149L1.24708 9.581L0.590576 10.2375L3.21704 12.864C3.30411 12.951 3.42218 12.9999 3.54529 12.9999C3.6684 12.9999 3.78647 12.951 3.87354 12.864L6.5 10.2375L5.8435 9.581L4.00958 11.4149ZM13 2.7625L10.3735 0.136036C10.2865 0.0489956 10.1684 9.91821e-05 10.0453 9.91821e-05C9.92218 9.91821e-05 9.80411 0.0489956 9.71704 0.136036L7.09058 2.7625L7.74708 3.419L9.58101 1.58507V13H10.5096V1.58507L12.3435 3.419L13 2.7625Z"
              fill="black" />
          </svg>
        </button>
      </div>
    </div>
    <!-- User-welcome content end here...-->

    <div class="dashboard-tabs" *ngIf="isShowNavigation">
      <ul class="nav nav-pills" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{ active: currentPath === '/dashboard' }" routerLink="/dashboard"
            role="button">
            Dashboard
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{ active: currentPath.includes('task-board') }"
            routerLink="/dashboard/task-board" role="button">
            Tasks
          </a>
        </li>
        <li class="nav-item d-none" role="presentation">
          <a routerLink="/dashboard/config" class="nav-link" [ngClass]="{ active: currentPath.includes('config') }"
            role="button">
            <span>
              <img src="/assets/icons/Dash-Configs.svg" alt="Dash-Configs" />
            </span>
            Configs
          </a>
        </li>

        <li class="nav-item" role="presentation">
          <a routerLink="/dashboard/config/workflows" [ngClass]="{ active: currentPath.includes('workflows') }"
            role="button" class="nav-link">
            Workflow
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a routerLink="/dashboard/config/users" role="button" class="nav-link">
            Users and Groups
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="javascript:void(0);" role="button" class="nav-link">
            Documents
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="javascript:void(0);" role="button" class="nav-link">
            Timelines
          </a>
        </li>
      </ul>
      <!-- <div class="ms-auto">
      <button class="btn btn-help" type="button">
        <span>
          <img src="/assets/icons/Dash-Help.svg" alt="Dash-Help" />
        </span>
        Help
      </button>
    </div> -->
    </div>
  </div>
  <router-outlet></router-outlet>
</section>