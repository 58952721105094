export enum HeaderTokenEnum {
    NoToken = 'NoToken',
    Bearder = "Bearer"
}

export enum AuthStorageKey {
    jwt = 'jwt',
    jwt_refresh = 'jwt_refresh',
    jwt_token_type = 'jwt_token_type',
}

export interface IJWTPayload {
    accessToken: string;
    refreshToken: string;
    tokenType: "Bearer"
}

export interface IJWTPayloadDecoded {
    account: {
        id: string;
        name: string;
    },
    user: {
        id: string;
        email: string;
    },
    iat: number;
    exp: number;
}

export type IRegisterInputPayload = {
    email: string;
    password: string;
    accountName : string;
    companyName : string;
    companyLogo : string;
}
