import { slideInAnimation } from "../util/animations";
import { AuthService } from "../services/auth.service";
import { CommonController } from '../util/common.controller';
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { matchValues, serverSide } from '../util/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { FieldRejection, ErrorMessage } from '../model/api.model';

@Component({
	template: `
	<section class="login">
  <div class="container-fluid">
    <div class="login-content-flex mx-auto">
      <div class="pb-3">
        <h4 class="login-title">Welcome to fusionFlow</h4>
        <p class="login-desc mb-0">
          Enter new password
        </p>
      </div>
      <div class="card login-card">
        <div class="card-body">
          <form [formGroup]="form">
            <div class="form-group">
              <label for="LoginPassword" class="form-label">Password</label>
              <div class="position-relative">
										<text-input t   [type]="passwordFieldType" field = "password" placeholder = "Enter Password"[form] = "form"[errors] = '{
required: "Password Required",
	serverValidation: "Rejected by server"
								}'></text-input>
             
                <span
                  class="icon pass-icon"
                  (click)="togglePasswordVisibility()"
                >
                  <svg
                    *ngIf="passwordFieldType === 'password'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                  >
                    <path
                      d="M1.5 8C1.5 8 4.95455 1 11 1C17.0455 1 20.5 8 20.5 8C20.5 8 17.0455 15 11 15C4.95455 15 1.5 8 1.5 8Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 11C12.574 11 13.85 9.65685 13.85 8C13.85 6.34315 12.574 5 11 5C9.42599 5 8.15 6.34315 8.15 8C8.15 9.65685 9.42599 11 11 11Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    *ngIf="passwordFieldType === 'text'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                  >
                    <path
                      d="M1.5 8C1.5 8 4.95455 1 11 1C17.0455 1 20.5 8 20.5 8C20.5 8 17.0455 15 11 15C4.95455 15 1.5 8 1.5 8Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 11C12.574 11 13.85 9.65685 13.85 8C13.85 6.34315 12.574 5 11 5C9.42599 5 8.15 6.34315 8.15 8C8.15 9.65685 9.42599 11 11 11Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <line
                      x1="1"
                      y1="1"
                      x2="21"
                      y2="15"
                      stroke="#949CA9"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
						   <div class="form-group">
              <label for="LoginPassword" class="form-label">Confirm Password</label>
              <div class="position-relative">
								  			<text-input [type]="passwordFieldType" field = "confirmPassword" placeholder = "Confirm Password"[form] = "form"[errors] = '{
required: "Password Required",
	isMatching: "Does not match"
								}'></text-input>
					

                <span
                  class="icon pass-icon"
                  (click)="togglePasswordVisibility()"
                >
                  <svg
                    *ngIf="passwordFieldType === 'password'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                  >
                    <path
                      d="M1.5 8C1.5 8 4.95455 1 11 1C17.0455 1 20.5 8 20.5 8C20.5 8 17.0455 15 11 15C4.95455 15 1.5 8 1.5 8Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 11C12.574 11 13.85 9.65685 13.85 8C13.85 6.34315 12.574 5 11 5C9.42599 5 8.15 6.34315 8.15 8C8.15 9.65685 9.42599 11 11 11Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    *ngIf="passwordFieldType === 'text'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                  >
                    <path
                      d="M1.5 8C1.5 8 4.95455 1 11 1C17.0455 1 20.5 8 20.5 8C20.5 8 17.0455 15 11 15C4.95455 15 1.5 8 1.5 8Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 11C12.574 11 13.85 9.65685 13.85 8C13.85 6.34315 12.574 5 11 5C9.42599 5 8.15 6.34315 8.15 8C8.15 9.65685 9.42599 11 11 11Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <line
                      x1="1"
                      y1="1"
                      x2="21"
                      y2="15"
                      stroke="#949CA9"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <button
              type="button"
          [disabled] = "!canSubmit(form)"(click) = "resetPassword()"
              class="btn btn-dark"
              role="button"
            >
              Reset
            </button>


         
          </form>
        </div>
      </div>
    </div>

    <div class="row justify-content-center d-none">
      <div class="col-12 col-lg-5">
        <div
          class="col-12 alert alert-danger"
          *ngIf="displayValidationErrors(fieldRejections)"
          [innerHTML]="displayValidationErrors(fieldRejections)"
        ></div>
      </div>
    </div>
  </div>
</section>

	`,
	styleUrls: ['./_reset.component.scss'],
	animations: [slideInAnimation]
})
export class ResetComponent extends CommonController implements OnInit {
	form: UntypedFormGroup;
	fieldRejections: FieldRejection[] = [];

	private email: string;
	private token: string;
	passwordFieldType: string = 'password';

	constructor(
		private formBuilder: UntypedFormBuilder,
		private authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private jwtService: JwtService,
		private router: Router
	) {
		super();

		this.form = this.formBuilder.group({
			password: [null, Validators.required, serverSide(this.fieldRejections, 'password')],
			confirmPassword: [null, Validators.required, matchValues('password')]
		});
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.token = params.token;
		});

		this.activatedRoute.queryParams.subscribe(queryParams => {
			this.email = queryParams.email;
		});

		this.form.controls['password'].valueChanges.subscribe({
			next: val => {
				if (val.length)
					this.form.controls['confirmPassword'].updateValueAndValidity();
			}
		});
	}

	togglePasswordVisibility() {
		this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
	}

	resetPassword() {
		if (!this.canSubmit(this.form)) return;

		this.form.markAsPending();

		this.authService.reset({
			email: this.email,
			password: this.form.value.password,
			resetToken: this.token
		})
			.subscribe(
				jwtPayload => {
					this.jwtService.saveJWTData(jwtPayload);

					this.router.navigate(['/']);
				},
				(err: ErrorMessage) => this.handleValidationErrors(err, this.form, this.fieldRejections),
				() => this.form.markAsUntouched()
			);
	}
}

