import { Component, OnInit } from '@angular/core';
import { UserAccountService, UserSwitchAccountService } from '../../services/account.service';
import { UserAccount } from '../../model/account.model';
import { environment } from 'src/environments/environment';
import { JwtService } from '../../services/jwt.service';
import { DialogService } from 'src/app/ui/service/dialog.service';
import { AddAccountDialogComponent } from './add-account-dialog/add-account-dialog.component';
import { Router } from '@angular/router';
import { isEmptyObject } from '../../util/utils';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./_company-list.component.scss']
})
export class CompanyListComponent implements OnInit {

  constructor(
    private userAccountService: UserAccountService,
    private jwtService: JwtService,
    public dialog: DialogService,
    private userAccountSwitchService: UserSwitchAccountService,
    private router: Router
  ) { }
  endpoint = environment.endpoint + "/"
  accounts: UserAccount[] = [];
  useremail: string = '';
  activatedAccount = ''
  ngOnInit(): void {
    this.monitorJwtData()
    this.getAccounts()
  }

  getAccounts() {
    this.userAccountService.get('').subscribe({
      next: (data) => {
        this.accounts = data;

      }
    })
  }
  
  handleOpenDialog() {
    const dialogRef = this.dialog.open(
      AddAccountDialogComponent,
      { data: null },
      "modal-width-xl"
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.getAccounts()
      }

    });
  }
  handleSwitchAccount(accountId) {
    this.userAccountSwitchService.get(accountId).subscribe({
      next: (data: any) => {
        this.jwtService.saveJWTData(data.accountDetail);
        setTimeout(() => {
          this.jwtService.currentJwtPayload$.subscribe({
            next: (data: any) => {
              if (data.planDetail && !isEmptyObject(data.planDetail)) {
                this.router.navigate(['/dashboard']);
              } else {
                this.router.navigate(['/plans']);
              }
            },
          });
        }, 1000);
      },
    });
  }
  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.activatedAccount = data.account.id
          this.useremail = data.user.email
        }
      },
    });
  }
}

