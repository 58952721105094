<!-- =========================== -->
<!-- Featured Banner start here... -->
<!-- =========================== -->
<section class="page-banner about">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-11">
        <div class="banner-content text-center">
          <span class="badge">About</span>
          <h2 class="title">About fusion flow</h2>
          <p class="description">
            FusionFlow is a cloud-based integration platform designed for small
            to medium-sized enterprises (SMEs) to streamline and automate their
            business processes without the need for coding expertise. By
            connecting and integrating standalone modules like tasks, forms,
            emails, and data storage with an intuitive workflow system,
            FusionFlow allows users to create fully customizable web
            applications. Whether you need to manage daily tasks, track the
            status of ongoing projects, or automate business workflows,
            FusionFlow makes it easy to stay organised and efficient.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- =========================== -->
<!-- Featured Banner end here... -->
<!-- =========================== -->

<!-- ========================================= -->
<!-- Founder section start here... -->
<!-- ========================================= -->
<section class="company-founder">
  <div class="container-fluid">
    <!-- content-1 start here... -->
    <div class="row align-items-md-center">
      <div class="col-12 col-lg-6">
        <div class="company-img">
          <!-- <video width="320" height="240" autoplay muted>
                <source src="movie.mp4" type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
            </video> -->
          <img src="/assets/img/company-1.webp" class="w-100" alt="company-1" />
          <div class="card-img-overlay">
            <button class="btn" type="button">
              <span>
                <img
                  src="/assets/icons/pause-btn-icon.svg"
                  alt="pause-btn-icon"
                />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="company-content">
          <h5>
            “I highly recommend FusionFlow to any organization seeking a
            reliable and efficient integration solution. With their cutting-edge
            technology, exceptional support, and commitment to long-term
            partnerships, I am confident that FusionFlow will continue to be a
            valuable asset for businesses in the years to come.”
          </h5>
          <span>Founder & CEO - Steele parker</span>
        </div>
      </div>
    </div>
    <!-- content-1 end here... -->
  </div>
</section>
<!-- ========================================= -->
<!-- Founder & company section end here... -->
<!-- ========================================= -->

<!-- =========================================== -->
<!-- About-team from world section start here... -->
<!-- =========================================== -->
<section class="about-team">
  <div class="container-fluid">
    <div class="row justify-content-lg-between">
      <div class="col-12 col-lg-7">
        <div class="about-team-content">
          <h4 class="title">
            FusionFlow: Your Gateway to Streamlined Business Automation
          </h4>
          <p class="description">
            FusionFlow is your go-to solution for businesses ready to "tech-up"
            and embark on a journey of automating their vital business
            processes. Our platform harnesses the extensive knowledge and
            expertise gained through years of crafting bespoke business process
            automation projects since 2018. With FusionFlow, you gain access to
            a seamless integration platform, allowing you to assemble base
            components effortlessly, including forms, file management, data
            tables, tasks, and more. Elevate your business operations, boost
            efficiency, and stay ahead of the competition with FusionFlow.
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="team-countdown">
          <div class="countdown-content">
            <h4 class="title countdown">2009</h4>
            <span class="description"> FOUNDED </span>
          </div>
          <div class="countdown-content">
            <h4 class="title">
              <span class="countdown">100</span>
              <span>%</span>
            </h4>
            <span class="description"> REMOTE </span>
          </div>
          <div class="countdown-content">
            <h4 class="title countdown">24</h4>
            <span class="description"> TEAMS </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ========================================= -->
<!-- About-team from world section end here... -->
<!-- ========================================= -->

<!-- ========================================= -->
<!-- Company section start here... -->
<!-- ========================================= -->
<section class="company-founder">
  <div class="container-fluid">
    <!-- content-2 start here... -->
    <div class="row align-items-md-start content-2">
      <div class="col-12 col-lg-6">
        <div class="company-content">
          <h4 class="title">Our Vision</h4>
          <p class="description">
            To revolutionize process automation by making it cost-effective and
            accessible for small to medium-sized enterprises (SMEs), thereby
            enabling them to optimize their operations, reduce repetitive tasks,
            and focus more on engaging in high-value activities and soft skills
            that computers cannot perform.
          </p>
          <h4 class="title">Our Mission</h4>
          <p class="description">
            FusionFlow strives to empower SMEs through a no-code, scalable, and
            secure cloud-based platform that simplifies task management and team
            collaboration. Our mission is to bridge the automation gap between
            large corporations and SMEs by providing state-of-the-art tools that
            automate workflows and integrate seamlessly with existing systems,
            thus enhancing productivity and operational efficiency without the
            need for extensive technical knowledge.
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="company-img h-100">
          <img src="/assets/img/company-2.webp" class="w-100" alt="company-2" />
        </div>
      </div>
    </div>
    <!-- content-2 end here... -->
  </div>
</section>
<!-- ========================================= -->
<!-- Company section end here... -->
<!-- ========================================= -->

<!-- ========================================= -->
<!-- why Choose Us section start here... -->
<!-- ========================================= -->
<section class="company-culture py-5">
  <div class="container">
   <img src="../../../../assets/img/why-choose-us.png" alt="why-choose-us" class="w-100 h-100 mx-auto">
  </div>
</section>

<!-- ========================================= -->
<!-- why Choose Us section end here... -->
<!-- ========================================= -->

<!-- ========================================= -->
<!-- our-company culture section start here... -->
<!-- ========================================= -->
<section class="company-culture">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-11">
        <h4 class="title">
          Our unwavering commitment to success is grounded in ethical conduct.
          We prioritize integrity, honesty, and transparency, ensuring our
          decisions consistently reflect our deeply ingrained values and
          principles.
        </h4>
      </div>
    </div>
    <div class="row justify-content-lg-between align-items-center">
      <div class="col-12 col-lg-5">
        <div>
          <span class="badge bg-light-violet"> Help customers succeed </span>
          <p class="description">
            Our unwavering commitment to excellence permeates every facet of our
            operations, from product development to customer service. This
            dedication to delivering exceptional quality consistently is the
            bedrock upon which we build trust and customer loyalty.
          </p>
        </div>
        <div>
          <span class="badge bg-light-yellow"> Bring good vibes </span>
          <p class="description">
            Our commitment to positivity radiates through every aspect of our
            culture and product. We foster an atmosphere of optimism,
            collaboration, and enthusiasm, creating an uplifting experience for
            our team and customers alike.
          </p>
        </div>
        <div>
          <span class="badge bg-light-creame">
            Take action, deliver results
          </span>
          <p class="description">
            We uphold the highest quality standards in every endeavor, spanning
            product development to customer service. Our unwavering commitment
            to excellence fosters trust and lasting business relationships.
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="culture-box"></div>
      </div>
    </div>
  </div>
</section>
