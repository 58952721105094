// form-instance

import {
  AuthApi, registerInput, resetInput, authenticateInput, resetContent,
  registerContent, authenticateContent, forgotInput, forgotContent
} from '../api/auth.api';
import { Injectable } from "@angular/core";
import { JwtService } from './jwt.service';
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { IJWTPayload } from '../model/auth.model';
import { publicApi } from '../api/public.api';

@Injectable()
export class PublicService {
  constructor(
    private publicApi: publicApi,
    public notifications: NotificationsService,
  ) { }



  public formInstanceCreate = (opts): Observable<any> => {
    return apiCallWrapper(
      this.publicApi.formInstanceCreate(opts),
      {
        notificationsService: this.notifications,
        action: "Registering",
        message: "Creating your account",
        successMessage: "An email has been sent to your inbox"
      }
    );
  }


  public formInstanceUpdate = (opts, id): Observable<any> => {
    return apiCallWrapper(
      this.publicApi.formInstanceUpdate(opts, id),
      {
        notificationsService: this.notifications,
        action: "Registering",
        message: "Creating your account",
        successMessage: "An email has been sent to your inbox"
      }
    );
  }
  public formInstanceGet = (id): Observable<any> => {
    return apiCallWrapper(
      this.publicApi.formInstanceGet(id),
      {
        notificationsService: this.notifications,
        action: "Registering",
        message: "Creating your account",
        successMessage: "An email has been sent to your inbox"
      }
    );
  }

  public submitPublicFormParticipent = (opts): Observable<any> => {
    return apiCallWrapper(
      this.publicApi.submitPublicFormParticipent(opts),
      {
        notificationsService: this.notifications,
        action: "Registering",
        message: "Creating your account",
        successMessage: "An email has been sent to your inbox"
      }
    );
  }

  public getPlans = (opts): Observable<any> => {
    return apiCallWrapper(
      this.publicApi.getPlans(opts),
      {
        notificationsService: this.notifications,
        action: "Registering",
        message: "Creating your account",
        successMessage: "An email has been sent to your inbox"
      }
    );
  }
}