import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { EmailTemplateApi } from '../api/email-template.api';
import { GenericService } from '../../main/services/generic.service';
import { IEmailTemplate } from '../types/email-template';
import { GenericApi } from 'src/app/main/api/generic.api';

@Injectable()
export class EmailTemplateService extends GenericService<IEmailTemplate> {
	constructor(
		public emailTemplateApi: EmailTemplateApi,
		public notifications: NotificationsService
	) {
		super(emailTemplateApi as GenericApi<any>, notifications);
	}
}