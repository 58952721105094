import { GenericApi } from "./generic.api"
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { createUrl } from "./api.util";

@Injectable()
export class AccountPlanApi extends GenericApi {
  public path = "plan";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class AccountPlanBuyApi extends GenericApi {
  public path = "plan/buy";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class CreateCheckoutApi extends GenericApi {
  public path = "plan/create-checkout";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}


@Injectable()
export class UserAccountsApi extends GenericApi {
  public path = "auth/account";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}
// http://localhost:3000/auth/switch-account/:accountId

@Injectable()
export class UserSwitchAccountsApi extends GenericApi {
  public path = "auth/switch-account";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class UserTaskApi extends GenericApi {
  public path = "task";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  readonly addComment = (opts: { taskId: string, comment: string }): Observable<{ success: true }> => {
    return this.httpClient.post<{ success: true }>(
      createUrl(this.path, 'comment'),
      opts
    );
  };

  readonly taskComment = (taskId: string): Observable<any> => {
    const filter = { taskId: taskId };
    return this.httpClient.get<any>(
      createUrl(this.path, 'comment'),
      { params: { filter: JSON.stringify(filter) } }
    );
  };

  readonly editComment = (opts: { taskId: string, comment: string }, commentId: string): Observable<{ success: true }> => {
    return this.httpClient.put<{ success: true }>(
      createUrl(this.path, 'comment', commentId),
      opts
    );
  };

  readonly deleteComment = (commentId: string): Observable<{ success: true }> => {
    return this.httpClient.delete<{ success: true }>(
      createUrl(this.path, 'comment', commentId)
    );
  };
}


@Injectable()
export class MyTaskApi extends GenericApi {
  public path = "task";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class ChangeTaskStatusApi extends GenericApi {
  public path = "task/status";
  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class TaskBulkUpdateApi extends GenericApi {
  public path = "task/update";
  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class TaskBulkDeleteApi extends GenericApi {
  public path = "task/delete";
  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class PublicFormApi extends GenericApi {
  public path = "public-form";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class PublicFormInstanceApi extends GenericApi {
  public path = "form-instance/public";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class PublicFormInstanceApiV2 extends GenericApi {
  public path = "form-instance/public-form";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}


@Injectable()
export class GetWorkflowInstanceApi extends GenericApi {
  public path = "workflow-instance/get-instance";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class UserDashboardApi extends GenericApi {
  public path = "task/dashboard";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}

@Injectable()
export class TaskActivityAPI extends GenericApi {
  public path = "task/activity";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }
}