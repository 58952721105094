import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { InputFieldControl } from "./input-field.control";
import { InputDialogComponent } from "../core/shared-dialog/input-dialog/input-dialog.component";
import { DialogService } from "src/app/ui/service/dialog.service";
import { ControlContainer, FormGroup } from "@angular/forms";

@Component({
  selector: "app-fbc-input",
  templateUrl: "./fbc-input.component.html",
  styleUrls: ["./fbc-input.component.sass"],
})
export class FbcInputComponent {
  constructor(public dialog: DialogService) { }

  @Input() control: InputFieldControl | null = null;
  @Input() elementIndex!: number;
  @Input() columnIndex!: number;
  @Input() rowIndex!: number;
  @Input() isColumn: boolean;
  @Input() isPreview: boolean;
  @Input() form: FormGroup;
  @Output() textFieldDeleteEvent = new EventEmitter<{
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();
  @Output() duplicateControl = new EventEmitter<InputFieldControl>();
  @Output() textAreaUpdateEvent = new EventEmitter<{
    control: InputFieldControl;
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();
  formGroup: FormGroup | null = null;

  editInputFieldDialog(
    control: InputFieldControl,
    controlIndex: number,
    columnIndex: number,
    rowIndex: number
  ): void {
    const dialogRef = this.dialog.open(
      InputDialogComponent,
      { data: control },
      "modal-width-xl"
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.textAreaUpdateEvent.emit({
          control: result,
          elementIndex: controlIndex,
          columnIndex: columnIndex,
          rowIndex: rowIndex,
        });
      }
    });
  }

  deleteTextField(
    control: InputFieldControl,
    index: number,
    columnIndex: number,
    rowIndex: number
  ) {
    this.textFieldDeleteEvent.emit({
      elementIndex: index,
      columnIndex: columnIndex,
      rowIndex: rowIndex,
    });
  }

  duplicateTextFieldControl(control: InputFieldControl): void {
    this.duplicateControl.emit(control);
  }
}
