import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Constants } from "../../constents";
import { DialogRef } from "src/app/ui/service/dialog-ref";
import { DIALOG_DATA } from "src/app/ui/service/dialog-tokens";

@Component({
  selector: "app-heading-dialog",
  templateUrl: "./heading-dialog.component.html",
  styles: [``],
})
export class HeadingDialogComponent implements OnInit {
  headingFieldsForm!: FormGroup;
  constructor(
    public formBuilder: FormBuilder,
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  constants: any = Constants;

  ngOnInit(): void {
    this.validateFormBuilder();
    this.editProperty(this.data);
  }

  validateFormBuilder() {
    this.headingFieldsForm = this.formBuilder.group({
      label: ["", Validators.required],
      name: ["", Validators.required],
    });
  }

  editProperty(control: any) {
    this.headingFieldsForm.setValue({
      label: control.label,
      name: control.name,
    });
  }

  onSubmit(): void {
    if (this.headingFieldsForm.invalid) {
      return;
    }
    this.headingFieldsForm.value.type = this.data.type;
    this.dialogRef.close(this.headingFieldsForm.value);
  }

  close() {
    this.dialogRef.close();
  }
}
