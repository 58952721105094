import { Component, EventEmitter, Input, Output } from "@angular/core";
import { InputDialogComponent } from "../core/shared-dialog/input-dialog/input-dialog.component";
import { CheckboxFieldControl } from "./checkbox.control";
import { DialogService } from "src/app/ui/service/dialog.service";
import { FormArray, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-fbc-checkbox",
  templateUrl: "./fbc-checkbox.component.html",
  styleUrls: ["./fbc-checkbox.component.sass"],
})
export class FbcCheckboxComponent {
  constructor(public dialog: DialogService) {}
  @Input() control: CheckboxFieldControl | null = null;
  @Input() form: FormGroup;
  @Input() elementIndex!: number;
  @Input() columnIndex!: number;
  @Input() rowIndex!: number;
  @Input() isColumn: boolean;
  @Input() isPreview: boolean;
  @Output() CheckboxFieldDeleteEvent = new EventEmitter<{
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();
  @Output() duplicateControl = new EventEmitter<CheckboxFieldControl>();
  @Output() textAreaUpdateEvent = new EventEmitter<{
    control: CheckboxFieldControl;
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();

  deleteTextField(
    control: CheckboxFieldControl,
    index: number,
    columnIndex: number,
    rowIndex: number
  ) {
    this.CheckboxFieldDeleteEvent.emit({
      elementIndex: index,
      columnIndex: columnIndex,
      rowIndex: rowIndex,
    });
  }
  editInputFieldDialog(
    control: CheckboxFieldControl,
    controlIndex: number,
    columnIndex: number,
    rowIndex: number
  ): void {
    const dialogRef = this.dialog.open(
      InputDialogComponent,
      { data: control },
      "modal-width-xl"
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.textAreaUpdateEvent.emit({
          control: result,
          elementIndex: controlIndex,
          columnIndex: columnIndex,
          rowIndex: rowIndex,
        });
      }
    });
  }

  duplicateCheckboxFieldControl(control: CheckboxFieldControl): void {
    this.duplicateControl.emit(control);
  }
  checkArray: any = [];
  updateHandleChange(event) {
    const formArray: FormArray = this.form.get(
      this.control?.name || ""
    ) as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
    // selectedArray.value = checkArray
  }
}
