import { environment } from "../../../environments/environment";
import { IQueryFilter } from "../model/query.filter.class";
import { HttpParams, HttpHeaders } from "@angular/common/http";
import { NotificationsService } from "angular2-notifications";
import { NEVER, Observable, of, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ErrorMessage } from "../model/api.model";
import { HeaderTokenEnum } from "../model/auth.model";
import { hasKey } from "../util/object.util";
import { logger } from "../util/Logger";

const className = "apiCallWrapper";

export const apiHost = environment.endpoint;
export const apiPrefix = "";

export const createAppUrl =
  (host: string, prefix: string) =>
    (...params: Array<string | number>): string =>
      `${host}${prefix.length ? prefix + "/" : ""}/${params.join("/")}`;

export const createUrl = createAppUrl(apiHost, apiPrefix);

export const httpParams = () => new HttpParams();

export const queryToParams = (query: IQueryFilter) =>
  (Object as any)
    .entries(query)
    .reduce(
      (params, [key, value]) =>
        typeof value === "function"
          ? params
          : params.set(
            key,
            typeof value === "object" ? JSON.stringify(value) : value
          ),
      httpParams()
    );

export const getPublicRoutesHeaders = () => {
  const headers = new HttpHeaders();

  return headers.append("tokenType", HeaderTokenEnum.NoToken);
};


export const getPublicMultipartRoutesHeaders = () => {
  const headers = new HttpHeaders();
  return headers.append("tokenType", HeaderTokenEnum.NoToken).set('Content-Type', 'multipart/form-data');

}

export const apiCallWrapper = <D>(
  observable: Observable<any>,
  opts: {
    notificationsService: NotificationsService;
    action: string;
    title?: string;
    message?: string;
    successTitle?: string;
    failTitle?: string;
    successMessage?: string;
    defaultValue?: D;
  }
): typeof observable => {
  const signature = className + ".apiCallWrapper: ";
  const options = Object.assign({}, opts, {
    title: opts.action,
    successTitle: `${opts.action} complete`,
    failTitle: `${opts.action} failed`,
    message: "",
    successMessage: opts.message ? `${opts.message} completed` : "",
  });
  const notifcation = options.notificationsService.warn(
    options.title,
    options.message
  );
  const removeExistingNotification = () =>
    options.notificationsService.remove(notifcation.id);

  // Sent to true when there was a gracefully handled error and the default value was returned.
  let didNotSucceed = false;

  return observable.pipe(
    catchError((err) => {
      removeExistingNotification();

      /**
       * Despite the documentation, it is observed at this moment that the following returns false
       * when true was expected
       * has(err, ['error.message','error.code'])
       *
       * For this reason, _.has will be used twice, as this gives the expected outcome
       */

      // Prevent duplicate handling of the error
      if (err instanceof ErrorMessage) {
        if (err.handled) {
          removeExistingNotification();
          return NEVER;
        }

        return throwError(err);
      }

      if (
        hasKey(err, "error") &&
        hasKey(err.error, "message") &&
        hasKey(err.error, "statusCode")
      ) {
        const error = new ErrorMessage().deserialize(
          err.error as Partial<ErrorMessage>
        );

        options.notificationsService.error(options.failTitle, error.message, {
          timeOut: 3000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true,
        });

        if (error.statusCode === 404 && opts.defaultValue) {
          logger.warn(signature + `Gracefully 404 Handled Error`);
          didNotSucceed = true;
          return of(options.defaultValue);
        }

        return throwError(error);
      } else {
        options.notificationsService.error(
          options.failTitle,
          "Unknown Error has Occurred",
          {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
          }
        );
      }

      return throwError(new ErrorMessage());
    }),
    map((result) => {
      removeExistingNotification();

      if (!didNotSucceed) {
        options.notificationsService.success(
          options.successTitle,
          options.successMessage,
          {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
          }
        );
      }

      return result.data || result;
    })
  );
};
