import { Component, HostListener, inject, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { handleValidationErrors, canSubmit, displayValidationErrors } from './formUtils';
import { Router, Scroll } from "@angular/router";

@Component({
	template: ''
})
export class CommonController {

  private _router = inject(Router);

  constructor() {}

  public enableAnchorScrolling() {
    this._router.events.subscribe(event => {
      if (event instanceof Scroll && event.anchor) {
        setTimeout(() => {
          this.scroll('#' + event.anchor);
        }, 100);
      }
    });
  }

  private scroll(query: string) {
    const targetElement = document.querySelector(query);
    if (!targetElement) {
      console.log("scrolling up");
      window.scrollTo(0, 0);
    } else if (!this.isInViewport(targetElement)) {
      targetElement.scrollIntoView();
    }
  }

  private isInViewport = (elem: any) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

	keypress: BehaviorSubject<string | null> = new BehaviorSubject(null);

	@HostListener('document:keypress', ['$event'])
  private handleKeyboardEvent(event: KeyboardEvent) {
		this.keypress.next(event.key);
  }

	handleValidationErrors = handleValidationErrors;
	displayValidationErrors = displayValidationErrors;
	canSubmit = canSubmit;

	scrollTop() {
		window.scrollTo(0, 0);
	}

	scrollTo(tgt: string) {
		let targetElem = document.querySelector('#' + tgt);

		if (!targetElem) return;

		targetElem.scrollIntoView();
		window.scrollBy(0, -50);
	}
}
