import { Component, EventEmitter, Input, Output } from "@angular/core";
import { InputDialogComponent } from "../core/shared-dialog/input-dialog/input-dialog.component";
import { SelectFieldControl } from "./select-field.control";
import { DialogService } from "src/app/ui/service/dialog.service";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-fbc-select",
  templateUrl: "./fbc-select.component.html",
  styleUrls: ["./fbc-select.component.sass"],
})
export class FbcSelectComponent {
  constructor(public dialog: DialogService) {}
  @Input() control: SelectFieldControl | null = null;
  @Input() form:FormGroup;
  @Input() elementIndex!: number;
  @Input() columnIndex!: number;
  @Input() rowIndex!: number;
  @Input() isColumn: boolean;
  @Input() isPreview: boolean;
  @Output() SelectFieldDeleteEvent = new EventEmitter<{
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();
  @Output() duplicateControl = new EventEmitter<SelectFieldControl>();
  @Output() textAreaUpdateEvent = new EventEmitter<{
    control: SelectFieldControl;
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();

  deleteTextField(
    control: SelectFieldControl,
    index: number,
    columnIndex: number,
    rowIndex: number
  ) {
    this.SelectFieldDeleteEvent.emit({
      elementIndex: index,
      columnIndex: columnIndex,
      rowIndex: rowIndex,
    });
  }

  editInputFieldDialog(
    control: SelectFieldControl,
    controlIndex: number,
    columnIndex: number,
    rowIndex: number
  ): void {
    const dialogRef = this.dialog.open(
      InputDialogComponent,
      { data: control },
      "modal-width-xl"
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.textAreaUpdateEvent.emit({
          control: result,
          elementIndex: controlIndex,
          columnIndex: columnIndex,
          rowIndex: rowIndex,
        });
      }
    });
  }

  duplicateSelectFieldControl(control: SelectFieldControl): void {
    this.duplicateControl.emit(control);
  }
}
