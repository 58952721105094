import { Component, EventEmitter, Input, Output } from "@angular/core";
import { InputDialogComponent } from "../core/shared-dialog/input-dialog/input-dialog.component";
import { TextAreaFieldControl } from "./textarea.control";
import { DialogService } from "src/app/ui/service/dialog.service";


@Component({
  selector: "app-fbc-textarea",
  templateUrl: "./fbc-textarea.component.html",
  styleUrls: ["./fbc-textarea.component.sass"],
})
export class FbcTextareaComponent {
  constructor(public dialog: DialogService) {}
  @Input() control: TextAreaFieldControl | null = null;
  @Input() form: any | null = null;
  @Input() elementIndex!: number;
  @Input() columnIndex!: number;
  @Input() rowIndex!: number;
  @Input() isColumn: boolean;
  @Input() isPreview: boolean;

  @Output() textAreaFieldDeleteEvent = new EventEmitter<{
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();
  @Output() duplicateControl = new EventEmitter<TextAreaFieldControl>();
  @Output() textAreaUpdateEvent = new EventEmitter<{
    control: TextAreaFieldControl;
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();

  deleteTextField(
    control: TextAreaFieldControl,
    index: number,
    columnIndex: number,
    rowIndex: number
  ) {
    this.textAreaFieldDeleteEvent.emit({
      elementIndex: index,
      columnIndex: columnIndex,
      rowIndex: rowIndex,
    });
  }

  duplicateTextAreaFieldControl(control: TextAreaFieldControl): void {
    this.duplicateControl.emit(control);
  }
  editInputFieldDialog(
    control: TextAreaFieldControl,
    controlIndex: number,
    columnIndex: number,
    rowIndex: number
  ): void {
    const dialogRef = this.dialog.open(
      InputDialogComponent,
      { data: control },
      "modal-width-xl"
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.textAreaUpdateEvent.emit({
          control: result,
          elementIndex: controlIndex,
          columnIndex: columnIndex,
          rowIndex: rowIndex,
        });
      }
    });
  }
}
