import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionApi } from '../api/session.api';
const permissions = {
  form: "Form",
  file: "File",
  table: "Data Table",
  users: "User & group",
  email: "Email Templates",
  workflow: "Workflow",
}
// const permissionTypes = ["All", "View", "Create", "Edit", "Delete", "Approve"];
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private sessionService: SessionApi) { }

  canFormTemplateDelete(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['form'] && permission.types.includes('Delete')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canFormInstanceDelete(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['form'] && permission.types.includes('Delete')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canCreateTask(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['form'] && permission.types.includes('All')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canCreateFileFolder(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['file'] && permission.types.includes('Create')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canEditFileFolder(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['file'] && permission.types.includes('Edit')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canDeleteFileFolder(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['file'] && permission.types.includes('Delete')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canAddDataTable(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['table'] && permission.types.includes('Create')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canEditDataTable(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['table'] && permission.types.includes('Edit')
              )
            );
          }
        }
        return false;
      })
    );
  }


  canViewDataTable(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['table'] && permission.types.includes('Delete')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canDeleteDataTable(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['table'] && permission.types.includes('Delete')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canCreateUsersAccess(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['users'] && permission.types.includes('Create')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canEditUsersAccess(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['users'] && permission.types.includes('Edit')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canDeleteUsersAccess(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['users'] && permission.types.includes('Delete')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canDeleteEmailTemplate(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['email'] && permission.types.includes('Delete')
              )
            );
          }
        }
        return false;
      })
    );
  }

  canDeleteWorkflow(): Observable<boolean> {
    return this.sessionService.$userData.pipe(
      map(data => {
        if (data) {
          if (data.userAccounts.user.isAdmin) {
            return true
          } else {
            return data.userGroups.some(group =>
              group.group.groupPermission.some(permission =>
                permission.permission.name === permissions['workflow'] && permission.types.includes('Delete')
              )
            );
          }
        }
        return false;
      })
    );
  }
}
