import { slideInAnimation } from "../../util/animations";
import { AuthService } from "../../services/auth.service";
import { CommonController } from '../../util/common.controller';
import { Component, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ErrorMessage, FieldRejection } from '../../model/api.model';
import { matchValues, serverSide } from '../../util/utils';
import { FileUploadService } from "../../services/fileUpload.service";

@Component({
  selector: "register",
	styleUrls: ['_register.component.scss'],
  templateUrl: "register.component.html",
  styles: [`
  .preview-image {
  width: 80px;
  height: 80px; 
}

.rounded-circle {
  border-radius: 50%;
}
  `],
  animations: [slideInAnimation]
})
export class RegisterComponent extends CommonController implements OnInit {

  form: UntypedFormGroup;
  fieldRejections: FieldRejection[] = [];
  companyImage: File | null = null;
  companyLogoUrl: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private fileUploadService: FileUploadService
  ) {
    super();

    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email], serverSide(this.fieldRejections, 'email')],
      accountName: [null,
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i),
        ])
      ],
      companyName: [null, Validators.required, serverSide(this.fieldRejections, 'companyName')],
      password: [
        null,
        Validators.compose([
          Validators.minLength(8),
          Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/)
        ])
      ],
    });
  }

  ngOnInit(): void { }

  fileName: string = '';
  filePreviewUrl: string | ArrayBuffer | null = null;

  async onFileChange(event: any) {
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file && file.type.startsWith('image/')) {
      this.companyImage = file;

      // Set file name dynamically
      this.fileName = file.name;

      // Read file and create preview URL
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.filePreviewUrl = e.target.result;
      };
      reader.readAsDataURL(file);

      try {
        const url = await this.uploadImage(file);
        this.companyLogoUrl = url;
      } catch (error) {
        console.error('Image upload failed:', error);
        this.companyImage = null;
      }
    } else {
      fileInput.value = '';
      alert('Please select a valid image file.');
    }
  }

  private uploadImage(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      this.fileUploadService.uploadCustomerImage(file, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static-dev-fusionflow-com-au/';
          const cfDistribution = 'https://static-dev-fusionflow-com-au/';
          const url = data.Location.replace(s3Prefix, cfDistribution);
          resolve(url);
        }
      });
    });
  }

  deleteFile() {
    this.fileName = '';
    this.filePreviewUrl = null;
    this.companyImage = null;
  }

  register(event: Event) {
    event.preventDefault();
    if (!this.canSubmit(this.form) || !this.companyLogoUrl) return;

    this.form.markAsPending();
    const payload = {
      email: this.form.get('email')?.value,
      accountName: this.form.get('accountName')?.value,
      companyName: this.form.get('companyName')?.value,
      password: this.form.get('password')?.value,
      companyLogo: this.companyLogoUrl
    };

    this.authService.register(payload)
      .subscribe(
        () => this.router.navigate(['/']),
        (err: ErrorMessage) => this.handleValidationErrors(err, this.form, this.fieldRejections),
        () => this.form.markAsUntouched()
      );
  }

  passwordVisible: boolean = false;
  confirmPasswordVisible: boolean = false;

  togglePasswordVisibility(field: string): void {
    if (field === 'password') {
      this.passwordVisible = !this.passwordVisible;
    } else if (field === 'confirmPassword') {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }
  }

}
