import { Component, EventEmitter, Input, Output } from "@angular/core";
import { InputDialogComponent } from "../core/shared-dialog/input-dialog/input-dialog.component";
import { NumberFieldControl } from "./number-field.control";
import { DialogService } from "src/app/ui/service/dialog.service";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-fbc-number",
  templateUrl: "./fbc-number.component.html",
  styleUrls: ["./fbc-number.component.sass"],
})
export class FbcNumberComponent {
  constructor(public dialog: DialogService) {}
  @Input() control: NumberFieldControl | null = null;
  @Input() form:FormGroup;
  @Input() elementIndex!: number;
  @Input() columnIndex!: number;
  @Input() rowIndex!: number;
  @Input() isColumn: boolean;
  @Input() isPreview: boolean;
  @Output() inputFieldDeleteEvent = new EventEmitter<{
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();
  @Output() duplicateControl = new EventEmitter<NumberFieldControl>();
  @Output() textAreaUpdateEvent = new EventEmitter<{
    control: NumberFieldControl;
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();

  deleteTextField(
    control: NumberFieldControl,
    index: number,
    columnIndex: number,
    rowIndex: number
  ) {
    this.inputFieldDeleteEvent.emit({
      elementIndex: index,
      columnIndex: columnIndex,
      rowIndex: rowIndex,
    });
  }

  duplicateNumberFieldControl(control: NumberFieldControl): void {
    this.duplicateControl.emit(control);
  }

  editInputFieldDialog(
    control: NumberFieldControl,
    controlIndex: number,
    columnIndex: number,
    rowIndex: number
  ): void {
    const dialogRef = this.dialog.open(
      InputDialogComponent,
      { data: control },
      "modal-width-xl"
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.textAreaUpdateEvent.emit({
          control: result,
          elementIndex: controlIndex,
          columnIndex: columnIndex,
          rowIndex: rowIndex,
        });
      }
    });
  }
}
