import { Overlay, ComponentType } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable, Injector } from "@angular/core";
import { OffcanvasRef } from "./offcanvas-ref"
import { OFFCANVAS_DATA } from "./offcanvas-token";

export interface OffcanvasConfig {
  data?: any;
  position?: 'start' | 'end';  // You can add more positions if needed
  widthClass?: string;
}

@Injectable({
  providedIn: "root",
})
export class OffcanvasService {
  constructor(private overlay: Overlay, private injector: Injector) { }

  /**
   * Open a custom component in an offcanvas overlay
   */
  open<T>(
    component: ComponentType<T>,
    config?: OffcanvasConfig
  ): OffcanvasRef {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerVertically()
      .left(config?.position === 'start' ? '0' : '')
      .right(config?.position === 'end' ? '0' : '');

    // Create the overlay with customizable options
    // offcanvas task-detail offcanvas-end
    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: "overlay-backdrop",
      panelClass: ['show', "offcanvas", config?.widthClass || "", "offcanvas-end"],
    });

    // Create offcanvasRef to return
    const offcanvasRef = new OffcanvasRef(overlayRef);

    // Create injector to reference the OffcanvasRef from within the component
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: OffcanvasRef, useValue: offcanvasRef },
        { provide: OFFCANVAS_DATA, useValue: config?.data },
      ],
    });

    // Attach component portal to the overlay
    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);

    return offcanvasRef;
  }
}
