import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogRef } from "src/app/ui/service/dialog-ref";
import { DIALOG_DATA } from "src/app/ui/service/dialog-tokens";
import { IPermission, IPermissionType } from "src/app/user/types/user";
type permissionValue = { permissionId: string; types: string[] };
const AllTypes = ["All", "View", "Create", "Edit", "Delete", "Approve"];
@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./_create-user.component.scss"],
  providers: [DatePipe]
})
export class CreateUserComponent implements OnInit {

  permissionData: IPermission[];
  permissionTypeData: IPermissionType;
  permissions: permissionValue[] = [];
  groupName: string;
  groups: any;
  userPermissionForm!: FormGroup;
  maxDate: string;
  isGroup: boolean;
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public datePipe: DatePipe
  ) {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    this.maxDate = `${year}-${month}-${day}`;
  }
  ngOnInit(): void {

    this.permissionData = this.data.permission;
    this.permissionTypeData = this.data.permissionType;
    this.groups = this.data.groups;
    this.validateFormBuilder();
  }
  validateFormBuilder() {
    this.userPermissionForm = this.formBuilder.group({
      name: [this.data?.editData?.name || "", Validators.required],
      email: [this.data?.editData?.email || "", [Validators.required, Validators.email]],
      joiningDate: [this.transformDate(this.data?.editData?.joiningDate) || "", Validators.required],
      groupId: [this.data?.editData?.groupDetail[0]?.groupId || "", Validators.required],
    });
    if (this.data?.editData?.groupDetail[0]?.groupId) {
      let index = this.groups.findIndex(
        (item) => item.groupId === this.data?.editData?.groupDetail[0]?.groupId
      );
      if (index > -1) {
        this.permissions = this.groups[index].permissions;
      }
    }
  }
  transformDate(date: string): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
  }

  handleChangeGrup(event) {
    let index = this.groups.findIndex(
      (item) => item.groupId === event.target.value
    );
    if (index > -1) {
      this.permissions = this.groups[index].permissions;
    }
  }

  handleCheckValueExistes(name, value) {
    if (this.permissions.length > 0) {
      let index = this.permissions.findIndex(
        (item) => item.permissionId === name
      );
      if (index > -1) {
        if (this.permissions[index].types.includes(value)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
  handleChangePermission(event) {
    const { name, value, checked } = event.target;
    const index = this.permissions.findIndex(
      (item) => item.permissionId === name
    );

    if (checked) {
      let typesToAdd = [value];

      if (value === "All") {
        typesToAdd = [];
        typesToAdd = AllTypes.filter((type) => type);
      }

      if (index === -1) {
        this.permissions.push({
          permissionId: name,
          types: typesToAdd,
        });
      } else {
        const types = this.permissions[index].types;

        if (value === "All") {
          types.splice(0, types.length, ...typesToAdd);
        } else {
          types.push(value);
        }
      }
    } else if (index !== -1) {
      const types = this.permissions[index].types;

      if (value === "All") {
        types.length = 0;
      } else {
        const AllIndex = types.indexOf("All");
        if (AllIndex !== -1) {
          types.splice(AllIndex, 1);
        }
        const valueIndex = types.indexOf(value);
        if (valueIndex !== -1) {
          types.splice(valueIndex, 1);
        }
      }
    }
  }

  onSubmit() {
    this.markFormGroupAsTouched(this.userPermissionForm);
    if (this.userPermissionForm.valid) {
      this.userPermissionForm.value.groups = [
        { groupId: this.userPermissionForm.value.groupId },
      ];
      this.dialogRef.close(this.userPermissionForm.value);
    } else {
      return;
    }
  }

  close() {
    this.dialogRef.close();
  }
  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      }
    });
  }

  setTab() {
    this.isGroup = true;
  }
}
