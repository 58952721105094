import { GenericApi } from '../../main/api/generic.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class WorkflowInstanceApi extends GenericApi {
    public path = "workflow-instance";

    constructor(
        public httpClient: HttpClient
    ) {
        super(httpClient);
    }
}