<div class="main-contianer">
  <app-header [showTransparentHeader]="prepareRoute(outlet, 'transparentHeader')"></app-header>
  <div [@routeAnimations]="prepareRoute(outlet, 'animation')">
    <router-outlet #outlet="outlet"></router-outlet>
    <app-footer *ngIf="isShow()"></app-footer>
  </div>

  <script src="assets/js/ipass.js"></script>
  <script src="assets/js/bootstrap.bundle.js"></script>

  <!--- [options]="options" --->
  <simple-notifications></simple-notifications>

</div>