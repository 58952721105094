<section class="login">
  <div class="container-fluid">
    <div class="login-content-flex mx-auto">
      <div class="pb-3">
        <h4 class="login-title">Welcome to fusionFlow</h4>
        <p class="login-desc mb-0">
          Enter your details to Sign in with your account
        </p>
      </div>
      <div class="card login-card">
        <div class="card-body">
          <form [formGroup]="form">
            <div class="form-group">
              <label for="LoginEmail" class="form-label">Email</label>
              <text-input
                field="email"
                placeholder="Enter your email"
                [form]="form"
                [errors]="{
                  required: 'Email Address Required',
                  email: 'Invalid Email Address',
                  serverValidation: 'Rejected by server'
                }"
              >
              </text-input>
            </div>
            <div class="form-group">
              <label for="LoginPassword" class="form-label">Password</label>
              <div class="position-relative">
                <text-input
                  field="password"
                  placeholder="Enter your password"
                  [form]="form"
                  [type]="passwordFieldType"
                  [errors]="{
                    required: 'Password required',
                    serverValidation: 'Rejected by server'
                  }"
                ></text-input>
                <span
                  class="icon pass-icon"
                  (click)="togglePasswordVisibility()"
                >
                  <svg
                    *ngIf="passwordFieldType === 'password'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                  >
                    <path
                      d="M1.5 8C1.5 8 4.95455 1 11 1C17.0455 1 20.5 8 20.5 8C20.5 8 17.0455 15 11 15C4.95455 15 1.5 8 1.5 8Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 11C12.574 11 13.85 9.65685 13.85 8C13.85 6.34315 12.574 5 11 5C9.42599 5 8.15 6.34315 8.15 8C8.15 9.65685 9.42599 11 11 11Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    *ngIf="passwordFieldType === 'text'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                  >
                    <path
                      d="M1.5 8C1.5 8 4.95455 1 11 1C17.0455 1 20.5 8 20.5 8C20.5 8 17.0455 15 11 15C4.95455 15 1.5 8 1.5 8Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 11C12.574 11 13.85 9.65685 13.85 8C13.85 6.34315 12.574 5 11 5C9.42599 5 8.15 6.34315 8.15 8C8.15 9.65685 9.42599 11 11 11Z"
                      stroke="#949CA9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <line
                      x1="1"
                      y1="1"
                      x2="21"
                      y2="15"
                      stroke="#949CA9"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <button
              type="button"
              [disabled]="!canSubmit(form)"
              (click)="authenticate()"
              class="btn btn-dark"
              role="button"
            >
              Login
            </button>

            <div class="text-center">
              <a class="btn btn-forget" role="button" routerLink="/forgot"
                >Forgot Password</a
              >
            </div>

            <div class="signUp-content">
              <div class="or-content">
                <p>OR</p>
              </div>
              <button
                id="google-signin-btn"
                (click)="googleSignIn()"
                class="btn btn-light google-btn"
                type="button"
              >
                <span>
                  <img
                    class="w-100"
                    src="/assets/icons/google-icon.svg"
                    alt="google-icon"
                  />
                </span>
                Sign up with Google
              </button>
              <button
                class="btn btn-light google-btn after-google-btn"
                type="button"
              >
                <p class="d-flex align-items-center gap-2 mb-0 text-start">
                  <span class="short"> MS </span>
                  <span class="w-auto h-auto d-block continue">
                    Continue as meenakshi
                    <span class="w-auto h-auto mail"
                      >meenakshi00711@gmail.com
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            d="M6.89999 8.2L10.5 11.8L14.1 8.2"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </span>
                  </span>
                </p>
                <span>
                  <img
                    class="w-100"
                    src="/assets/icons/google-icon.svg"
                    alt="google-icon"
                  />
                </span>
              </button>
              <p class="d-flex align-items-center justify-content-center">
                Don’t have an account?
                <a routerLink="/register" role="button" class="btn btn-link">
                  Sign up
                </a>
              </p>

              <p class="terms-text">
                By clicking the button above, you agree to our
                <a
                  href="javascript:void(0);"
                  role="button"
                  class="btn btn-link"
                >
                  Terms of Service
                </a>
                and
                <a
                  href="javascript:void(0);"
                  role="button"
                  class="btn btn-link"
                >
                  Privacy Policy </a
                >.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row justify-content-center d-none">
      <div class="col-12 col-lg-5">
        <div
          class="col-12 alert alert-danger"
          *ngIf="displayValidationErrors(fieldRejections)"
          [innerHTML]="displayValidationErrors(fieldRejections)"
        ></div>
      </div>
    </div>
  </div>
</section>
