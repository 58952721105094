import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericApi } from "./generic.api";


@Injectable()
export class ProjectApi extends GenericApi {

  public path = 'project';

  constructor(
    public readonly httpClient: HttpClient
  ) {
    super(httpClient);
  }

}


@Injectable()
export class ProjectStatusApi extends GenericApi {

  public path = 'project/status';

  constructor(
    public readonly httpClient: HttpClient
  ) {
    super(httpClient);
  }

}

@Injectable()
export class ProjectUsersApi extends GenericApi {

  public path = 'project/user';

  constructor(
    public readonly httpClient: HttpClient
  ) {
    super(httpClient);
  }

}