import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyTaskService } from 'src/app/main/services/account.service';
import { Projectservice } from 'src/app/main/services/project.service';
import { DialogRef } from 'src/app/ui/service/dialog-ref';
import { DIALOG_DATA } from 'src/app/ui/service/dialog-tokens';

@Component({
  selector: 'app-move-task-dialog',
  template: `
  <div
  class="modal fade config-add-modal show"
  id="addformModal"
  tabindex="-1"
  aria-labelledby="addformModalLabel"
  aria-hidden="true"
  style="display: block; padding-left: 0px"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-body">
    <div class="d-flex align-items-center justify-content-start gap-3">
    <button class="btn border-0 p-0" type="button" (click)="close()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="13"
        viewBox="0 0 19 13"
        fill="none"
      >
        <path
          d="M18 7H1"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M6.5 11.75L1.25 6.5L6.5 1.25"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </button>
    <h5 class="offcanvas-title" id="taskDetailLabel">Move Task</h5>
  </div>
        <form [formGroup]="taskForm" (ngSubmit)="onSubmit()">
          <!-- Project Select -->
          <div class="form-group mt-5">
            <label for="project">Project</label>
            <select
              id="project"
              class="form-control"
              formControlName="project"
              required
            >
              <option value="">Select a Project</option>
              <option *ngFor="let project of projects" [value]="project.id">{{ project.projectName }}</option>
            </select>
            <div *ngIf="taskForm.get('project')?.invalid && taskForm.get('project')?.touched" class="text-danger">
              Project is required.
            </div>
          </div>

          <!-- Task Select -->
          <div class="form-group" *ngIf="taskForm.get('project')?.value">
            <label for="task">Task</label>
            <select
              id="task"
              class="form-control"
              formControlName="task"
            >
              <option value="">Select a Task</option>
              <option *ngFor="let task of tasks" [value]="task.id">{{ task.taskName }}</option>
            </select>
          </div>

          <!-- Modal Buttons -->
          <div class="modal-btns">
            <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="taskForm.invalid">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
  `,
  styles: [``],
})
export class MoveTaskDialogComponent implements OnInit {
  taskForm: FormGroup;
  projects: any[] = [];
  tasks: any[] = []

  constructor(
    private dialogRef: DialogRef,
    private fb: FormBuilder,
    private projectService: Projectservice,
    private myTaskService: MyTaskService,
    @Inject(DIALOG_DATA) public data: any
  ) {
    this.taskForm = this.fb.group({
      project: ['', Validators.required],
      task: [''],
    });
  }
  ngOnInit(): void {
    this.projectService.getProjects().subscribe(projects => {
      this.projects = projects
    })

    this.taskForm.get('project')?.valueChanges.subscribe((projectId) => {
      if (projectId) {
        this.getTasksForProject(projectId);
      } else {
        this.tasks = [];
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.taskForm.valid) {
      // Handle form submission logic
      this.dialogRef.close(this.taskForm.value);
    }
  }

  getTasksForProject(projectId) {
    let query = {
      skip: 0,
      limit: 100,
      order: 'asc',
      filter: { projectId }
    };

    this.myTaskService.list(query).subscribe({
      next: (data) => {
        this.tasks = data.rows.filter(task => task.taskId == null && !this.data.selectedTasks.includes(task.id));
      }
    })
  }

}
