import { CommonController } from '../../util/common.controller';
import { Component, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AuthService } from '../../services/auth.service';
import { JwtService } from '../../services/jwt.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { md5 } from '../../util/string.util';

@Component({
  moduleId: module.id,
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['_header.component.scss']
})
export class HeaderComponent extends CommonController {

  @Input() showTransparentHeader: boolean = false;

  @HostListener('window:scroll', ['$event'])
  track = (event: Event): void => { this.pageYOffset = window.pageYOffset };

  pageYOffset: number = 0;
  showLoginButton = true
  // @TODO: https://sparkbusinesstechnology.atlassian.net/browse/SBM-46 - System Notifications
  showNotificationsButton = false;
  // @TODO: https://sparkbusinesstechnology.atlassian.net/browse/SBM-47 - Lock Screen
  showLockScreenButton = false;
  // @TODO: https://sparkbusinesstechnology.atlassian.net/browse/SBM-48 - User Settings
  showSettingsButton = false;
  // @TODO: https://sparkbusinesstechnology.atlassian.net/browse/SBM-49 - Account Edit
  showAccountButton = false;

  showConfigHeader = false;
  username: string = '';
  displayName: string = '';
  emailHash: string = '';
  currentURL: string = '';
  cardToshow = false

  icons = {
    faBell: faBell
  };

  constructor(
    public authService: AuthService,
    private jwtService: JwtService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

    super();
    this.monitorJwtData();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url
        if (event.url?.includes('dashboard')) {
          this.showConfigHeader = true;
        } else {
          this.showConfigHeader = false;
        }
      }
    });
  }

  getInitials() {
    const nameParts = this.username.trim().split(' ');

    if (nameParts.length === 1) {
      return nameParts[0].substring(0, 2).toUpperCase();
    } else {
      const firstNameInitial = nameParts[0][0].toUpperCase();
      const lastNameInitial = nameParts[nameParts.length - 1][0].toUpperCase();
      return firstNameInitial + lastNameInitial;
    }
  }
  showCard() {
    this.cardToshow = !this.cardToshow;
  }
  // ngOnChanges(): void {
  //   const currentRoutePath = this.activatedRoute.snapshot.routeConfig?.path;
  //   console.log("currentRoutePath===========>", this.router.routerState.snapshot.url)
  // }

  /**
    * Monitors JWT data.
    * @description Subscribes to `knownJWTData$` observable and sets user information in Sentry scope when new data is available.
    */
  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.username = data.account.name
          this.displayName = data.user.email;
          this.emailHash = md5(data.user.email);
        } else {
          this.displayName = '';
          this.emailHash = '';
        }
      },
    });
  }

  homeAndScrollTo(dest: string) {
    const targetUrl = '/';

    if (this.router.url !== targetUrl) {
      const watcher = this.router.events.subscribe(value => {
        if (value instanceof NavigationEnd) {
          setTimeout(() => {
            this.scrollTo(dest);
          }, 350);
          watcher.unsubscribe();
        }
      });

      this.router.navigate(["/"]);
    } else {
      this.scrollTo(dest);
    }
  }

  /**
   * Determines whether the header of a web page should be transparent based on the current vertical scroll position and a boolean flag.
   * @returns {boolean} A boolean value representing whether the header should be transparent (`true`) or not (`false`).
   */
  isTransparent = (): boolean => (this.showTransparentHeader && this.pageYOffset < 10);

  /**
   * Determines whether the current element is transparent, and returns a CSS class accordingly.
   *
   * @param {string} [transparentCssClass] The CSS class to return if the current element is transparent (optional).
   * @param {string} [opaqueCssClass] The CSS class to return if the current element is opaque (optional).
   * @returns {string} Returns the appropriate CSS class based on the transparency of the current element.
   */
  whenTransparent = (transparentCssClass?: string, opaqueCssClass?: string): string => this.isTransparent() ? (transparentCssClass || '') : (opaqueCssClass || '');

  /**
   * Removes the JWT data and navigates to the home page.
   *
   * @returns {void}
   */
  logout = () => {
    this.jwtService.removeJWTData();
    this.router.navigate(['/']);
  }
}
