import { Component, EventEmitter, Input, Output } from "@angular/core";
import {  HeadingDialogComponent } from "../core/shared-dialog/heading-control/heading-dialog.component";
import { HeadingControl } from './heading-control';
import { DialogService } from "src/app/ui/service/dialog.service";

@Component({
  selector: "app-fbc-heading",
  templateUrl: "./fbc-heading.component.html",
  styleUrls: ["./fbc-heading.component.sass"],
})
export class FbcHeadingComponent {
  constructor(public dialog: DialogService) {}
  @Input() control: HeadingControl | null = null;
  @Input() elementIndex!: number;
  @Input() columnIndex!: number;
  @Input() rowIndex!: number;
  @Input() isColumn: boolean;
  @Input() isPreview: boolean;
  @Output() HeadingDeleteEvent = new EventEmitter<{
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();
  @Output() duplicateControl = new EventEmitter<HeadingControl>();
  @Output() headingUpdateEvent = new EventEmitter<{
    control: HeadingControl;
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();

  deleteHeading(
    control: HeadingControl,
    index: number,
    columnIndex: number,
    rowIndex: number
  ) {
    this.HeadingDeleteEvent.emit({
      elementIndex: index,
      columnIndex: columnIndex,
      rowIndex: rowIndex,
    });
  }

  duplicateHeadingControl(control: HeadingControl): void {
    this.duplicateControl.emit(control);
  }
  editHeading(
    control: HeadingControl,
    controlIndex: number,
    columnIndex: number,
    rowIndex: number
  ): void {
    const dialogRef = this.dialog.open(
      HeadingDialogComponent,
      { data: control },
      "modal-width-xl"
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.headingUpdateEvent.emit({
          control: result,
          elementIndex: controlIndex,
          columnIndex: columnIndex,
          rowIndex: rowIndex,
        });
      }
    });
  }
}
