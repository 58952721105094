import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ICreateProjectStatus } from 'src/app/main/model/project.model';
import { MyTaskService } from 'src/app/main/services/account.service';
import { Projectservice } from 'src/app/main/services/project.service';
import { OffcanvasService } from 'src/app/ui/service/offcanvas.service';
import { WorkflowTaskAssignComponent } from 'src/app/workflow-template/view/workflow-task-assign/workflow-task-assign.component';

@Component({
  selector: 'app-draggable-task-view',
  templateUrl: './draggable-task-view.component.html',
  styleUrls: ['./draggable-task-view.component.sass']
})
export class DraggableTaskViewComponent implements OnChanges {
  @Input() statuses: ICreateProjectStatus[] = [];
  @Input() taskData: any = [];
  @Output() updateTaskStatus = new EventEmitter<{
    taskId: string;
    status: string;
    previousStatus: string
  }>();
  newTaskData: any = {};
  connectedDropLists: string[] = [];

  @Output()
  taskAdded = new EventEmitter<null>();

  constructor(
    public offCanvasService: OffcanvasService,
    private projectService: Projectservice,
    private myTaskService: MyTaskService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['taskData'] && this.taskData && this.taskData.length > 0 && changes['statuses'], this.statuses.length > 0) {
      this.organizeTasks();
      this.setupConnectedDropLists();
    }
  }

  organizeTasks(): void {
    let i = 0;
    while (i < this.statuses.length) {
      const status = this.statuses[i]
      console.log("filter data ", this.taskData.filter(task => task.status === status.statusName))
      this.newTaskData[status.statusName] = this.taskData.filter(task => task.status === status.statusName);
      i++;
    }
  }

  getBgColor(color) {
    return color ? color.split('-')[1] : 'gray'
  }

  setupConnectedDropLists(): void {
    this.connectedDropLists = this.statuses.map(status => status.statusName);
  }

  onDrop(event: CdkDragDrop<any[]>, targetStatus): void {
    console.log("mujhe badla chahiye", targetStatus)
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      let previousStatus = event.container.data[event.currentIndex].status
      // Update the task's status
      const movedTask = event.container.data[event.currentIndex];
      movedTask.status = targetStatus;
      this.updateTaskStatus.emit({ taskId: movedTask.id, status: movedTask.status, previousStatus: previousStatus })
      // You can handle API update or other necessary updates here to persist the changes
    }
  }

  handleAddtask() {
    const dialogRef = this.offCanvasService.open(
      WorkflowTaskAssignComponent,
      { data: { taskType: 'task' }, position: 'end', widthClass: 'task-detail' },
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.taskAdded.emit(null);
      }
    });
  }

  // getTaskList() {
  //   let query = {
  //     limit: 10,
  //     filter: { projectId: this.projectService.getSelectedProject(this.activatedAccount) }
  //   };
  //   this.myTaskService.list(query).subscribe({
  //     next: (data) => {
  //       this.taskData = data;
  //     }
  //   })
  // }
}