import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { FileUploadApi } from '../api/fileUpload.api';
import { v4 as uuid } from 'uuid';
import { JwtService } from './jwt.service';

import { HttpClient } from '@angular/common/http';
@Injectable()
export class FileUploadService {
  constructor(
    private notifications: NotificationsService,
    private fileUploadApi: FileUploadApi,
    private jwtService: JwtService,
    private http: HttpClient
  ) {
  }

  uploadProductImage(file: any, callback: (err, data) => void) {
    this.uploadFile({
      file: file,
      bucket: 'products'
    }, callback)
  }

  uploadCustomerImage(file: any, callback: (err, data) => void) {
    this.uploadFile({
      file: file,
      bucket: 'customers'
    }, callback)
  }

  uploadCompanyLogoImage(file: any, callback: (err, data) => void) {
    this.uploadFile({
      file: file,
      bucket: 'company'
    }, callback)
  }

  uploadProductOptionImage(file: any, callback: (err, data) => void) {
    this.uploadFile({
      file: file,
      bucket: 'productOptions'
    }, callback)
  }

  uploadSupportingDocument(file: any, type: string, callback: (err, data) => void) {
    const folder = this.getFolder();

    // TODO: Ensure this does not overwrite an existing file
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        this.uploadFile({
          file: file,
          bucket: `supportingDocuments/${folder}/${type}/${data?.account?.id}`
        }, callback)
      }
    })
  }

  uploadDocument(file: any, type: string, callback: (err, data) => void) {
    const folder = this.getFolder();

    // TODO: Ensure this does not overwrite an existing file

    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        this.uploadFile({
          file: file,
          bucket: `supportingDocuments/${folder}/${type}/${data.account.id}`
        }, callback)
      }
    })
  }

  uploadDataSheet(file: any, callback: (err, data) => void) {
    const folder = this.getFolder();

    this.uploadFile({
      file: file,
      bucket: `dataSheet/${folder}`
    }, callback)
  }

  uploadSizeChart(file: any, callback: (err, data) => void) {
    const folder = this.getFolder();

    this.uploadFile({
      file: file,
      bucket: `sizeChart/${folder}`
    }, callback)
  }

  /**
   * Helper method for determining the destination for a file upload
   */
  getFolder = (): string => process.env.NODE_ENV === 'production' ? 'prod' : process.env.NODE_ENV === 'staging' ? 'stage' : 'dev';

  uploadFile(options: { file: any, bucket: string }, callback: (err, data) => void) {

    let extension = options.file.name.match(/[^\.]+$/);

    let key = options.bucket + '/' + uuid() + '.' + extension[0];

    this.fileUploadApi.getSignedUploadUrl('static-dev-fusionflow-com-au', key)
      .subscribe(signedUrlResult => {
        this.fileUploadApi.decodeUri(options.file, signedUrlResult.data.url,).subscribe(result => {
          callback(null, {
            Location: signedUrlResult.data.url.replace(/\?.*$/, '')
          })
        }
        );
      });

  }


  public list(query: IQueryFilter): Observable<any> {
    return apiCallWrapper(
      this.fileUploadApi.list(query),
      {
        notificationsService: this.notifications,
        action: "Fetching User"
      }
    )
  }



  downloadFile(path: string, fileName: string): void {
    this.http.get(path, { responseType: 'blob' }).subscribe(
      (blob) => {
        this.saveFile(blob, fileName);
      },
      (error) => console.error('Download error', error)
    );
  }

  private saveFile(blob: Blob, fileName: string): void {
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
