<div
  class="modal fade config-add-modal show"
  id="addformModal"
  tabindex="-1"
  aria-labelledby="addformModalLabel"
  aria-hidden="true"
  style="display: block; padding-left: 0px"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-body">
        <form
          [formGroup]="inputFieldEditForm"
          class="login_contant"
          (ngSubmit)="onSubmit()"
        >
            <div class="form-group mb-25">
              <label class="form-label">Label Name</label>
              <input class="form-control" type="text" formControlName="label" />
              <p
                class="text-danger"
                *ngFor="
                  let validation of constants.accountValidationMessages.label
                "
              >
                <ng-container
                  class="text-danger"
                  *ngIf="
                    inputFieldEditForm
                      .get('label')
                      ?.hasError(validation.type) &&
                    (inputFieldEditForm.get('label')?.dirty ||
                      inputFieldEditForm.get('label')?.touched)
                  "
                >
                  {{ validation.message }}
                </ng-container>
              </p>
            </div>
          <ng-container *ngIf="handleCheckOptionsField() === false">
              <div class="form-group mb-25">
                <label class="form-label">placeholder</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="placeHolder"
                />
                <p
                  class="text-danger"
                  *ngFor="
                    let validation of constants.accountValidationMessages
                      .placeHolder
                  "
                >
                  <ng-container
                    *ngIf="
                      inputFieldEditForm
                        .get('placeHolder')
                        ?.hasError(validation.type) &&
                      (inputFieldEditForm.get('placeHolder')?.dirty ||
                        inputFieldEditForm.get('placeHolder')?.touched)
                    "
                  >
                    <span class="text-danger"> {{ validation.message }}</span>
                  </ng-container>
                </p>
              </div>
          </ng-container>
            <div class="form-group mb-25">
              <label class="form-label">Input Name</label>
              <input
                class="form-control"
                placeholder="Your Input Name"
                type="text"
                formControlName="name"
                required
              />
              <p
                class="text-danger"
                *ngFor="
                  let validation of constants.accountValidationMessages.name
                "
              >
                <ng-container
                  class="text-danger"
                  *ngIf="
                    inputFieldEditForm.get('name')?.hasError(validation.type) &&
                    (inputFieldEditForm.get('name')?.dirty ||
                      inputFieldEditForm.get('name')?.touched)
                  "
                >
                  {{ validation.message }}
                </ng-container>
              </p>
            </div>
          <div class="mb-3">
            <div class="form-check modal-check">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="isRequired"
                id="flexCheckIndeterminate"
              />
              <label class="form-check-label" for="flexCheckIndeterminate">
                Is required ?
              </label>
            </div>
          </div>
          <ng-container
            *ngIf="handleCheckOptionsField() === true"
            formArrayName="options"
          >
            <div
              class="row align-items-center"
              *ngFor="
                let option of options.controls;
                let index = index;
                let last = last
              "
              [formGroupName]="index"
            >
              <div class="col-md-1 mt-3">
                <div class="form-check modal-check">
                  <input
                    formControlName="defaultSelected"
                    class="form-check-input"
                    type="checkbox"
                    id="{{ 'val' + index }}"
                    (change)="radioChange($event, index)"
                    [checked]="option.value.defaultSelected"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-25">
                  <label class="form-label">Option Value {{ index + 1 }}</label>
                  <input
                    type="text"
                    placeholder="Option value"
                    formControlName="value"
                    placeholder="Option value"
                    minlength="1"
                    maxlength="50"
                    class="form-control"
                    (keyup)="onKey($event, index)"
                    pattern="^[a-z0-9_-]{1,50}$"
                    required
                  />
                  <p
                    class="text-danger"
                    *ngFor="
                      let validation of constants.accountValidationMessages
                        .value
                    "
                  >
                    <ng-container
                      class="text-danger"
                      *ngIf="
                        options.controls[index]
                          .get('value')
                          ?.hasError(validation.type) &&
                        (options.controls[index].get('value')?.errors?.unique ||
                          options.controls[index].get('value')?.dirty ||
                          options.controls[index].get('value')?.touched)
                      "
                    >
                      {{ validation.message }}
                    </ng-container>
                  </p>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group mb-25">
                  <label class="form-label">Option label {{ index + 1 }}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Option value"
                    formControlName="label"
                    placeholder="Option label"
                    minlength="1"
                    maxlength="999"
                    required
                  />
                  <p
                    class="text-danger"
                    *ngFor="
                      let validation of constants.accountValidationMessages
                        .label
                    "
                  >
                    <ng-container
                      class="text-danger"
                      *ngIf="
                        options.controls[index]
                          .get('label')
                          ?.hasError(validation.type) &&
                        (options.controls[index].get('label')?.dirty ||
                          options.controls[index].get('label')?.touched)
                      "
                    >
                      {{ validation.message }}
                    </ng-container>
                  </p>
                </div>
              </div>
              <div class="col-md-2 mt-4 mb-2">
                <div class="d-flex align-items-center justify-content-between">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-modal-trash notify-icon"
                    *ngIf="index !== 0"
                    (click)="removeOption(index)"
                  >
                    <!-- <i class="fa-regular fa-trash"></i> -->
                    <img
                      src="/assets/icons/component-dlt-icon.svg"
                      alt="component-dlt-icon"
                    />
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-modal-add notify-icon"
                    (click)="addNewOption()"
                    *ngIf="last"
                  >
                    <!-- <i class="fa-regular fa-plus"></i> -->
                    <img src="/assets/icons/plus-dark.svg" alt="plus-dark" />
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="modal-btns">
            <button type="button" class="btn btn-secondary" (click)="close()">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
