import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FieldRejection, ErrorMessage } from 'src/app/main/model/api.model';
import { UserAccountService } from 'src/app/main/services/account.service';
import { canSubmit } from 'src/app/main/util/formUtils';
import { serverSide } from 'src/app/main/util/utils';
import { DialogRef } from 'src/app/ui/service/dialog-ref';
import { DIALOG_DATA } from 'src/app/ui/service/dialog-tokens';

@Component({
  selector: 'app-add-account-dialog',
  templateUrl: './add-account-dialog.component.html',
  styles: ['']
})
export class AddAccountDialogComponent {
  fieldRejections: FieldRejection[] = [];
  form: UntypedFormGroup;
  constructor(
    private dialogRef: DialogRef,
    private userAccountService: UserAccountService,
    private formBuilder: UntypedFormBuilder,
    @Inject(DIALOG_DATA) public data: any
  ) {
    this.form = this.formBuilder.group({
      accountName: [null, Validators.required, serverSide(this.fieldRejections, 'accountName')],
      companyName: [null, Validators.required, serverSide(this.fieldRejections, 'companyName')],

    });
  }

  close() {
    this.dialogRef.close(false);

  }
  companyImage: any = null;

  handleSubmitColValue() {
    if (!canSubmit(this.form)) return;
    const formData: FormData = new FormData();
    formData.append('accountName', this.form.get('accountName')?.value);
    formData.append('companyName', this.form.get('companyName')?.value);
    formData.append('companyLogo', this.companyImage);
    this.userAccountService.create(formData)
      .subscribe(
        () => this.dialogRef.close(true),
        (err: ErrorMessage) => console.log(err),
        () => this.form.markAsUntouched()
      );

  }
  onFileChange(event: any) {
    const fileInput = event.target;
    const file = fileInput.files[0];
    this.companyImage = file
  }
}
