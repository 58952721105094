import { NotificationsService } from "angular2-notifications";
import { slideInAnimation } from "../util/animations";
import { AuthService } from "../services/auth.service";
import { CommonController } from '../util/common.controller';
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ErrorMessage, FieldRejection } from '../model/api.model';
import { serverSide } from '../util/utils';

@Component({
	styleUrls: ['_forget.component.scss'],
  template: `

<section class="login">
  <div class="container-fluid">
    <div class="login-content-flex forget mx-auto">
    <div class="pb-3">
        <h4 class="login-title">Set new password</h4>
        <p class="login-desc mb-0">
          Please enter the email address for your account. We will send instructions on how to reset your password.
        </p>
      </div>
      <div class="card login-card">
          <div class="card-body">
            <h4>Forgot Password!</h4>
            <p>Enter your email and we will Send you a reset link</p>
            <form [formGroup]="form">
              <div class="form-group">
                <label for="LoginEmail" class="form-label">E-mail or phone number</label>
                <div class="position-relative">
                <text-input field="email" placeholder="Enter your email" [form]="form" [errors]='{
                                              required: "Email Address Required",
                                              email: "Invalid Email Address",
                                              serverValidation: "Rejected by server"
                                          }'></text-input>
                <span class="icon d-none">
                  <img class="w-100" src="/assets/icons/login-user.svg" alt="login-user" />
                </span>
                </div>
              </div>
          
              <button type="button" [disabled]="!canSubmit(form)" (click)="reset()" class="btn btn-dark"
                role="button">
                Send me the link
              </button>
          
            </form>
          </div>
        </div>
    </div>
    <div class="back-to-login">
      <a routerLink="/login" role="button" class="btn btn-link">
        Return to Sign in
      </a>
    </div>
  </div>
</section>
	`,
  animations: [slideInAnimation]
})
export class ForgotComponent extends CommonController {
  form: UntypedFormGroup;
  fieldRejections: FieldRejection[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    super();

    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email], serverSide(this.fieldRejections, 'email')],
    });
  }

  reset() {
    if (!this.canSubmit(this.form)) return;

    this.form.markAsPending();

    this.authService.forgot(this.form.value)
      .subscribe(
        () => this.router.navigate(['/']),
        (err: ErrorMessage) => this.handleValidationErrors(err, this.form, this.fieldRejections),
        () => this.form.markAsUntouched()
      );
  }
}
