export const isRecord = (obj: unknown): obj is Record<string, unknown> => {
    return (
        typeof obj === "object" ||
        typeof obj === 'function' ||
        (
            obj instanceof Object &&
            !(obj instanceof Array)
        )
    ) && (
            obj !== null &&
            obj !== undefined
        )
}

export function hasKey<X extends {}, Y extends PropertyKey>(obj: unknown, prop: Y): obj is X & Record<Y, unknown> {
    return obj !== null && obj !== undefined && Object.prototype.hasOwnProperty.call(obj, prop) || (isRecord(obj) && prop in (obj as Record<string, unknown>));
}

export const getValue = <T = unknown>(obj: unknown, key: string | number, defaultValue: T): T => {
    return isRecord(obj) && hasKey(obj, key) && obj[key] as typeof defaultValue || defaultValue;
}