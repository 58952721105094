import { Injectable } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { DataTableApi, DataTableRecordApi } from "../api/data-table.api";
import { GenericService } from "../../main/services/generic.service";
import { IDataTable } from "../types/data-table";
import { GenericApi } from "src/app/main/api/generic.api";

@Injectable()
export class DataTableService extends GenericService<IDataTable> {
	constructor(
		public dataTableApi: DataTableApi,
		public notifications: NotificationsService
	) {
		super(dataTableApi as GenericApi<any>, notifications);
	}
}

@Injectable()
export class DataTableRecordService extends GenericService<IDataTable> {
	constructor(
		public dataTableRecordApi: DataTableRecordApi,
		public notifications: NotificationsService
	) {
		super(dataTableRecordApi as GenericApi<any>, notifications);
	}
}
