import { Injectable } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { FormsInstanceApi, PublicFormsInstanceApi } from "../api/forms-instance.api";
import { GenericService } from "../../main/services/generic.service";
import { IFormsInstance } from "../types/forms-instance";
import { GenericApi } from "src/app/main/api/generic.api";
import { formBuilderList } from "src/app/main/model/form-builder.model";

@Injectable()
export class FormsInstanceService extends GenericService<IFormsInstance> {
	constructor(
		public formsInstanceApi: FormsInstanceApi,
		public notifications: NotificationsService
	) {
		super(formsInstanceApi as GenericApi<any>, notifications);
	}
	convertFormBuilderObj(obj) {

		return {
			id: obj.id,
			name: obj.name,
			description: obj.description,
			formTemplateId: obj.formTemplateId,
			rows: obj.formInstanceRows.sort((a, b) => a.index - b.index).map((row) => ({
				uid: row.id,
				id: row.id,
				columns: row.formInstanceColumn.map((column) => ({
					id: column.id,
					uid: column.id,
					isColumn: column.isColumn,
					index: column.index,
					size: column.size,
					elements: column.formInstanceComponent.map((element) => ({
						id: element.id,
						type: element.type,
						label: element.label,
						name: element.name,
						placeHolder: element.placeHolder,
						isRequired: element.isRequired,
						result: element.result,
						options:
							element.formInstanceComponentOption.length === 0
								? []
								: element.formInstanceComponentOption.map((option) => ({
									id: option.id,
									label: option.label,
									value: option.value,
									defaultSelected:
										option.defaultSelected == "0" ? false : true,
								})),
					})),
				})),
			})),
		};
	}
}


@Injectable()
export class PublicFormsInstanceService extends GenericService<IFormsInstance> {
	constructor(
		public publicFormsInstanceApi: PublicFormsInstanceApi,
		public notifications: NotificationsService
	) {
		super(publicFormsInstanceApi as GenericApi<any>, notifications);
	}
}