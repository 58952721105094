import {
  AuthApi, registerInput, resetInput, authenticateInput, resetContent,
  registerContent, authenticateContent, forgotInput, forgotContent
} from '../api/auth.api';
import { Injectable } from "@angular/core";
import { JwtService } from './jwt.service';
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { IJWTPayload, IRegisterInputPayload } from '../model/auth.model';

@Injectable()
export class AuthService {
  constructor(
    private authApi: AuthApi,
    private jwtService: JwtService,
    public notifications: NotificationsService,
  ) { }

  /**
   * @description Fetches a new JWT token using the supplied refresh token
   */
  public readonly refreshToken = (jwtString: string, jwtRefreshString: string): Observable<IJWTPayload> => {
    return apiCallWrapper(
      this.authApi.refresh({ accessToken: jwtString, refreshToken: jwtRefreshString }),
      {
        notificationsService: this.notifications,
        action: "Obtaining new access keys"
      }
    )
  }

  public register = (opts: IRegisterInputPayload): Observable<registerContent> => {
    return apiCallWrapper(
      this.authApi.register(opts),
      {
        notificationsService: this.notifications,
        action: "Registering",
        message: "Creating your account",
        successMessage: "An email has been sent to your inbox"
      }
    );
  }

  public reset = (opts: resetInput): Observable<resetContent> => {
    return apiCallWrapper(
      this.authApi.reset(opts),
      {
        notificationsService: this.notifications,
        action: "Changing password"
      }
    );
  }

  public authenticate = (opts: authenticateInput): Observable<authenticateContent> => {
    return apiCallWrapper(
      this.authApi.authenticate(opts),
      {
        notificationsService: this.notifications,
        action: "Authenticating"
      }
    )
  }

  public forgot = (opts: forgotInput): Observable<forgotContent> => {
    return apiCallWrapper(
      this.authApi.forgot(opts),
      {
        notificationsService: this.notifications,
        action: "Reset Password",
        failTitle: "Reset Failed",
        successTitle: "Reset Complete",
        successMessage: "Instructions were sent to your email address"
      }
    )
  }

  public isAuthenticated = () => this.jwtService.currentJwtPayload$.getValue() !== null;
}