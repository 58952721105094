import { Component } from '@angular/core';
import { CommonController } from '../util/common.controller';

@Component({
  selector: 'faq',
  styleUrls: ['./_faq.component.scss'],
  template: `
	    <section class="faq">
        <div class="container-fluid">
          <h1 class="title">You've got questions. We've got answers.</h1>
          <div class="faq-collapse">
            <div class="accordion" id="FaqAccordion">
              <!-- accrodion-collapse 1 start here... -->
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Faqcollapse1"
                    aria-expanded="true"
                    aria-controls="Faqcollapse1"
                  >
                   How does FusionFlow handle security and data?
                  </button>
                </h2>
                <div
                  id="Faqcollapse1"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#FaqAccordion"
                >
                  <div class="accordion-body">
                    <p>
                      FusionFlow keeps your data secure by separating everything into distinct projects and accounts, so only the right people can access the right information.
                      Within each project, user access is carefully controlled, ensuring everyone only sees what they need to. All data is validated before it's shared, ensuring
                      your information stays safe and protected at every step.
                    </p>
                  </div>
                </div>
              </div>
              <!-- accrodion-collapse 1 end here... -->

              <!-- accrodion-collapse 2 start here... -->
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Faqcollapse2"
                    aria-expanded="false"
                    aria-controls="Faqcollapse2"
                  >
                   Can FusionFlow integrate with the tools we already use?
                  </button>
                </h2>
                <div
                  id="Faqcollapse2"
                  class="accordion-collapse collapse"
                  data-bs-parent="#FaqAccordion"
                >
                  <div class="accordion-body">
                    <p>
                    Yes, FusionFlow is built to easily integrate with the tools you already use through API connections. We're also planning to add built-in integrations
                    with popular apps in future updates, making it even simpler to connect everything you need.
                    </p>
                  </div>
                </div>
              </div>
              <!-- accrodion-collapse 2 end here... -->

              <!-- accrodion-collapse 3 start here... -->
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Faqcollapse3"
                    aria-expanded="false"
                    aria-controls="Faqcollapse3"
                  >
                  How does the pricing work?
                  </button>
                </h2>
                <div
                  id="Faqcollapse3"
                  class="accordion-collapse collapse"
                  data-bs-parent="#FaqAccordion"
                >
                  <div class="accordion-body">
                    <p>
                    FusionFlow's pricing is flexible and predictable, offering tiered plans based on monthly usage. Each plan provides a set number of function calls,
                    and if you need more, additional usage can be purchased without upgrading to the next tier. This ensures that businesses with varying levels of
                    activity can scale efficiently and cost-effectively, without being penalized for infrequent use or unexpected spikes in demand.
                    </p>
                  </div>
                </div>
              </div>
              <!-- accrodion-collapse 3 end here... -->

              <!-- accrodion-collapse 4 start here... -->
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#Faqcollapse4"
                    aria-expanded="false"
                    aria-controls="Faqcollapse4"
                  >
                  How customizable is FusionFlow to fit my specific business needs?
                  </button>
                </h2>
                <div
                  id="Faqcollapse4"
                  class="accordion-collapse collapse"
                  data-bs-parent="#FaqAccordion"
                >
                  <div class="accordion-body">
                    <p>
                    FusionFlow is highly customizable to fit your business needs. Its intuitive workflow configuration screen allows you to easily design and automate workflows directly from your
                    browser. With built-in tools and task management, you can tailor processes to match your specific operations without needing deep technical knowledge. This flexibility ensures
                    that FusionFlow adapts to your business, no matter how unique or complex your workflows may be.
                    </p>
                  </div>
                </div>
              </div>
              <!-- accrodion-collapse 4 end here... -->
            </div>
          </div>
        </div>
      </section>
	`,
  styles: [``]
})
export class FaqComponent extends CommonController { }
