import { Component, OnInit } from '@angular/core';
import { ChangeTaskStatusService, MyTaskService, UserTaskService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Route } from 'aws-sdk/clients/eventbridge';
import { FormsInstanceService } from 'src/app/forms-instance/service/forms-instance.service';
import { WorkflowInstanceService } from 'src/app/workflow/service/workflow-instance.service';
import { IUser } from 'src/app/user/types/user';
import { WorkflowTaskAssignComponent } from 'src/app/workflow-template/view/workflow-task-assign/workflow-task-assign.component';
import { OffcanvasService } from 'src/app/ui/service/offcanvas.service';
import { Projectservice, ProjectStatusService } from '../../services/project.service';
import { JwtService } from '../../services/jwt.service';
import { ICreateProjectStatus } from '../../model/project.model';

@Component({
  selector: 'app-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.sass']
})
export class TaskBoardComponent implements OnInit {
  constructor(
    public myTaskService: MyTaskService,
    public router: Router, public route: ActivatedRoute,
    public workflowInstanceService: WorkflowInstanceService,
    public offCanvasService: OffcanvasService,
    public projectService: Projectservice,
    private jwtService: JwtService,
    private changeTaskStatusService: ChangeTaskStatusService,
    private projectStatusService: ProjectStatusService,
    private userTaskService: UserTaskService
  ) { }
  taskData: any = [];
  skip: number = 0;
  limit: number = 10;
  order: string = "asc";
  filter: object = {};
  totalItems: number;
  pageSize: number = 10;
  totalPage: number;
  activatedAccount: any
  statuses: ICreateProjectStatus[] = [];
  isLoading: boolean = false;

  getTaskColor(val: string) {
    return this.statuses.find(data => data.statusName == val)?.description || 'bg-gray-25';
  }

  ngOnInit(): void {
    this.monitorJwtData();
    this.getProjectStatuses()
  }

  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.activatedAccount = data.account;
          this.getTaskList();
        }
      },
    });
  }
  getProjectStatuses() {
    this.projectStatusService.projectStatus$.subscribe(statuses => {
      this.statuses = statuses;
    });
  }

  getTaskList() {
    this.isLoading = true
    let query = {
      skip: this.skip,
      limit: this.limit,
      order: this.order,
      filter: { projectId: this.projectService.getSelectedProject(this.activatedAccount.id) }
    };
    this.router.navigate(["."], {
      relativeTo: this.route,
      queryParams: query,
      queryParamsHandling: "",
    });
    this.myTaskService.list(query).subscribe({
      next: (data) => {
        this.taskData = this.organizeTasks(data.rows);
      },
      complete: () => this.isLoading = false
    })
  }

  organizeTasks(taskData) {
    let parentTasks = taskData.filter(task => task.taskId === null);
    let childTasks = taskData.filter(task => task.taskId !== null);

    childTasks.sort((a, b) => a.taskId.localeCompare(b.taskId));

    let result: any[] = [];
    parentTasks.forEach(parent => {
      result.push(parent);
      result.push(...childTasks.filter(child => child.taskId === parent.id));
    });

    return result;
  }

  goToTask(id, taskId, workflowInstanceId, instanceType) {
    // if (instanceType === 'workflowInstance') {
    //   this.workflowInstanceService.get(id).subscribe({
    //     next: (data: any) => {
    //       this.router.navigate(["/dashboard/config/workflows/templates/workflow-execute/" + data.workflowTemplateId], {
    //         queryParams: { taskId, workflowInstanceId: instanceId }
    //       })
    //     }
    //   })

    // } else {
    this.router.navigate(['/dashboard/config/forms/task/' + id], { queryParams: { taskId, workflowInstanceId } });
    // }
  }

  getStatusBadgeClass(status) {
    if (status === 'On Track' || 'Completed') {
      return 'bg-green'
    } else if (status === 'At Risk' || 'Due') {
      return 'bg-orange'
    } else if (status === 'Off Track') {
      return 'bg-red'
    }
  }

  getPriorityBedgeClass(status) {
    let classObj = { Low: "bg-red-light", Medium: "bg-green-light", High: "bg-blue-light" }
    return classObj[status]
  }

  handleAddtask() {
    const dialogRef = this.offCanvasService.open(
      WorkflowTaskAssignComponent,
      { data: {}, position: 'end', widthClass: 'task-detail' },
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log("task result", result);
      }
    });
  }

  editTask(id: string, subTask, isEdit) {
    const dialogRef = this.offCanvasService.open(
      WorkflowTaskAssignComponent,
      { data: { id: id, isSubTask: subTask, isTaskEdit: isEdit, taskType: 'task' }, position: 'end', widthClass: 'task-detail' },
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getTaskList();
        console.log("task result", result);
      }
    });
  }

  /**
* @description Updates the task status to 'Completed' and navigates to the task board.
*/
  updateTask(taskId, status, oldStatus) {
    let formData = {
      status: status
    };
    this.changeTaskStatusService.update(taskId, formData).subscribe({
      next: () => {
        this.userTaskService.getActivityLog(formData, { status: oldStatus }, this.activatedAccount, [], taskId);
        this.getTaskList();
      },
      error: (err) =>
        console.log(err),
    });
  }

  addSubTask(id: number) {
    this.taskData.push({ taskName: "testing", subTaskId: id });
  }

}
