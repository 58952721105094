import { Component, Input } from "@angular/core";

@Component({
  selector: "app-icon",
  template: `
         <div class="card config-card" >
                  <div class="card-body">
                    <div class="d-flex align-items-start justify-content-center flex-column card-gap">
                      <div class="card-img">
                        <img src="{{icon}}" alt="Form-instances-config" />
                      </div>
                      <div>
                        <h4 class="title">{{text}}</h4>
                        <p class="description">
                            {{description}}
                        </p>
                      </div>
                      <div class="text-end w-100">
                        <a [routerLink]="route" class="icon-link icon-link-hover"  role="button" >
                          See {{link}}
                          <span>
                            <img src="/assets/icons/config-card-right-arrow.svg" alt="config-card-right-arrow" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div> 
  `,
  styles: [``],
})
export class AppIconComponent {
  @Input()
  public icon: string = "";

  @Input()
  public text: string = "";

  @Input()
  public route: string[] = [];

  @Input()
  public description: string = '';

  @Input()
  public link: string = '';

  constructor() { }
}

