import { Component } from "@angular/core";

@Component({
    selector: 'app-list',
    template: `

    <div class="d-flex flex-wrap">
        <app-icon
            text="Form instances"
            link="Forms"
            description="Create form into pieces as instance of a form template"
            icon="/assets/icons/Form-instances-config.svg"
            [route]="['/dashboard/config/forms']">
        </app-icon>
        <app-icon
           text="File storage"
           link="Folder"
           description="A file storage is where you manage all your stuff"
           icon="/assets/icons/File-storage-config.svg"
           [route]="['/dashboard/config/files/file-storage']">
        </app-icon>
        <app-icon
            text="Data table"
            link="Tables"
            description="Create data tables with data type and validation feature"
            icon="/assets/icons/Data-table-config.svg"
            [route]="['/dashboard/config/data-tables']">
        </app-icon>
        <app-icon
            text="Users & Groups"
            link="Groups"
            description="Create user groups and manage user according to their task"
            icon="/assets/icons/Users-Groups-config.svg"
            [route]="['/dashboard/config/users']">
        </app-icon>
        <app-icon
            text="Email templates"
            link="Templates"
            description="Create customised email templates with all the
                          options and validations"
            icon="/assets/icons/Email-templates-config.svg"
            [route]="['/dashboard/config/email-templates']">
        </app-icon>
       <app-icon
            text="Workflow"
            link="Workflow"
            description="Create a workflow with the help of templates"
            icon="/assets/icons/Workflow-config.svg"
            [route]="['/dashboard/config/workflows']">
        </app-icon>
    </div>
        
    `,
    styles: [``]
})
export class AppListComponent {
    constructor() { }
}
