import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextInputComponent } from './view/text-input/text-input.component';
import { SectionTitleComponent } from './view/section-title.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContenteditableModel } from './directive/ContenteditableModel';
import { SelectInputComponent } from './view/select-input.component';
// import { NgSelect2Module } from 'ng-select2';
import { RouterModule } from '@angular/router';
import { ServiceEventsComponent } from './view/service-events.component';
import { TrimPipe } from './pipe/trim.pipe';
import { DataGridModule } from './view/data-grid/data-grid.module';
import { SectionHeaderComponent } from './view/section-header.component';
import { ConfigHeaderComponent } from './view/config-header.component';
import { UserSelect2Component } from './view/user-select2.component';
import { MultiSelectDropDownComponent } from './view/multi-select-dropdown.component';
import { ModuleTitleDialogComponent } from './view/module-title.dialog'
import { FbcInputComponent } from "../forms-template/view/form-builder/Form-Controls/fbc-input/fbc-input.component";
import { FbcNumberComponent } from "../forms-template/view/form-builder/Form-Controls/fbc-number/fbc-number.component";
import { FbcCheckboxComponent } from "../forms-template/view/form-builder/Form-Controls/fbc-checkbox/fbc-checkbox.component";
import { FbcRadioComponent } from "../forms-template/view/form-builder/Form-Controls/fbc-radio/fbc-radio.component";
import { FbcSelectComponent } from "../forms-template/view/form-builder/Form-Controls/fbc-select/fbc-select.component";
import { FbcTextareaComponent } from "../forms-template/view/form-builder/Form-Controls/fbc-textarea/fbc-textarea.component";
import { FbcFileUploadComponent } from "../forms-template/view/form-builder/Form-Controls/fbc-file-upload/fbc-file-upload.component";
import { FbcHeadingComponent } from "../forms-template/view/form-builder/Form-Controls/fbc-heading/fbc-heading.component";
import { InputDialogComponent } from "../forms-template/view/form-builder/Form-Controls/core/shared-dialog/input-dialog/input-dialog.component";
import { FbcColumnComponent } from "../forms-template/view/form-builder/Form-Controls/fbc-column/fbc-column.component";
import { FbcFieldViewComponent } from "../forms-template/view/form-builder/fbc-field-view/fbc-field-view.component";
import { HeadingDialogComponent } from "../forms-template/view/form-builder/Form-Controls/core/shared-dialog/heading-control/heading-dialog.component";
import { JsonPreviewDialogComponent } from "../forms-template/view/form-builder/Form-Controls/core/shared-dialog/json-preview-dialog/json-preview-dialog.component";
import { FormPreviewDialogComponent } from "../forms-template/view/form-builder/Form-Controls/core/shared-dialog/form-preview-dialog/form-preview-dialog.component";
import { ControlActionComponent } from '../forms-template/view/form-builder/Form-Controls/control-action.component'
import { FormsElementOptionService } from '../forms-template/service/forms-template.service';
import { FormsElementOptionApi } from '../forms-template/api/forms-template.api';
import { FolderViewComponentComponent } from "../file/view/common/folder-view-component/folder-view-component.component";
import { CreateFolderDialogComponent } from "../file/view/common/create-folder-dialog/create-folder-dialog.component";
import { TransferFileDialogComponent } from "../file/view/common/transfer-file-dialog/transfer-file-dialog.component";
import { TableRecordDialogComponent } from "../data-table/view/common/table-record-dialog/table-record-dialog.component";
import { CreateUserComponent } from "../user/view/dialog/create-user/create-user.component";
import { CreateGroupComponent } from "../user/view/dialog/create-group/create-group.component";

import { EmailElementViewComponent } from "../email-template/view/core-email-editor/email-element-view/email-element-view.component";
import { EmailElementHeadingComponent } from "../email-template/view/core-email-editor/email-element-view/email-element-heading/email-element-heading.component";
import { EmailElementTextComponent } from "../email-template/view/core-email-editor/email-element-view/email-element-text/email-element-text.component";
import { EmailElementDeviderComponent } from "../email-template/view/core-email-editor/email-element-view/email-element-devider/email-element-devider.component";
import { EmailElementButtonComponent } from "../email-template/view/core-email-editor/email-element-view/email-element-button/email-element-button.component";
import { EmailElementHtmlComponent } from "../email-template/view/core-email-editor/email-element-view/email-element-html/email-element-html.component";
import { EmailElementImageComponent } from "../email-template/view/core-email-editor/email-element-view/email-element-image/email-element-image.component";
import { EmailElementMenuComponent } from "../email-template/view/core-email-editor/email-element-view/email-element-menu/email-element-menu.component";
// import { DropdownSelectComponent } from "./view/dropdown-select.component";
import { EmailPreviewDialogComponent } from "../email-template/view/core-email-editor/common/email-preview-dialog/email-preview-dialog.component";
// import { WorkFlowListIconsComponent } from "../workflow-template/view/common/workflow-list-icons.component";
// import { WorkFlowListItemComponent } from "../workflow-template/view/common/workflow-list-item.component";
import { WorkflowFormComponent } from "../workflow-template/view/workflow-preview/workflow-form/workflow-form.component";
import { WorkflowTableComponent } from "../workflow-template/view/workflow-preview/workflow-table/workflow-table.component";
import { WorkflowTaskAssignComponent } from '../workflow-template/view/workflow-task-assign/workflow-task-assign.component';
import { WorkflowQuickViewComponent } from '../workflow-template/view/workflow-quick-view/workflow-quick-view.component';
import { SearchTemplateDialogComponent } from '../workflow-template/view/create-workflow/search-template-dialog/search-template-dialog.component'
import { TableViewDialogComponent } from '../workflow-template/view/create-workflow/table-view-dialog/table-view-dialog.component';
import { WebhookUrlDialogComponent } from '../workflow-template/view/Webhook-url/webhook-url-dialog.component'
import { FlatpickrModule } from 'angularx-flatpickr';
import { SharedModule } from './shared.module'
@NgModule({
  declarations: [
    SectionTitleComponent,
    TextInputComponent,
    SelectInputComponent,
    ServiceEventsComponent,
    ContenteditableModel,
    SectionHeaderComponent,
    TrimPipe,
    UserSelect2Component,
    MultiSelectDropDownComponent,
    ConfigHeaderComponent,
    ModuleTitleDialogComponent,
    FbcInputComponent,
    FbcNumberComponent,
    FbcCheckboxComponent,
    FbcRadioComponent,
    FbcSelectComponent,
    FbcTextareaComponent,
    FbcFileUploadComponent,
    FbcHeadingComponent,
    InputDialogComponent,
    HeadingDialogComponent,
    FbcColumnComponent,
    FbcFieldViewComponent,
    JsonPreviewDialogComponent,
    FormPreviewDialogComponent,
    ControlActionComponent,
    TableRecordDialogComponent,
    FolderViewComponentComponent,
    CreateFolderDialogComponent,
    TransferFileDialogComponent,
    CreateGroupComponent,
    CreateUserComponent,
    EmailElementViewComponent,
    EmailElementHeadingComponent,
    EmailElementTextComponent,
    EmailElementDeviderComponent,
    EmailElementButtonComponent,
    EmailElementHtmlComponent,
    EmailElementImageComponent,
    EmailElementMenuComponent,
    EmailPreviewDialogComponent,
    // WorkFlowListIconsComponent,
    // WorkFlowListItemComponent,
    WorkflowFormComponent,
    WorkflowTableComponent,
    WorkflowTaskAssignComponent,
    WorkflowQuickViewComponent,
    SearchTemplateDialogComponent,
    WebhookUrlDialogComponent,
    TableViewDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    // NgSelect2Module,
    ObserversModule,

    DataGridModule,

    RouterModule,
    FlatpickrModule.forRoot()
  ],
  providers: [FormsElementOptionService, FormsElementOptionApi],
  exports: [
    CommonModule,
    SectionTitleComponent,
    SectionHeaderComponent,
    ConfigHeaderComponent,
    TextInputComponent,
    SelectInputComponent,
    FormsModule,
    ReactiveFormsModule,
    // NgSelect2Module,
    ServiceEventsComponent,
    TrimPipe,
    DataGridModule,
    UserSelect2Component,
    MultiSelectDropDownComponent,
    ModuleTitleDialogComponent,
    FbcInputComponent,
    FbcNumberComponent,
    FbcCheckboxComponent,
    FbcRadioComponent,
    FbcSelectComponent,
    FbcTextareaComponent,
    FbcFileUploadComponent,
    FbcHeadingComponent,
    InputDialogComponent,
    HeadingDialogComponent,
    FbcColumnComponent,
    FbcFieldViewComponent,
    JsonPreviewDialogComponent,
    FormPreviewDialogComponent,
    ControlActionComponent,
    TableRecordDialogComponent,
    FolderViewComponentComponent,
    CreateFolderDialogComponent,
    TransferFileDialogComponent,
    CreateGroupComponent,
    CreateUserComponent,
    EmailElementViewComponent,
    EmailElementHeadingComponent,
    EmailElementTextComponent,
    EmailElementDeviderComponent,
    EmailElementButtonComponent,
    EmailElementHtmlComponent,
    EmailElementImageComponent,
    EmailElementMenuComponent,
    EmailPreviewDialogComponent,
    // WorkFlowListIconsComponent,
    // WorkFlowListItemComponent,
    WorkflowFormComponent,
    WorkflowTableComponent,

    WorkflowTaskAssignComponent,
    WorkflowQuickViewComponent,
    SearchTemplateDialogComponent,
    WebhookUrlDialogComponent,
    TableViewDialogComponent,
    SharedModule
  ]

})
export class UiModule { }
