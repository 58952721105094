import { GenericApi } from "../../main/api/generic.api";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class UserApi extends GenericApi {
    public path = "user";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}

@Injectable()
export class PermissionApi extends GenericApi {
    public path = "permission";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}

@Injectable()
export class PermissionTypeApi extends GenericApi {
    public path = "permission-type";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}

@Injectable()
export class GroupApi extends GenericApi {
    public path = "group";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}

@Injectable()
export class UserGroupApi extends GenericApi {
    public path = "user-group";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}

@Injectable()
export class MyGroupApi extends GenericApi {
    public path = "group/my-groups";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}
// user/my-user/
@Injectable()
export class MyUserApi extends GenericApi {
    public path = "user/my-user";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}

@Injectable()
export class MyUserListApi extends GenericApi {
    public path = "user/list";

    constructor(public httpClient: HttpClient) {
        super(httpClient);
    }
}


