import { Injectable } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import {
  FormsTemplateApi,
  FormsRowApi,
  FormsColumnApi,
  FormsElementApi,
  FormsElementOptionApi,
  FormTemplateMoveToInstanseApi,
} from "../api/forms-template.api";
import { GenericService } from "../../main/services/generic.service";
import { IFormsTemplate } from "../types/forms-template";
import { GenericApi } from "src/app/main/api/generic.api";

@Injectable()
export class FormsTemplateService extends GenericService<IFormsTemplate> {
  constructor(
    public formsTemplateApi: FormsTemplateApi,
    public notifications: NotificationsService
  ) {
    super(formsTemplateApi as GenericApi<any>, notifications);
  }
  convertFormBuilderObj(obj) {
    console.log("convertFormBuilderObj", obj.formTemplateRows)
    return {
      id: obj.id,
      name: obj.name,
      description: obj.description,
      rows: obj.formTemplateRows.map((row) => ({
        uid: row.id,
        id: row.id,
        columns: row.formTemplateColumn.map((column) => ({
          id: column.id,
          uid: column.id,
          isColumn: column.isColumn,
          index: column.index,
          size: column.size,
          elements: column.formTemplateElement.map((element) => ({
            id: element.id,
            type: element.type,
            label: element.label,
            name: element.name,
            placeHolder: element.placeHolder,
            isRequired: element.isRequired,
            options:
              element.formTemplateElementOption.length === 0
                ? []
                : element.formTemplateElementOption.map((option) => ({
                  id: option.id,
                  label: option.label,
                  value: option.value,
                  defaultSelected:
                    option.defaultSelected == "0" ? false : true,
                })),
          })),
        })),
      })),
    };
  }
}
@Injectable()
export class FormsRowService extends GenericService<IFormsTemplate> {
  constructor(
    public formsRowApi: FormsRowApi,
    public notifications: NotificationsService
  ) {
    super(formsRowApi as GenericApi<any>, notifications);
  }

}
@Injectable()
export class FormsColumnService extends GenericService<IFormsTemplate> {
  constructor(
    public formsColumnApi: FormsColumnApi,
    public notifications: NotificationsService
  ) {
    super(formsColumnApi as GenericApi<any>, notifications);
  }
}
@Injectable()
export class FormsElementService extends GenericService<IFormsTemplate> {
  constructor(
    public formsElementApi: FormsElementApi,
    public notifications: NotificationsService
  ) {
    super(formsElementApi as GenericApi<any>, notifications);
  }
}
@Injectable()
export class FormsElementOptionService extends GenericService<IFormsTemplate> {
  constructor(
    public formsElementOptionApi: FormsElementOptionApi,
    public notifications: NotificationsService
  ) {
    super(formsElementOptionApi as GenericApi<any>, notifications);
  }

}


@Injectable()
export class FormTemplateMoveToInstanceService extends GenericService<IFormsTemplate> {
  constructor(
    public moveToInstance: FormTemplateMoveToInstanseApi,
    public notifications: NotificationsService
  ) {
    super(moveToInstance as GenericApi<any>, notifications);
  }
}

