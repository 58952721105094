import { Injectable } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import {
	GroupApi,
	MyGroupApi,
	MyUserApi,
	MyUserListApi,
	PermissionApi,
	PermissionTypeApi,
	UserApi,
	UserGroupApi,
} from "../api/user.api";
import { GenericService } from "../../main/services/generic.service";
import {
	IGroup,
	IPermission,
	IPermissionType,
	IUser,
	IUserGroup,
} from "../types/user";
import { GenericApi } from "src/app/main/api/generic.api";

@Injectable()
export class UserService extends GenericService<IUser> {
	constructor(
		public userApi: UserApi,
		public notifications: NotificationsService
	) {
		super(userApi as GenericApi<any>, notifications);
	}
}

@Injectable()
export class PermissionService extends GenericService<IPermission> {
	constructor(
		public permissionApi: PermissionApi,
		public notifications: NotificationsService
	) {
		super(permissionApi as GenericApi<any>, notifications);
	}
}

@Injectable()
export class PermissionTypeService extends GenericService<IPermissionType> {
	constructor(
		public permissionTypeApi: PermissionTypeApi,
		public notifications: NotificationsService
	) {
		super(permissionTypeApi as GenericApi<any>, notifications);
	}
}

@Injectable()
export class GroupService extends GenericService<IGroup> {
	constructor(
		public groupApi: GroupApi,
		public notifications: NotificationsService
	) {
		super(groupApi as GenericApi<any>, notifications);
	}
}

@Injectable()
export class UserGroupService extends GenericService<IUserGroup> {
	constructor(
		public userGroupApi: UserGroupApi,
		public notifications: NotificationsService
	) {
		super(userGroupApi as GenericApi<any>, notifications);
	}
}

@Injectable()
export class myGroupService extends GenericService<IUserGroup> {
	constructor(
		public myGroupApi: MyGroupApi,
		public notifications: NotificationsService
	) {
		super(myGroupApi as GenericApi<any>, notifications);
	}
}


@Injectable()
export class MyUserService extends GenericService<IUserGroup> {
	constructor(
		public myUserApi: MyUserApi,
		public notifications: NotificationsService
	) {
		super(myUserApi as GenericApi<any>, notifications);
	}
}



@Injectable()
export class MyUsersListService extends GenericService<IUserGroup> {
	constructor(
		public myUserListApi: MyUserListApi,
		public notifications: NotificationsService
	) {
		super(myUserListApi as GenericApi<any>, notifications);
	}
}



