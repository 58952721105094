<ng-container *ngIf="control as control">
  <div class="img-uploader">
    <label for="fileInput" *ngIf="!isPreview">
      <h5 class="before-text">Upload image</h5>
      <input type="file" id="fileInput" hidden class="form-control" (change)="onFileSelected($event)" />
    </label>

  </div>

  <form *ngIf="isPreview" [formGroup]="form">
    <div class="img-uploader">
      <label for="fileInput">
        <h5 class="before-text">Upload image</h5>
        <input type="file" id="fileInput" hidden class="form-control" formControlName="{{ control.name }}"
          (change)="onFileSelected($event)" />
      </label>
      <ng-container *ngIf="
          isPreview === true &&
          form?.get(control.name || '')?.hasError('required') &&
          (form?.get(control.name || '')?.dirty ||
            form?.get(control.name || '')?.touched)
        ">
        <span class="text-danger"> {{ control.label }} is required</span>
      </ng-container>
    </div>
  </form>

  <!-- <ng-template > -->
  <control-action [isPreview]="isPreview" [isColumn]="isColumn" (duplicate)="duplicateFileUploadControl(control)"
    (delete)="deleteTextField(control, elementIndex, columnIndex, rowIndex)"></control-action>

  <!-- </ng-template> -->
</ng-container>