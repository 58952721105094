import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { WorkflowGenerateURLApi, WorkflowMessageApi, WorkflowPublicTemplateApi, WorkflowTemplateApi, WorkflowTemplateStepActionApi, WorkflowTemplateStepApi, WorkflowTemplateStepTriggerApi } from '../api/workflow-template.api';
import { GenericService } from '../../main/services/generic.service';
import { IWorkflowTemplate } from '../types/workflow-template';
import { GenericApi } from 'src/app/main/api/generic.api';
import { v4 as uuidv4 } from 'uuid';
import { JwtService } from 'src/app/main/services/jwt.service';

@Injectable()
export class WorkflowTemplateService extends GenericService<IWorkflowTemplate> {
	constructor(
		public workflowTemplateApi: WorkflowTemplateApi,
		public notifications: NotificationsService
	) {
		super(workflowTemplateApi as GenericApi<any>, notifications);
	}
	globalVariables: any = []
	findVariables = (obj) => {

		// JSON.stringify(obj).match(/{{([\w_]+)}}/g);   alternate way 

		const regex = /{{\s*([\w_]+)\s*}}/g;
		let results = new Set();


		const searchInString = (str) => {
			let match;
			while ((match = regex.exec(str)) !== null) {
				results.add(match[1]);
			}
		};

		const traverseObject = (obj) => {
			for (const key in obj) {
				if (typeof obj[key] === 'string') {
					searchInString(obj[key]);
				} else if (typeof obj[key] === 'object' && obj[key] !== null) {
					traverseObject(obj[key]);
				}
			}
		};

		traverseObject(obj);
		return [...results];
	};

	addActionToTrigger = (action, index, workflowTemplateId,) => {
		if (action.event == "complete") {
			return {
				...(workflowTemplateId && { id: action.id }),
				action: "$step.complete",
				index: index,
				...(action.condition && { condition: action.condition }),
			};
		}
		if (action.title === "setValue") {
			return {
				...(workflowTemplateId && { id: action.id }),
				action: "$setValues",
				index: index,
				variables: action.config
			};
		}
		if (action.type === 'webhook') {
			let config: any = null;
			let actionPrefix = '';

			switch (action.templateType) {
				case 'table':
					config = { dataTableId: action.templateId, templateTitle: action.templateTitle };
					actionPrefix = '$dataTable.';
					break;
				case 'form':
					config = { formTemplateId: action.templateId, templateTitle: action.templateTitle };
					actionPrefix = '$formInstance.';
					break;
				case 'url':
					config = {
						requestUrl: action.config.webhookUrl,
						method: action.config.method,
						headers: JSON.stringify(action.config.headers),
						postData: JSON.stringify(action.config.postData),
					};
					actionPrefix = '$webhook.';
					break;
			}

			const actionSuffix = action.title.split('.')[1];
			return {
				action: `${actionPrefix}${actionSuffix}`,
				...(action.condition && { condition: action.condition }),
				config,
				index,
			};
		}

		if (action.type === 'form') {
			return {
				...(workflowTemplateId && { id: action.id }),
				action: "$formInstance." + action.title.split('.')[1],
				index: index,
				...(action.condition && { condition: action.condition }),
				config: {
					formTemplateId: action.templateId,
					name: action.templateTitle,
				},
			};
		}
		if (action.type === 'table') {
			return {
				...(workflowTemplateId && { id: action.id }),
				action: "$DataTable." + action.title.split('.')[1],
				index: index,
				...(action.condition && { condition: action.condition }),
				config: {
					datatableId: action.templateId,
					name: action.templateTitle,
					filter: action?.filter || {}
				},
			};
		}
		if (action.type === "email") {
			return {
				...(workflowTemplateId && { id: action.id }),
				action: "$email.send",
				index: index,
				...(action.condition && { condition: action.condition }),
				config: {
					emailTemplateId: action.templateId,
					destination: action.to,
					subject: action.subject,
					context: action?.filter || {}
				},
			};
		} if (action.event === 'Task') {
			return {
				...(workflowTemplateId && { id: action.id }),
				"action": "$task.create",
				...(action.condition && { condition: action.condition }),
				index: index,
				config: action.filter,
			};
		} else {
			return {
				...(workflowTemplateId && { id: action.id }),
				action: `$email.${action.event}`,
				...(action.condition && { condition: action.condition }),
				index: index,
				...(action.event === "execute" && {
					config: {
						name: "UNHAPPY-CUSTOMER",
					},
				}),
			};
		}
	};
	sortByIndexAscending(data) {
		if (Array.isArray(data)) {
			return data
				.map((item) => ({
					...item,
					workflowTemplateStepTrigger: this.sortByIndexAscending(
						item.workflowTemplateStepTrigger
					),
				}))
				.map((item) => ({
					...item,
					workflowTemplateStepActions: this.sortByIndexAscending(
						item.workflowTemplateStepActions
					),
				}))
				.sort((a, b) => a.index - b.index);
		} else if (typeof data === "object") {
			const sortedObject = {};
			for (const key in data) {
				sortedObject[key] = this.sortByIndexAscending(data[key]);
			}
			return sortedObject;
		}
		return data;
	}
	convertToWorkFlowStepData(data) {
		const arr: any = [];
		const sortedData = this.sortByIndexAscending(data.workflowTemplateStep);

		sortedData.forEach((step) => {
			const triggers = step.workflowTemplateStepTrigger.length > 0
				? step.workflowTemplateStepTrigger.map((trigger) => {
					const triggerFilter = JSON.parse(trigger.filter);
					const key = Object.keys(triggerFilter)[0];
					const [_, middleString, lastString] = key.split(".");

					const actions = trigger.workflowTemplateStepActions.length > 0
						? trigger.workflowTemplateStepActions.map((action) => {
							const config = action.config ? JSON.parse(action.config) : {};
							const actionId = action.id;
							const defaultTemplateId = uuidv4();

							if (action.action.includes('task')) {
								return {
									id: actionId,
									title: config.taskName,
									event: 'Task',
									templateId: action.config === null ? defaultTemplateId : config.taskId,
									type: 'action',
									filter: config,
									condition: JSON.parse(action.condition),
								};
							} else if (action.action.includes('email')) {
								return {
									id: actionId,
									title: config.subject,
									event: "send",
									templateId: config.emailTemplateId,
									templateTitle: config.subject,
									type: 'email',
									filter: config.context,
									to: config.destination,
									subject: config.subject,
									condition: JSON.parse(action.condition),
								};
							}
							else if (action.action.includes('setValues')) {
								this.globalVariables = [...this.globalVariables, ...[JSON.parse(action.variables)]]
								return {
									id: actionId,
									title: "setValue",
									event: "setValue",
									templateId: "",
									templateTitle: "",
									type: "all",
									config: JSON.parse(action.variables)
								}
							}
							else if (action.action.includes('dataTable') && trigger.trigger.includes("Webhook")) {
								return {
									id: actionId,
									title: 'dataTableRecord.' + action.action.split(".")[1],
									event: "dataTableRecord",
									templateId: config.dataTableId || defaultTemplateId,
									type: 'webhook',
									templateTitle: config?.templateTitle || '',
									templateType: "table",
									condition: JSON.parse(action.condition),
								};
							} else if (action.action.includes('formInstance') && trigger.trigger.includes("Webhook")) {
								return {
									id: actionId,
									title: 'FormInstance.' + action.action.split(".")[1],
									event: "FormInstance",
									templateId: config.formTemplateId || defaultTemplateId,
									type: 'webhook',
									templateTitle: config?.templateTitle || '',
									templateType: "form",
									condition: JSON.parse(action.condition),

								};
							} else if (action.action.includes('send') && trigger.trigger.includes("Webhook")) {
								let actionConfig = {
									webhookUrl: config.requestUrl,
									method: config.method,
									headers: JSON.parse(config?.headers || '{}'),
									postData: JSON.parse(config?.postData || '{}'),
									condition: JSON.parse(action.condition),
								}
								return {
									id: actionId,
									title: 'webhook.' + action.action.split(".")[1],
									event: "webhook",
									templateId: config.formTemplateId || defaultTemplateId,
									type: 'webhook',
									templateTitle: config?.requestUrl || '',
									templateType: "url",
									config: actionConfig,
									condition: JSON.parse(action.condition),
								};
							} else {
								const formatActionName = (action) =>
									action.replace('$', '').replace(/^./, str => str.toUpperCase());

								const determineTitleOrEvent = (action) =>
									action.includes('complete') ? 'complete' : formatActionName(action);

								const getTemplateId = (action, config, defaultTemplateId) => {

									const actionPart = action.action.split(".")[0];
									if (actionPart.includes('$form')) {
										return config.formTemplateId;
									}
									if (actionPart.includes('$DataTable')) {
										return config.datatableId;
									}
									return "";
								};

								const getType = (action) => {
									if (action.config === null) {
										return "action";
									}
									if (action.action.includes('form')) {
										return 'form';
									}
									if (action.action.includes('DataTable')) {
										return 'table';
									}
									return "email";
								};

								return {
									id: actionId,
									title: determineTitleOrEvent(action.action),
									event: action.action.includes('complete') ? 'complete' : determineTitleOrEvent(action.action.split(".")[0]),
									templateId: getTemplateId(action, config, defaultTemplateId),
									templateTitle: action.config === null ? '' : config.name,
									type: getType(action),
									condition: JSON.parse(action.condition),
								};

							}
						})
						: [{
							id: 'sbt_' + uuidv4(),
							title: "Drop Action Here",
							event: "",
							templateId: "",
							type: "",
						}];
					const getEventType = (trigger) => {
						if (trigger.trigger.includes('Webhook')) {
							return 'webhook';
						}
						return trigger.trigger.split(".")[0];
					};

					const getTemplateId = (trigger, triggerFilter, middleString, lastString) => {
						if (middleString !== "execute" && !trigger.trigger.includes('Webhook')) {
							return triggerFilter[`$event.${middleString}.${lastString}`]['$eq'];
						}
						return "";
					};

					const getTriggerType = (trigger, lastString, middleString) => {
						if (trigger.trigger.includes('Webhook')) {
							return "webhook";
						}
						if (lastString === "formTemplateId") {
							return "form";
						}
						if (middleString === "DataTable") {
							return 'table';
						}
						return "email";
					};

					return {
						id: trigger.id,
						title: trigger.trigger,
						event: getEventType(trigger),
						templateTitle: trigger?.filter ? triggerFilter.templateTitle : '',
						templateId: getTemplateId(trigger, triggerFilter, middleString, lastString),
						type: getTriggerType(trigger, lastString, middleString),
						...this.convertToFilter(triggerFilter, trigger?.nextStep, trigger),
						actions
					};

				})
				: [{
					id: 'sbt_' + uuidv4(),
					title: "Drop Trigger Here",
					event: "",
					templateId: "",
					type: "",
					actions: [{
						id: 'sbt_' + uuidv4(),
						title: "Drop Action Here",
						event: "",
						templateId: "",
						type: "",
					}],
				}];

			arr.push({
				id: step.id,
				title: step.name,
				triggers
			});
		});

		console.log("final arr", arr);
		return arr;
	}

	convertToFilter(data, nextStep, trigger) {
		let filter: any = {};

		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				if (key.includes('$event.formData.')) {
					const filterType = Object.keys(data[key])[0];
					const configKey = key.replace('$event.formData.', '');
					filter.filterType = filterType.replace('$', '');
					filter.config = filter.config || {};
					filter.config[configKey] = data[key][filterType];
				} else if (key.includes('$event.webhook.')) {
					let webhookAction = trigger.workflowTemplateStepActions[0];
					let actionConfig = JSON.parse(webhookAction.config);
					if (webhookAction.action.includes('dataTable') || webhookAction.action.includes('formInstance')) {
						filter = {
							filterType: "webhook",
							webhookType: webhookAction.action == '$dataTable.createRecord' ? "dataTableRecord.create" :
								webhookAction.action == '$formInstance.create' ? 'formInstance' : '',
							config: {
								event: webhookAction.action == '$dataTable.createRecord' ? "dataTableRecord.create" :
									webhookAction.action == '$formInstance.create' ? 'formInstance' : '',
								templateId: webhookAction.action.includes('dataTable') ? actionConfig?.dataTableId :
									webhookAction.action.includes('formInstance') ? actionConfig?.formTemplateId : '',
								templateTitle: actionConfig?.templateTitle
							}
						}
					} else if (webhookAction.action.includes("webhook.send")) {
						let config = JSON.parse(webhookAction.config)
						filter = {
							filterType: "webhook",
							webhookType: "url",
							config: {
								webhookUrl: config.requestUrl,
								method: config.method,
								headers: JSON.parse(config?.headers || '{}'),
								postData: JSON.parse(config?.postData || '{}')
							}
						}
					}
				}
			}
		}
		if (nextStep) {

			filter.nextStepConfig = { nextStepIndex: nextStep };
		}

		return { filter };
	}

	sendErrorMessage(failTitle, message) {
		this.notifications.error(failTitle, message, {
			timeOut: 3000,
			showProgressBar: true,
			pauseOnHover: true,
			clickToClose: true,
		});
	}

	transformVariables(variables) {
		let result = {};

		const operators = [
			{ operator: '===', format: '$eq=' },
			{ operator: '==', format: '$eq=' },
			{ operator: '!=', format: '$neq=' },
			{ operator: '>=', format: '$gte=' },
			{ operator: '<=', format: '$lte=' },
			{ operator: '>', format: '$gt=' },
			{ operator: '<', format: '$lt=' }
		];

		for (let key in variables) {
			let value = variables[key];
			if (value) {
				operators.forEach(({ operator, format }) => {
					if (value.includes(operator)) {
						let [left, right] = value.split(operator).map(v => v.trim());

						// Remove curly braces from the variable name
						left = left.replace(/[{}]/g, '');

						// Use `left` as the key and assign the transformed condition as the value
						result[left] = `${format}${right}`;
					}
				});
			}
		}

		return result;
	}


	reverseTransformVariables(variables) {
		let result = '';

		const operators = {
			'$eq': '==',
			'$neq': '!=',
			'$gte': '>=',
			'$lte': '<=',
			'$gt': '>',
			'$lt': '<'
		};

		for (let key in variables) {
			let value = variables[key];

			for (let format in operators) {
				if (value.startsWith(format)) {
					let right = value.replace(format, '').trim();
					let operator = operators[format];
					result = `{{${key}}}${operator}${right}`;
				}
			}
		}

		return result;
	}



}


@Injectable()
export class WorkflowPublicTemplateService extends GenericService<any> {
	constructor(
		public workflowPublicTemplateApi: WorkflowPublicTemplateApi,
		public notifications: NotificationsService
	) {
		super(workflowPublicTemplateApi as GenericApi<any>, notifications);
	}
}

@Injectable()
export class WorkflowMessageService extends GenericService<any> {
	constructor(
		public workflowMessageApi: WorkflowMessageApi,
		public notifications: NotificationsService,
		private jwtService: JwtService,
	) {
		super(workflowMessageApi as GenericApi<any>, notifications);
	}

	monitorJwtData() {
		let accountId = ''
		this.jwtService.currentJwtPayload$.subscribe({
			next: (data: any) => {
				accountId = data.account.id;
			},
		});
		return accountId
	}
}

@Injectable()
export class WorkflowTemplateStepService extends GenericService<any> {
	constructor(
		public workflowTemplateStepApi: WorkflowTemplateStepApi,
		public notifications: NotificationsService
	) {
		super(workflowTemplateStepApi as GenericApi<any>, notifications);
	}
}

@Injectable()
export class WorkflowTemplateStepTriggerService extends GenericService<any> {
	constructor(
		public workflowTemplateStepTriggerApi: WorkflowTemplateStepTriggerApi,
		public notifications: NotificationsService
	) {
		super(workflowTemplateStepTriggerApi as GenericApi<any>, notifications);
	}
}

@Injectable()
export class WorkflowTemplateStepActionService extends GenericService<any> {
	constructor(
		public workflowTemplateStepActionApi: WorkflowTemplateStepActionApi,
		public notifications: NotificationsService
	) {
		super(workflowTemplateStepActionApi as GenericApi<any>, notifications);
	}
}

@Injectable()
export class WorkflowTemplateGenerateURLService extends GenericService<any> {
	constructor(
		public workflowGenerateURLApi: WorkflowGenerateURLApi,
		public notifications: NotificationsService
	) {
		super(workflowGenerateURLApi as GenericApi<any>, notifications);
	}
}