<footer class="footer">
  <div class="container-fluid">
    <div class="row justify-content-between">
      <div class="col-12 col-lg-4">
        <p>© 2023 FusionFlow. All rights reserved.</p>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex align-items-center justify-content-end">
          <a href="about.html" class="footer-link"> About </a>
          <a href="service.html" class="footer-link"> Services </a>
          <a href="terms-condition.html" class="footer-link"> Terms of use </a>
          <a href="privacy-policy.html" class="footer-link"> Privacy policy </a>
        </div>
      </div>
    </div>
  </div>
</footer>
