import { Injectable } from "@angular/core";
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";
import { v4 as uuidv4 } from "uuid";

@Injectable({
  providedIn: "root",
})
export class FormBuilderService {
  constructor() { }

  drop(
    event: CdkDragDrop<string[]>,
    previousContainerData: any,
    containerData: any,
    isColumn: boolean,
    columnUID: string,
    rowUid: string,
    formArray: any
  ): void {
    const droppedItem: any = previousContainerData[event.previousIndex];

    if (event.previousContainer === event.container) {
      if (
        Array.isArray(containerData) === false &&
        containerData?.uid === previousContainerData?.uid
      ) {

        let item = {
          uid: uuidv4(),
          columns: [
            {
              uid: uuidv4(),
              size: "col-md-12",
              isColumn: false,
              index: event.currentIndex,
              elements: [event.item.data],
            },
          ],
        };
        let newArray = this.dropItemAt(
          formArray,
          item,
          event.previousIndex + 2
        );
        newArray
          .filter((item) => !formArray.includes(item))
          .forEach((item) => formArray.push(item));
        formArray.forEach((row) => {
          row.columns.forEach((column) => {
            if (column.uid === containerData.uid) {
              column.elements = [];
            }
          });
        });
      } else {
        //@Change Parent Items Index
        moveItemInArray(containerData, event.previousIndex, event.currentIndex);
      }
    } else {
      let droppedItem: any = previousContainerData[event.previousIndex];
      if (isColumn == true) {
        if (
          containerData &&
          containerData.isColumn === true &&
          previousContainerData[event.previousIndex] &&
          previousContainerData[event.previousIndex].columns &&
          previousContainerData[event.previousIndex].columns[0].isColumn ===
          false
        ) {
          console.log("@Move Parent Items to Column Drop Area");
          //@Move Parent Items to Column Drop Area
          let rowIndex = previousContainerData.findIndex(
            (row) => row.uid === rowUid
          );

          containerData.elements.push(
            previousContainerData[rowIndex].columns[0].elements[0]
          );

          previousContainerData.splice(rowIndex, 1);
        } else if (previousContainerData && previousContainerData.elements) {
          //@Drop Column Items to Column Drop Area

          containerData.elements.push(
            previousContainerData.elements[event.previousIndex]
          );
          previousContainerData.elements.splice(event.previousIndex, 1);
        } else {
          //@Drop Items to Column Drop Area
          containerData.elements.push(
            previousContainerData[event.previousIndex]
          );
        }
      } else {
        if (droppedItem.type == "column") {
          // Drop Columns to Parent drop area
          droppedItem = { ...droppedItem, uid: uuidv4() };

          containerData.push({
            uid: uuidv4(),
            columns: [
              {
                uid: uuidv4(),
                size: "col-md-6",
                isColumn: true,
                index: event.currentIndex,
                elements: [],
              },
              {
                uid: uuidv4(),
                size: "col-md-6",
                isColumn: true,
                index: event.currentIndex,
                elements: [],
              },
            ],
          });
        } else if (
          containerData &&
          containerData.isColumn == true &&
          previousContainerData &&
          previousContainerData[event.previousIndex].columns &&
          previousContainerData[event.previousIndex].columns[0].isColumn ==
          false
        ) {
          console.log("dsf");
          console.log(
            "row to column",
            previousContainerData[event.previousIndex]
          );
          // containerData.elements.push(
          //   previousContainerData[event.previousIndex]
          // );
        } else {
          //@Drop Items to Parent Drop Area

          containerData.push({
            uid: uuidv4(),
            columns: [
              {
                uid: uuidv4(),
                size: "col-md-12",
                isColumn: false,
                index: event.currentIndex,
                elements: [droppedItem],
              },
            ],
          });
        }
      }
    }
  }

  getFlatternColumns(rows) {
    let columns: any = [];
    let i = 0;
    while (i < rows.length) {
      let item = rows[i];
      item.columns.forEach((each) => {
        columns.push({ ...each, rowIndex: i });
      });
      i++;
    }
    return columns;
  }
  dropItemAt(array, item, index) {
    let newArr: any = [...array];
    if (index >= newArr.length) {
      newArr.push(item);
    } else {
      if (newArr[index] !== undefined) {
        const temp = newArr[index];
        newArr[index] = item;
        this.dropItemAt(newArr, temp, index + 1);
      } else {
        newArr[index] = item;
      }
    }
    return newArr;
  }

  convertFormBuilderObj(obj) {
    return {
      id: obj.id,
      name: obj.name,
      description: obj.description,
      isPublicAccessAllowed: obj.isPublicAccessAllowed,
      rows: obj.formTemplateRows.map((row) => ({
        uid: row.id,
        id: row.id,
        index: row.index,
        columns: row.formTemplateColumn.map((column) => ({
          id: column.id,
          uid: column.id,
          isColumn: column.isColumn,
          index: column.index,
          size: column.size,
          elements: column.formTemplateElement.map((element) => ({
            id: element.id,
            type: element.type,
            label: element.label,
            name: element.name,
            placeHolder: element.placeHolder,
            isRequired: element.isRequired,
            options:
              element.formTemplateElementOption.length === 0
                ? []
                : element.formTemplateElementOption.map((option) => ({
                  id: option.id,
                  label: option.label,
                  value: option.value,
                  defaultSelected:
                    option.defaultSelected == "0" ? false : true,
                })),
          })),
        })),
      })),
    };
  }
}
