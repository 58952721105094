import { Component } from '@angular/core';
import { Projectservice } from '../../services/project.service';
import { ICreateProject } from '../../model/project.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-project',
  templateUrl: './list-project.component.html',
  styleUrls: ['./_list-project.component.scss']
})
export class ListProjectComponent {
  projects: ICreateProject[];

  constructor(
    private projectService: Projectservice,
    private router: Router,
  ) {
    this.getProjects();
  }

  getProjects() {
    this.projectService.projects$.subscribe(projects => {
      this.projects = projects;
    });
  }

  addProject(){
    this.router.navigate(['/dashboard/manage/create-project']);
  }
}
