<div
  class="modal fade config-add-modal show"
  id="addformModal"
  tabindex="-1"
  aria-labelledby="addformModalLabel"
  aria-hidden="true"
  style="display: block; padding-left: 0px"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content template-add-canvas">
      <div class="modal-head">
        <h2 class="title">{{ data.chatData.groupName }}</h2>
        <p class="desc">
          {{ data.chatData.description }}
        </p>
      </div>
      <div class="modal-body">
        <div class="user-list">
          <h4 class="mb-3 ms-3">Members</h4>
          <ul class="list-unstyled users mb-4">
            <li *ngFor="let user of data.chatData.users">
              <a class="btn border-0" role="button">
                <span class="user-label">
                  <img
                    src="../../../assets/img/chat-group-logo.svg"
                    alt="logo"
                  />
                </span>
                <h4>
                  {{ user.account.name }}
                  <span class="d-inline" *ngIf="user.type === 'host'"
                    >(Host)</span
                  >
                  <span>
                    Joined: {{ user.createdAt | date : "dd MMM, yyyy" }}
                  </span>
                </h4>

                <div class="dropdown ms-auto">
                  <button
                    class="btn border-0 p-0 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 16C10.4142 16 10.75 15.6642 10.75 15.25C10.75 14.8358 10.4142 14.5 10 14.5C9.58579 14.5 9.25 14.8358 9.25 15.25C9.25 15.6642 9.58579 16 10 16Z"
                        stroke="#343B4E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 10.75C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10C9.25 10.4142 9.58579 10.75 10 10.75Z"
                        stroke="#343B4E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 5.5C10.4142 5.5 10.75 5.16421 10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75C9.25 5.16421 9.58579 5.5 10 5.5Z"
                        stroke="#343B4E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                        *ngIf="user.type != 'host'"
                        (click)="changeChatHost(user.accountId)"
                        >Make Host</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0);"
                        (click)="removeChatUser(user.accountId)"
                        >Remove</a
                      >
                    </li>
                  </ul>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="user-list">
          <h4 class="mb-3 ms-3">Invite New User</h4>
          <ul class="list-unstyled users">
            <li *ngFor="let user of getNotInvitedUsers()">
              <a class="btn border-0" role="button">
                <span class="user-label">
                  <img
                    src="../../../assets/img/chat-group-logo.svg"
                    alt="logo"
                  />
                </span>
                <h4>
                  {{ user.name }}
                </h4>

                <a
                  href="javascript:void(0)"
                  class="btn border-0 p-0 ms-auto gap-2"
                  (click)="inviteUser(user)"
                >
                  <span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <circle
                        cx="11"
                        cy="11"
                        r="10.5"
                        fill="white"
                        stroke="#BDC4D7"
                        stroke-dasharray="2 2"
                      ></circle>
                      <path
                        d="M13 16V15C13 14.4696 12.7893 13.9609 12.4142 13.5858C12.0391 13.2107 11.5304 13 11 13H7.5C6.96957 13 6.46086 13.2107 6.08579 13.5858C5.71071 13.9609 5.5 14.4696 5.5 15V16M15 9.5V12.5M16.5 11H13.5M11.25 9C11.25 10.1046 10.3546 11 9.25 11C8.14543 11 7.25 10.1046 7.25 9C7.25 7.89543 8.14543 7 9.25 7C10.3546 7 11.25 7.89543 11.25 9Z"
                        stroke="#343B4E"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </span>
                  Invite
                </a>
              </a>
            </li>
          </ul>
        </div>
        <div class="modal-btns">
          <button type="button" class="btn btn-secondary" (click)="close()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
