<div class="modal fade config-add-modal show" id="addformModal" tabindex="-1" aria-labelledby="addformModalLabel"
  aria-hidden="true" style="display: block; padding-left: 0px;">
  <div class="modal-dialog modal-dialog-centered modal-md">

    <div class="modal-content">

      <div class="modal-body">
        <form [formGroup]="headingFieldsForm" class="login_contant" (ngSubmit)="onSubmit()">
          <div class="mb-3">
            <div class="form-group mb-2">
              <label class="mb-2">Heading Type</label>
              <select class="form-control" required formControlName="name">
                <option value="h1">h1</option>
                <option value="h2">h2</option>
                <option value="h3">h3</option>
                <option value="h4">h4</option>
                <option value="h5">h5</option>
                <option value="h6">h6</option>
              </select>
              <p class="text-danger" *ngFor="let validation of constants.accountValidationMessages.name">
                <ng-container *ngIf="
            headingFieldsForm.get('name')?.hasError(validation.type) &&
            (headingFieldsForm.get('name')?.dirty ||
              headingFieldsForm.get('name')?.touched)
          ">
                  <span class="text-danger"> {{ validation.message }}</span>
                </ng-container>
              </p>
            </div>
          </div>
          <div class="mb-3">
            <div class="form-group mb-2">
              <label class="mb-2">Heading Value</label>
              <input class="form-control" formControlName="label" required />
              <p class="text-danger" *ngFor="let validation of constants.accountValidationMessages.label">
                <ng-container *ngIf="
            headingFieldsForm.get('label')?.hasError(validation.type) &&
            (headingFieldsForm.get('label')?.dirty ||
              headingFieldsForm.get('label')?.touched)
          ">
                  <span class="text-danger">{{ validation.message }}</span>
                </ng-container>
              </p>
            </div>
          </div>
          <div class="modal-btns">
            <button type="button" class="btn btn-secondary" (click)="close()"> Cancel </button>
            <button type="submit" class="btn btn-dark">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>