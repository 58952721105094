<section class="login">
  <div class="container-fluid">
    <div class="row justify-content-evenly">
      <div class="col-12 col-lg-4">
        <div class="login-content">
          <h4 class="title">Connect, Engage, and Retain with FusionFlow</h4>
          <p class="description">
            We will show you how FusionFlow works, what FusionFlow has to offer,
            and pricing options.
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-5">
        <div class="col-12 alert alert-danger" *ngIf="displayValidationErrors(fieldRejections)"
          [innerHTML]="displayValidationErrors(fieldRejections)"></div>
        <div class="login-content-flex mx-auto">
          <div class="card login-card">
            <div class="card-body">
              <form [formGroup]="form">
                <div class="form-group">
                  <label for="LoginName" class="form-label">Full name</label>
                  <div class="position-relative">
                    <text-input field="accountName" placeholder="Enter your name" [form]="form" [errors]="{
                        required: 'Account Name Required',
                        pattern: 'Please enter a valid full name',
                        serverValidation: 'Rejected by server'
                      }"></text-input>
                    <span class="icon d-none">
                      <img class="w-100" src="/assets/icons/login-user.svg" alt="login-user" />
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="LoginEmail" class="form-label">E-mail address</label>
                  <div class="position-relative">
                    <text-input field="email" placeholder="Enter your email address" [form]="form" [errors]="{
                        required: 'Email Address Required',
                        email: 'Invalid Email Address',
                        serverValidation: 'Rejected by server'
                      }"></text-input>
                    <span class="icon d-none">
                      <img class="w-100" src="/assets/icons/login-mail.svg" alt="login-mail" />
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="LoginCompany" class="form-label">Company name</label>
                  <div class="position-relative">
                    <text-input field="companyName" placeholder="Enter your company name" [form]="form" [errors]="{
                        required: 'Company name Required',
                        serverValidation: 'Rejected by server'
                      }"></text-input>
                    <span class="icon d-none">
                      <img class="w-100" src="/assets/icons/loginCompany.svg" alt="loginCompnay" />
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="Note" class="form-label">Additional note</label>
                  <textarea class="form-control" placeholder="Type here.." id="Note" style="height: 13.1rem"></textarea>
                </div>
                <button type="button" [disabled]="!canSubmit(form)" (click)="authenticate()" class="btn btn-dark"
                  role="button">
                  Get a demo
                </button>

                <div class="signUp-content mb-0">
                  <p class="d-flex align-items-center justify-content-center mb-0">
                    Don’t have an account?
                    <a routerLink="/register" role="button" class="btn btn-link">
                      Sign up
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>