export const PageSizeOptions: Array<number> = [1, 10, 15, 20, 30, 50];

export class IQueryFilter {

  constructor(defaults?: Partial<IQueryFilter>) {
    Object.assign(this, defaults);
  }

  skip: number = 0;
  limit: number = PageSizeOptions[0];
  sortBy: string;
  order: string = 'asc';
  filter: {
    [key: string]: any;
  } = {};

  getPageNumber = () => (this.skip / this.limit) + 1;
  setPageNumber = (page: number) => (this.skip = this.limit * (page - 1));
}

export class QueryResult<T> {
  rows: T[] = [];
  count: number = 0;
}
