import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FileUploadControl } from "./file-upload-control";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-fbc-file-upload",
  templateUrl: "./fbc-file-upload.component.html",
  styleUrls: ["./fbc-file-upload.component.sass"],
})
export class FbcFileUploadComponent {
  @Input() control: FileUploadControl | null = null;
  @Input() form: FormGroup;
  @Input() elementIndex!: number;
  @Input() columnIndex!: number;
  @Input() rowIndex!: number;
  @Input() isColumn: boolean;
  @Input() isPreview: boolean;
  @Output() FileUploadDeleteEvent = new EventEmitter<{
    elementIndex: number;
    columnIndex: number;
    rowIndex: number;
  }>();
  @Output() duplicateControl = new EventEmitter<FileUploadControl>();

  deleteTextField(
    control: FileUploadControl,
    index: number,
    columnIndex: number,
    rowIndex: number
  ) {
    this.FileUploadDeleteEvent.emit({
      elementIndex: index,
      columnIndex: columnIndex,
      rowIndex: rowIndex,
    });
  }

  duplicateFileUploadControl(control: FileUploadControl): void {
    this.duplicateControl.emit(control);
  }
  onFileSelected(e: any) {}
}
