<div class="task-boarding" cdkDropListGroup>
    <div class="boards">
        <div *ngFor="let status of statuses" class="content {{getBgColor(status.description)}}">
            <div class="head w-100">
                <h4 class="title mb-0 d-flex align-items-center gap-3">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                            <circle cx="4.23291" cy="4.39886" r="3.73285" fill="#343B4E" />
                        </svg>
                    </span>
                    {{status.statusName}}
                    <span class="badge {{status.description}}"> {{newTaskData[status.statusName].length || 0}} </span>
                </h4>
                <div class="d-flex align-items-center gap-4 justify-content-end">
                    <button class="btn border-0 p-0" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                            <path d="M6.75 0.75V11.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M1.5 6H12" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </button>
                    <button class="btn border-0 p-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="14" viewBox="0 0 4 14" fill="none">
                            <path
                                d="M1.75 13C2.16421 13 2.5 12.6642 2.5 12.25C2.5 11.8358 2.16421 11.5 1.75 11.5C1.33579 11.5 1 11.8358 1 12.25C1 12.6642 1.33579 13 1.75 13Z"
                                stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M1.75 7.75C2.16421 7.75 2.5 7.41421 2.5 7C2.5 6.58579 2.16421 6.25 1.75 6.25C1.33579 6.25 1 6.58579 1 7C1 7.41421 1.33579 7.75 1.75 7.75Z"
                                stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
                                stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="main-tasks w-100" cdkDropList [cdkDropListData]="newTaskData[status.statusName]"
                [cdkDropListConnectedTo]="connectedDropLists" (cdkDropListDropped)="onDrop($event, status.statusName)">
                <ng-container *ngIf="newTaskData[status.statusName]?.length > 0; else emptyContainer">
                    <div class="task-card card" *ngFor="let task of newTaskData[status.statusName]" cdkDrag>
                        <div class="card-body">
                            <!-- project-list-drop start here... -->
                            <div class="dropdown">
                                <button class="btn btn-pro dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    {{task.project.projectName}}
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6"
                                            fill="none">
                                            <path d="M1 1L4.6 4.6L8.2 1" stroke="white" stroke-width="1.5"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></span>
                                </button>
                                <ul class="dropdown-menu d-none">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li>
                                        <a class="dropdown-item" href="#">Another action</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- project-list-drop end here... -->
                            <div class="card-head d-flex align-items-center gap-2">
                                <button class="btn border-0 p-0" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="9" viewBox="0 0 7 9"
                                        fill="none">
                                        <path
                                            d="M2.9 7.875C2.9 8.49375 2.36 9 1.7 9C1.04 9 0.5 8.49375 0.5 7.875C0.5 7.25625 1.04 6.75 1.7 6.75C2.36 6.75 2.9 7.25625 2.9 7.875ZM1.7 3.375C1.04 3.375 0.5 3.88125 0.5 4.5C0.5 5.11875 1.04 5.625 1.7 5.625C2.36 5.625 2.9 5.11875 2.9 4.5C2.9 3.88125 2.36 3.375 1.7 3.375ZM1.7 0C1.04 0 0.5 0.50625 0.5 1.125C0.5 1.74375 1.04 2.25 1.7 2.25C2.36 2.25 2.9 1.74375 2.9 1.125C2.9 0.50625 2.36 0 1.7 0ZM5.3 2.25C5.96 2.25 6.5 1.74375 6.5 1.125C6.5 0.50625 5.96 0 5.3 0C4.64 0 4.1 0.50625 4.1 1.125C4.1 1.74375 4.64 2.25 5.3 2.25ZM5.3 3.375C4.64 3.375 4.1 3.88125 4.1 4.5C4.1 5.11875 4.64 5.625 5.3 5.625C5.96 5.625 6.5 5.11875 6.5 4.5C6.5 3.88125 5.96 3.375 5.3 3.375ZM5.3 6.75C4.64 6.75 4.1 7.25625 4.1 7.875C4.1 8.49375 4.64 9 5.3 9C5.96 9 6.5 8.49375 6.5 7.875C6.5 7.25625 5.96 6.75 5.3 6.75Z"
                                            fill="#949CA9" />
                                    </svg>
                                </button>
                                <h4 class="title mb-0">{{task.taskName}}</h4>
                            </div>
                            <div class="card-foot">
                                <button class="btn border-0 p-0" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22"
                                        fill="none">
                                        <circle cx="11.5" cy="11" r="10.5" fill="white" stroke="#BDC4D7"
                                            stroke-dasharray="2 2" />
                                        <path
                                            d="M14 16V15C14 14.4696 13.7893 13.9609 13.4142 13.5858C13.0391 13.2107 12.5304 13 12 13H8.5C7.96957 13 7.46086 13.2107 7.08579 13.5858C6.71071 13.9609 6.5 14.4696 6.5 15V16M16 9.5V12.5M17.5 11H14.5M12.25 9C12.25 10.1046 11.3546 11 10.25 11C9.14543 11 8.25 10.1046 8.25 9C8.25 7.89543 9.14543 7 10.25 7C11.3546 7 12.25 7.89543 12.25 9Z"
                                            stroke="#343B4E" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                                <div class="d-flex align-items-center gap-3 justify-content-end">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10"
                                            viewBox="0 0 11 10" fill="none">
                                            <path
                                                d="M8.66986 0.0634766H2.54246C2.00077 0.0634766 1.48126 0.278664 1.09822 0.6617C0.715188 1.04474 0.5 1.56425 0.5 2.10594V5.51005C0.5 6.05174 0.715188 6.57125 1.09822 6.95429C1.48126 7.33732 2.00077 7.55251 2.54246 7.55251H5.49723L8.81283 9.87411C8.86815 9.91319 8.93387 9.93487 9.00158 9.93639C9.06929 9.93791 9.13591 9.9192 9.19293 9.88265C9.24995 9.8461 9.29477 9.79337 9.32165 9.73121C9.34854 9.66905 9.35628 9.60028 9.34387 9.5337L9.05452 7.49124C9.51683 7.40262 9.93434 7.15703 10.2364 6.79602C10.5385 6.43501 10.7066 5.98074 10.7123 5.51005V2.10594C10.7123 1.56425 10.4971 1.04474 10.1141 0.6617C9.73106 0.278664 9.21155 0.0634766 8.66986 0.0634766ZM10.0315 5.51005C10.0315 5.87118 9.88804 6.21752 9.63268 6.47287C9.37733 6.72823 9.03099 6.87169 8.66986 6.87169C8.62084 6.87121 8.57231 6.88131 8.52756 6.90132C8.48281 6.92132 8.44292 6.95076 8.41059 6.9876C8.37827 7.02445 8.35429 7.06784 8.34028 7.11481C8.32628 7.16179 8.32258 7.21123 8.32945 7.25976L8.55752 8.8665L5.79679 6.93296C5.74083 6.89389 5.67441 6.87254 5.60616 6.87169H2.54246C2.18133 6.87169 1.83499 6.72823 1.57964 6.47287C1.32428 6.21752 1.18082 5.87118 1.18082 5.51005V2.10594C1.18082 1.74481 1.32428 1.39847 1.57964 1.14311C1.83499 0.887756 2.18133 0.744298 2.54246 0.744298H8.66986C9.03099 0.744298 9.37733 0.887756 9.63268 1.14311C9.88804 1.39847 10.0315 1.74481 10.0315 2.10594V5.51005Z"
                                                fill="#343B4E" />
                                            <path
                                                d="M8.66983 1.76562H4.24449C4.15421 1.76562 4.06762 1.80149 4.00378 1.86533C3.93994 1.92917 3.90408 2.01575 3.90408 2.10604C3.90408 2.19632 3.93994 2.2829 4.00378 2.34674C4.06762 2.41058 4.15421 2.44645 4.24449 2.44645H8.66983C8.76011 2.44645 8.8467 2.41058 8.91054 2.34674C8.97438 2.2829 9.01024 2.19632 9.01024 2.10604C9.01024 2.01575 8.97438 1.92917 8.91054 1.86533C8.8467 1.80149 8.76011 1.76562 8.66983 1.76562ZM8.66983 3.46768H2.54244C2.45215 3.46768 2.36557 3.50354 2.30173 3.56738C2.23789 3.63122 2.20203 3.71781 2.20203 3.80809C2.20203 3.89837 2.23789 3.98496 2.30173 4.0488C2.36557 4.11264 2.45215 4.1485 2.54244 4.1485H8.66983C8.76011 4.1485 8.8467 4.11264 8.91054 4.0488C8.97438 3.98496 9.01024 3.89837 9.01024 3.80809C9.01024 3.71781 8.97438 3.63122 8.91054 3.56738C8.8467 3.50354 8.76011 3.46768 8.66983 3.46768ZM8.66983 5.16973H2.54244C2.45215 5.16973 2.36557 5.2056 2.30173 5.26944C2.23789 5.33327 2.20203 5.41986 2.20203 5.51014C2.20203 5.60043 2.23789 5.68701 2.30173 5.75085C2.36557 5.81469 2.45215 5.85055 2.54244 5.85055H8.66983C8.76011 5.85055 8.8467 5.81469 8.91054 5.75085C8.97438 5.68701 9.01024 5.60043 9.01024 5.51014C9.01024 5.41986 8.97438 5.33327 8.91054 5.26944C8.8467 5.2056 8.76011 5.16973 8.66983 5.16973Z"
                                                fill="#343B4E" />
                                        </svg>
                                        12 comments
                                    </span>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10"
                                            fill="none">
                                            <path
                                                d="M5.18456 10C4.51441 9.99924 3.87194 9.75442 3.39806 9.31923C2.92419 8.88405 2.65761 8.29402 2.65679 7.67857V3.57143C2.65679 3.19255 2.82067 2.82919 3.1124 2.56128C3.40412 2.29337 3.79978 2.14286 4.21234 2.14286C4.6249 2.14286 5.02056 2.29337 5.31228 2.56128C5.60401 2.82919 5.7679 3.19255 5.7679 3.57143V7.5C5.7679 7.59472 5.72693 7.68556 5.65399 7.75254C5.58106 7.81952 5.48215 7.85714 5.37901 7.85714C5.27587 7.85714 5.17695 7.81952 5.10402 7.75254C5.03109 7.68556 4.99012 7.59472 4.99012 7.5V3.57143C4.99012 3.38199 4.90817 3.20031 4.76231 3.06635C4.61645 2.9324 4.41862 2.85714 4.21234 2.85714C4.00606 2.85714 3.80823 2.9324 3.66237 3.06635C3.51651 3.20031 3.43456 3.38199 3.43456 3.57143V7.67857C3.43456 8.10481 3.61894 8.5136 3.94713 8.81499C4.27532 9.11639 4.72043 9.28571 5.18456 9.28571C5.64869 9.28571 6.09381 9.11639 6.422 8.81499C6.75019 8.5136 6.93456 8.10481 6.93456 7.67857V3.21429C6.93456 2.55124 6.64776 1.91536 6.13724 1.44652C5.62673 0.977678 4.93432 0.714286 4.21234 0.714286C3.49036 0.714286 2.79796 0.977678 2.28744 1.44652C1.77692 1.91536 1.49012 2.55124 1.49012 3.21429V6.78571C1.49012 6.88043 1.44915 6.97128 1.37622 7.03825C1.30329 7.10523 1.20437 7.14286 1.10123 7.14286C0.99809 7.14286 0.899175 7.10523 0.826244 7.03825C0.753313 6.97128 0.712341 6.88043 0.712341 6.78571V3.21429C0.712341 2.3618 1.08109 1.54424 1.73747 0.941442C2.39384 0.338647 3.28408 0 4.21234 0C5.1406 0 6.03084 0.338647 6.68721 0.941442C7.34359 1.54424 7.71234 2.3618 7.71234 3.21429V7.67857C7.71152 8.29402 7.44493 8.88405 6.97106 9.31923C6.49719 9.75442 5.85472 9.99924 5.18456 10Z"
                                                fill="#343B4E" />
                                        </svg>
                                        0 files
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #emptyContainer>
                    <div class="empty-container">
                        <div class="empty-placeholder">
                            Drop tasks here
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="foot w-100">
                <button class="btn btn-light w-100" type="button" (click)="handleAddtask()">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path d="M10.5 4.75V15.25" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M5.25 10H15.75" stroke="black" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </span>
                    Add more task
                </button>
            </div>
        </div>
    </div>
</div>